import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { getProperty, updateProperty } from '../../actions/property-actions';
import { BsFillTrashFill } from "react-icons/bs";
import Paginator from '../../components/paginator/paginator';
import { Link, useParams } from 'react-router-dom';
import { formatDate } from '../../actions/global-actions';

function ExpensesList(){
    const dispatch = useDispatch();
    const params = useParams();
    const [expenses, setExpenses] = useState([]);
    const [loadData, setLoadData] = useState(true);
    const user = useSelector(state => state.user.user);
    const currentLink = "/inmuebles";
    const [currentData, setCurrentData] = useState({});

    const [skip, setSkip] = useState(0);
    const [paginatorData, setPaginatorData] = useState({
        totalitems: 0,
        totalpages: 0,
        currentpage: 0
    });

    useEffect(() => {
        const getFunction = (response) => {
            let data = response.data;
            if(data.code === "01"){
                Swal.fire({
                    title:"Error",
                    confirmButtonColor:"#76b4ff",
                    text: data.message
                });
            }else{
                //console.log(data[0]);
                let expenses = data[0].expenses;
                //console.log('expenses', expenses);

                let expensesArray = [];
                for (let index = 0; index < expenses.length; index++) {
                    let expense = expenses[index];
                    expense.person = `${expense.creationUserID.firstName} ${expense.creationUserID.lastName}`;
                    expense.formatteDate = formatDate(expense.createdAt, 2);
                    expense.date = new Date(expense.createdAt);
                    expensesArray.push(expense);
                }
                expensesArray.sort((a, b) => b.date - a.date);

                //console.log('expensesArray', expensesArray);

                setExpenses(expensesArray);
                setCurrentData(data[0]);

                /*
                setPaginatorData({
                    ...paginatorData,
                    ...response.headers,
                })*/
            }
        }
        if (loadData) {
            setLoadData(false);
            setExpenses([]);
            setCurrentData({});
            dispatch( getProperty( params.id, getFunction, user.token ) );

        }
    }, [loadData, dispatch, user.token, params.id, skip, paginatorData]);
    
    const handleOnClick = (index, expense) => {
        if (window.confirm(`¿Realmente desea eliminar el gasto: '${expense.description}'`)) {
            expenses.splice(index, 1);
            let data = JSON.parse(JSON.stringify(currentData));
            data.expenses = expenses;
            //console.log(data);
            dispatch( updateProperty( data, updateFunction, user.token ) );
        }
    }

    const updateFunction = (data) => {
        if(data.code === "01"){
            Swal.fire({
                title:"Error",
                confirmButtonColor:"#76b4ff",
                text: data.message
            });
        }else{
            setLoadData(true);

            Swal.fire({
                title:"¡Éxito!",
                confirmButtonColor:"#76b4ff",
                text: "Gasto eliminado con éxito"
            });
        }
    }

    useEffect(() => {
        //console.log(currentData)
    }, [currentData]);

    return(
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <div className="w-100 mt-5">
                    <Link to={`${currentLink}/editar/${params.id}`} className="back-button">&#60; Volver - Editar inmueble</Link>
                </div>
                <h1 className="my-5">Gastos - Inmueble {currentData.code}</h1>
                <div className="d-flex justify-content-end"> 
                    <Link to={`${currentLink}/${params.id}/nuevo-gasto`} className="primary-button">Registrar gasto</Link>
                </div>
                <table className="list-table my-5">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Fecha</th>
                            <th>Valor</th>
                            <th>Descripción</th>
                            <th>Usuario</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {expenses.map((expense, index) => (
                            <tr key={index}>
                                <td>
                                    <Link to={`${currentLink}/${params.id}/gastos/${expense._id}/editar`}>
                                        { (index+1) + skip }
                                    </Link> 
                                </td>
                                <td>
                                    <Link to={`${currentLink}/${params.id}/gastos/${expense._id}/editar`}>
                                        {expense.formatteDate}
                                    </Link> 
                                </td>
                                <td>
                                    <Link to={`${currentLink}/${params.id}/gastos/${expense._id}/editar`}>
                                        ${expense.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                    </Link> 
                                </td>
                                <td>
                                    <Link to={`${currentLink}/${params.id}/gastos/${expense._id}/editar`}>
                                        {expense.description}
                                    </Link> 
                                </td>
                                <td>
                                    <Link to={`${currentLink}/${params.id}/gastos/${expense._id}/editar`}>
                                        {expense.person}
                                    </Link> 
                                </td>
                                <td>
                                    <BsFillTrashFill onClick={()=>handleOnClick(index, expense)} className='trash-icon' />
                                </td>
                            </tr> 
                        ))}
                    </tbody>
                </table>
                {paginatorData.totalpages > 0 && paginatorData.currentpage > 0? 
                    <Paginator skip={skip} data={paginatorData} setSkip={(n) => setSkip(n)} setLoadData={(need) => setLoadData(need)} />
                :
                    null
                }
            </div>
        </div>
    );
}

export default ExpensesList;