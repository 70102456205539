import { getFormattedHeaders, queryIsLoading, queryNotLoading } from './global-actions';
import axios from 'axios';
import Swal from 'sweetalert2';
import { itemsPerPage } from './global-actions-data';

const apiUrl = process.env.REACT_APP_API_URL;

export function updateRequest( data, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.put(`${apiUrl}/property/update-request/${data.data._id}`, data, {
            headers:{
                token
            }
        }).then(result => {
            //console.log(result);
            if(result.status === 200){
                dispatch(queryNotLoading());
                cbFunction(result);
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function createContract( data, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.put(`${apiUrl}/property/create-contract/${data._id}`, data, {
            headers:{
                token
            }
        }).then(result => {
            //console.log(result);
            if(result.status === 200){
                dispatch(queryNotLoading());
                cbFunction(result);
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function getContracts( skip, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.get(`${apiUrl}/property/get-contracts`, {
            headers: {
                token
            },
            params: {
                skip,
                limit: itemsPerPage,
            },
        },).then(result => {
            const headersNeeded = [ 'totalitems', 'totalpages','currentpage' ];
            const headers = getFormattedHeaders( result.headers, headersNeeded);
            const response = { data: result.data, headers };
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(response)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function createPayment( data, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.put(`${apiUrl}/property/create-payment/${data._id}`, data, {
            headers:{
                token
            }
        }).then(result => {
            //console.log(result);
            if(result.status === 200){
                dispatch(queryNotLoading());
                cbFunction(result);
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function getAwaitingRequests( skip, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.get(`${apiUrl}/property/get-awaiting-requests`, {
            headers: {
                token
            },
            params: {
                skip,
                limit: itemsPerPage,
            },
        },).then(result => {
            const headersNeeded = [ 'totalitems', 'totalpages','currentpage' ];
            const headers = getFormattedHeaders( result.headers, headersNeeded);
            const response = { data: result.data, headers };
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(response)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function getContractsIncrease( skip, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.get(`${apiUrl}/property/get-contracts-increase`, {
            headers: {
                token
            },
            params: {
                skip,
                limit: itemsPerPage,
            },
        },).then(result => {
            const headersNeeded = [ 'totalitems', 'totalpages','currentpage' ];
            const headers = getFormattedHeaders( result.headers, headersNeeded);
            const response = { data: result.data, headers };
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(response)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}




export function getAprobedApply( id, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.get(`${apiUrl}/request/approbed-apply/${id}`, {
            headers: {
                token
            }
        }).then(result => {
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(result.data)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function getPayments( contract, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.get(`${apiUrl}/payment/${contract}`, {
            headers: {
                token
            }
        },).then(result => {
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(result)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}