import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { formatDate, searchListQuery } from '../../actions/global-actions';
import Paginator from '../../components/paginator/paginator';
import { getContractsIncrease } from '../../actions/request-actions';
import { Link, useSearchParams } from 'react-router-dom';
import { setSkipValue } from '../../components/setSkipValue';
import { DEFAULT_MONTHS, GET_MONTS } from '../payments/payments-data';
import { updateProperty } from '../../actions/property-actions';

function ContractsIncreaseList(){
    const currentLink = "/inmuebles";
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [contracts, setContracts] = useState([]);
    const [loadData, setLoadData] = useState(true);
    const user = useSelector(state => state.user.user);
    const [paginatorData, setPaginatorData] = useState({
        totalitems: 0,
        totalpages: 0,
        currentpage: 0
    });
    const [searchData, setSearchData] = useState( searchParams.get("q") || '' );
    const [skip, setSkip] = useState(setSkipValue(searchParams));

    useEffect(() => {
        const getFunction = (response) => {
            let data = response.data;
            if(data.code === "01"){
                Swal.fire({
                    title:"Error",
                    confirmButtonColor:"#76b4ff",
                    text: data.message
                });
            }else{
                //console.log('info', data.data);
                let contracts = [];
                data.data.forEach(property => {
                    const request = property.requests.find(request => request.status === 'Aprobado' || request.status === 'En contrato');
                    const contract = request.contracts.find(contract => contract.status === 'Activo');
                    let contractToPush = {};
                    contractToPush.person = `${request.firstName} ${request.lastName} ${request.documentType} No ${request.document}`;
                    contractToPush.ubication = `${property.address} B/${property.neighborhood} ${property.city}, ${property.province}`;
                    contractToPush.code = property.code;
                    contractToPush.contract = contract;
                    contractToPush.property = property;
                    contractToPush.request = request;
                    contractToPush.check = 'pending';
                    contractToPush.textStatus = 'Próximo a vencer';
                    let currentDate = new Date();
                    let contractEndDate = new Date(contract.endDate);
                    if (contractEndDate < currentDate) {
                        contractToPush.check = 'mora';
                        contractToPush.textStatus = 'Contrato vencido';
                    }
                    contractToPush.formattedStartDate = formatDate(contract.startDate, 4);
                    contractToPush.formattedEndDate = formatDate(contract.endDate, 4);
                    contracts.push(contractToPush);
                });

                //console.log('contracts', contracts);

                setContracts(contracts);
                
                setPaginatorData({
                    ...paginatorData,
                    ...response.headers,
                })
            }
        }

        if (loadData) {
            setLoadData(false);
            setContracts([]);
            if (searchData.length > 2) {
                dispatch( searchListQuery( 'property/get-contracts-increase', searchData, getFunction, skip, user.token ) );
            } else {
                dispatch( getContractsIncrease( skip, getFunction, user.token ) );
            }
        }
    }, [loadData, dispatch, user.token, skip, paginatorData, searchData]);

    const searchFunction = (event) => {
        let value = event.target.value;
        setSearchData(value);
        if (value.length > 2) {
            stockPaginator();
            setLoadData(true);
            setSearchParams({ q: value });
            return;
        }
        if (value.length === 0) {
            stockPaginator();
            setLoadData(true);
            setSearchParams({});
        }
    }

    const stockPaginator = () => {
        setSkip(0);
        setPaginatorData({
            totalitems: 0,
            totalpages: 0,
            currentpage: 0
        });
    }

    const updateContrat = (contract) => {
        const newEndDate = new Date( new Date(contract.contract.endDate).setMonth(new Date(contract.contract.endDate).getMonth() + (GET_MONTS[contract.contract.agreementTime] || DEFAULT_MONTHS)) )
        const newFormattedEndDate = formatDate(newEndDate, 4)
        if (window.confirm(`¿Realmente desea renovar el contrato con datos: Inquilino: ${contract.person}, nueva fecha de finalización: ${newFormattedEndDate} y valor canon mensual: $${contract.contract.monthlyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}?`)) {
            console.log(contract);

            //console.log(contract.property);
            contract.request.contracts.map(contractObj=>{
                if (contractObj._id === contract.contract._id) {
                    contractObj.endDate = newEndDate;
                }
                return contractObj;
            });

            contract.property.requests.map(request=>{
                if (request._id === contract.request._id) {
                    request.contracts = contract.request.contracts;
                }
                return request;
            });
            console.log( contract.property);

            dispatch( updateProperty( contract.property, updateFunction, user.token ) );
        }
    }

    const updateFunction = (data) => {
        if(data.code === "01"){
            Swal.fire({
                title:"Error",
                confirmButtonColor:"#76b4ff",
                text: data.message
            });
        }else{
            //navigate(currentLink)
            //console.log(data);
            setLoadData(true);
            Swal.fire({
                title:"¡Éxito!",
                confirmButtonColor:"#76b4ff",
                text: "Contrato actualizado con éxito"
            });
        }
    }
    
    return(
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <h1 className="my-5">Circular de aumento del canon de arrendamiento</h1>
                <div className="d-flex justify-content-between">
                    <div className="d-flex w-50">
                        <input className="input-field w-75" type="text" onChange={searchFunction} placeholder="Buscar por datos del inquilino o por datos del inmueble" value={searchData}/>
                    </div>
                </div>
                <div className='mt-3'>
                    <span className='align-self-center'>Contratos: <b>{paginatorData.totalitems}</b></span>
                </div>
                {contracts.map((contract, index) => (
                    <div key={index} className={`my-3 contract-container d-flex flex-column ${contract.check}`}>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex flex-column w-25 field'>
                                <span><b>Estado</b></span>
                                <span className={`contract-text ${contract.check}`}><b>{contract.textStatus}</b></span>
                            </div>
                            <div className='d-flex flex-column w-25 field'>
                                <span><b>Inquilino</b></span>
                                <span>{contract.person}</span>
                            </div>
                            <div className='d-flex flex-column w-25 field'>
                                <span><b>Inmueble</b></span>
                                <span>{`(${contract.code}) - ${contract.ubication}`}</span>
                            </div>
                            <div className='d-flex flex-column w-25 field'>
                                <span><b>Tipo de contrato</b></span>
                                <span>{contract.contract.agreementTime}</span>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex flex-column w-25 field'>
                                <span><b>Día límite de pago</b></span>
                                <span>{contract.contract.limitDay}</span>
                            </div>
                            <div className='d-flex flex-column w-25 field'>
                                <span><b>Fecha de inicio</b></span>
                                <span>{formatDate(contract.contract.startDate, 1)}</span>
                            </div>
                            <div className='d-flex flex-column w-25 field'>
                                <span><b>Fecha de finalización</b></span>
                                <span>{formatDate(contract.contract.endDate, 1)}</span>
                            </div>
                            <div className='d-flex flex-column w-25 field'>
                                <span><b>Valor del canon</b></span>
                                <span><b>${contract.contract.monthlyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</b></span>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-2'>
                            <div className='d-flex flex-column'>
                                <span className="end-button-wm go" onClick={()=>updateContrat(contract)}>Renovar contrato con el mismo canon</span>
                                <Link to={`${currentLink}/${contract.property._id}/nuevo-aumento`} className="end-button-wm go">Registrar aumento</Link>
                            </div>
                        </div>
                    </div> 
                ))}
                {paginatorData.totalpages > 0 && paginatorData.currentpage > 0 &&
                    <Paginator 
                        skip={skip}
                        data={paginatorData}
                        setSkip={(n) => setSkip(n)}
                        setLoadData={(need) => setLoadData(need)}
                        queryParams={searchParams}
                        setQueryParams={(params) => setSearchParams(params)} />
                }
            </div>
        </div>
    );
}

export default ContractsIncreaseList;