import Loader from "react-loader-spinner";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import {useSelector} from 'react-redux';
import Login from "./routes/login";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/general.css';
import Menu from "./components/menu/menu";
import Users from "./routes/users";
import UsersList from "./routes/users/users-list";
import CreateUser from "./routes/users/create-user";
import EditUser from "./routes/users/edit-user";
import Properties from "./routes/properties";
import CreateProperty from "./routes/properties/create-property";
import PropertiesList from "./routes/properties/properties-list";
import EditProperty from "./routes/properties/edit-property";
import Inventory from "./routes/properties/inventory";
import Documents from "./routes/properties/documents";
import Request from "./routes/request/request";
import SeeRequest from "./routes/request/see-request";
import SeeRequests from "./routes/request/see-resquests";
import PaymentsList from "./routes/payments/payments-list";
import CreateContract from "./routes/contracts/create-contract";
import ContractsList from "./routes/contracts/contracts-list";
import EditContract from "./routes/contracts/edit-contract";
import ExpensesList from "./routes/expenses/expenses-list";
import CreateExpense from "./routes/expenses/create-expense";
import EditExpense from "./routes/expenses/edit-expense";
import AwaitingRequests from "./routes/awaiting-requests/awaiting-requests";
import ContractsIncreaseList from "./routes/contract-increase/contracts-increase-list";
import CreateIncrement from "./routes/increments/create-increment";
import IncrementsList from "./routes/increments/increments-list";
import CreatePayment from "./routes/payments/create-payment";
import PropertyPaymentsList from "./routes/payments/property-payments-list";
import EditPayment from "./routes/payments/edit-payment";
import Upload from "./routes/upload/upload";


function RouterApp() {
  const user = useSelector(state => state.user.user);

  const global = useSelector(state => state.global);

  return(
    <div className="h-100">
        <div className="fixed-background" style={{ display: global.queryIsLoading? "flex": "none" }}>
          <Loader type="Oval" color="#76b4ff" height={100} width={100} visible={true}/>
        </div>
        {
          user === undefined || user === null || 
          (user && Object.keys(user).length === 0
          && Object.getPrototypeOf(user) === Object.prototype)?
            <BrowserRouter>
              <div className="d-flex m-0 h-100 primary-container">
                <div className="flex-grow-1 flex-shrink-1 p-2 content-container">
                  <div className="col h-100 p-0 bg-white">
                      <Routes>
                        {/** Ruta por defecto */}
                        <Route
                          path="/"
                          element={<Navigate to="/login"/>}
                        />
                          
                        {/** Rutas del usuario */}
                        <Route path="login" element={<Login/>}></Route>
                        
                        <Route path="solicitud/:code" element={<Request/>}/>

                        {/** Ruta para rutas sin pantalla*/}
                        <Route
                          path="*"
                          element={<Login/>}
                        />
                      </Routes>
                  </div>
                </div>
              </div>
            </BrowserRouter>
          :
            <BrowserRouter>
              <div className="d-flex m-0 h-100 primary-container">
                <div className="menu-container">
                  <Menu/>
                </div>
                <div className="flex-grow-1 flex-shrink-1 p-2 content-container">
                  <div className="col h-100 p-0 bg-white">
                      <Routes>
                        {/** Ruta por defecto */}
                        <Route
                          path="/"
                          element={<Navigate to="/inmuebles"/>}
                        />

                        <Route
                          path="/login"
                          element={<Navigate to="/inmuebles"/>}
                        />

                        {/** Rutas del usuario */}
                        <Route path="usuarios" element={<Users/>}>
                          <Route index element={<UsersList/>}/>
                          <Route path="" element={<UsersList/>}/>
                          <Route path="nuevo" element={<CreateUser/>}/>
                          <Route path="editar/:id" element={<EditUser/>}/>
                        </Route>

                        {/** Rutas del inmueble */}
                        <Route path="inmuebles" element={<Properties/>}>
                          <Route index element={<PropertiesList/>}/>
                          <Route path="" element={<PropertiesList/>}/>
                          <Route path="nuevo" element={<CreateProperty/>}/>
                          <Route path="editar/:id" element={<EditProperty/>}/>
                          <Route path=":id/documentos/:type" element={<Documents/>}/>
                          <Route path=":id/inventario" element={<Inventory/>}/>
                          <Route path=":id/solicitudes" element={<SeeRequests/>}/>
                          <Route path=":id/solicitud/:applyID" element={<SeeRequest/>}/>
                          <Route path=":id/contratos" element={<ContractsList/>}/>
                          <Route path=":id/nuevo-contrato" element={<CreateContract/>}/>
                          <Route path=":id/contratos/:contractID/editar" element={<EditContract/>}/>
                          <Route path="pagos" element={<PaymentsList/>}/>
                          <Route path=":id/nuevo-pago" element={<CreatePayment/>}/>
                          <Route path=":id/pagos" element={<PropertyPaymentsList/>}/>
                          <Route path=":id/pagos/:paymentID/editar" element={<EditPayment/>}/>
                          <Route path=":id/gastos" element={<ExpensesList/>}/>
                          <Route path=":id/nuevo-gasto" element={<CreateExpense/>}/>
                          <Route path=":id/gastos/:expenseID/editar" element={<EditExpense/>}/>
                          <Route path="solicitudes-pendientes" element={<AwaitingRequests/>}/>
                          <Route path="revisar-aumentos" element={<ContractsIncreaseList/>}/>
                          <Route path=":id/nuevo-aumento" element={<CreateIncrement/>}/>
                          <Route path=":id/aumentos" element={<IncrementsList/>}/>
                        </Route>

                        {/** Ruta del cargue masivo */}
                        <Route path="subir-archivo" element={<Upload/>} />

                        {/** Ruta para rutas sin pantalla*/}
                        <Route
                          path="*"
                          element={
                            <main style={{ padding: "1rem" }}>
                              <p>404, ¡Aquí no hay nada!</p>
                            </main>
                          }
                        />
                      </Routes>
                  </div>
                </div>
              </div>
            </BrowserRouter>
        }
    </div>
  );
}

export default RouterApp;