import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams  } from 'react-router-dom';
import { getProperty } from '../../actions/property-actions';
import DatePicker from 'react-date-picker';
import { agreementTimes } from '../properties/create-property-data';
import { createContract } from '../../actions/request-actions';
import alert from '../../components/alert';
import { DEFAULT_MONTHS, GET_MONTS } from '../payments/payments-data';

function CreateContract(){
    const currentLink = "/inmuebles";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.user);
    const [loadData, setLoadData] = useState(true);
    const params = useParams();
    const [currentData, setCurrentData] = useState({});
    const [currentRequest, setCurrentRequest] = useState({});
    const [limitDays, setLimitDays] = useState({});
    const [canShow, setCanShow] = useState(false);

    const [form, setForm] = useState({
        startDate: new Date(),
        endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        limitDay: '10',
        agreementTime: 'Anual',
        monthlyCost: '',
    });
    
    useEffect(() => {
        if (loadData) {
            const getFunction = (data) => {
                if(data.code === "01"){
                    alert('Error', data.message);
                    return;
                }

                //console.log('info', data.data[0]);
                let info = data.data[0];
                //console.log('info', info);
                setCurrentData(info);

                let request = info.requests.find(request=>request.status==='Aprobado' || request.status==='En contrato');
                if (!request) {
                    alert('Error', 'Para generar un contrato de arrendamiento primero debe aceptar una solicitud de algún aspirante');
                    navigate(`${currentLink}/${params.id}/contratos`)
                    return;
                }

                let currentContract = request.contracts.find(contract=>contract.status==='Activo');
                if (currentContract) {
                    alert('Advertencia', 'Ya existe actualmente un contrato activo para este inmueble');
                    navigate(`${currentLink}/${params.id}/contratos`)
                    return;
                }

                request.person = `${request.firstName} ${request.lastName} ${request.documentType} No ${request.document}`;
                request.monthlyIncome = request.monthlyIncome?request.monthlyIncome:0;
                request.incomeText = `$${request.monthlyIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                request.monthlyExpenses = request.monthlyExpenses?request.monthlyExpenses:0;
                request.expensesText = `$${request.monthlyExpenses.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;

                let days = [];
                for (let index = 1; index < 32; index++) {
                    days.push(index);
                }
                setLimitDays(days);
                setCurrentRequest(request);
                setCanShow(true);
                setForm({
                    ...form,
                    monthlyCost: info.monthCost
                })
            }

            setLoadData(false);

            setForm({
                startDate: new Date(),
                endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                limitDay: '10',
                agreementTime: 'Anual',
                monthlyCost: '',
            })

            dispatch( getProperty( params.id, getFunction, user.token ) );
        }
    }, [loadData, dispatch, form, params.id, user.token ]);
 
    const handleInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name] :  event.target.value
        })
    }

    const handleDateChange = ( name, date ) => {
        if (name === 'endDate') {
            setForm({
                ...form,
                [name] :  date,
            })
            return;
        }
        const newEndDate = new Date( new Date(date).setMonth(new Date(date).getMonth() + (GET_MONTS[form.agreementTime] || DEFAULT_MONTHS)) )
        setForm({
            ...form,
            [name] :  date,
            endDate: newEndDate
        })
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const isEmpty = validateFields();
        if (isEmpty) {
            alert('¡Atención!','Debe llenar todos los datos para continuar')
            return;
        }
        let data = {...currentData};
        let requests = data.requests;
        let currentRequestToSend = {...currentRequest};
        currentRequestToSend.contracts.push(form);
        currentRequestToSend.status = 'En contrato';

        for (let index = 0; index < requests.length; index++) {
            if (requests[index]._id === currentRequestToSend._id) {
                requests[index] = currentRequestToSend;
            }
        }
        data.requests = requests;
        //console.log('data', data);

        dispatch( createContract( data, createFunct, user.token ) );
    }

    const validateFields = () => {
        for (const field in form) {
            if (form.hasOwnProperty(field)) {
                //console.log(`${field}: ${form[field]}`);
                if (  form[field] === '' ) {
                    return true
                }
            }
        }
        return false
    }

    const createFunct = (data) => {
        if(data.code === "01"){
            alert('Error', data.message);
            return;
        }
        navigate(-1)
        //console.log(data);
        alert('¡Éxito!', 'Contrato creado con éxito');
    }

    useEffect(() => {
        //console.log(currentRequest)
    }, [currentRequest]);

    return(
        <div className="w-100 d-flex">
            { (canShow) &&
                <div className="container d-flex flex-column">
                    <div className="w-100 mt-5">
                        <button onClick={()=>navigate(-1)} className="back-button">&#60; Volver</button>
                    </div>
                    <h1 className="my-5">Nuevo contrato de arrendamiento - Inmueble {currentData.code}</h1>
                    <div className="d-flex w-100 flex-wrap mb-4">
                        <h4 className='w-100'>Datos de la solicitud aprobada</h4>
                        <div className="d-flex flex-column w-3 field">
                            <label className='label-field'>Persona</label>
                            <span className="span-field">{currentRequest.person}</span>
                        </div>
                        <div className="d-flex flex-column w-3 field">
                            <label className='label-field'>Ingresos</label>
                            <span className="span-field">{currentRequest.incomeText}</span>
                        </div>
                        <div className="d-flex flex-column w-3 field">
                            <label className='label-field'>Gastos</label>
                            <span className="span-field">{currentRequest.expensesText}</span>
                        </div>
                        <div className="d-flex flex-column w-100 field">
                            <label className='label-field'>Observación</label>
                            <span className="span-field">{currentRequest.observation}</span>
                        </div>
                        <div className="w-100 d-flex d-flex justify-content-center">
                            <Link to={`${currentLink}/${params.id}/solicitud/${currentRequest._id}`} className="end-button go">Ir a solicitud</Link>
                        </div>
                    </div>
                    <form onSubmit={handleOnSubmit}>
                        <div className="d-flex w-100 flex-wrap">
                            <h4 className="w-100 my-2">Datos del contrato</h4>
                            <div className="d-flex flex-column w-20 field">
                                <label className="label-field" htmlFor="agreementTime">Tiempo de contrato</label>
                                <select className="input-field" id="agreementTime" name="agreementTime" onChange={handleInputChange} value={form.agreementTime}>
                                    <option></option>
                                    {agreementTimes.map((type, index) => (
                                        <option key={`${type}${index}`} value={type}>{type}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="d-flex flex-column w-20 field">
                                <label className="label-field" htmlFor="startDate">Fecha de inicio</label>
                                <DatePicker
                                    value={ form.startDate }
                                    dayAriaLabel={"Día"}
                                    dayPlaceholder={"dd"}
                                    monthAriaLabel={"Mes"}
                                    monthPlaceholder={"mm"}
                                    yearAriaLabel={"Año"}
                                    yearPlaceholder={"yyyy"}
                                    name={"startDate"}
                                    onChange={ (date)=>handleDateChange( 'startDate', date ) }
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                            <div className="d-flex flex-column w-20 field">
                                <label className="label-field" htmlFor="endDate">Fecha de finalización</label>
                                <DatePicker
                                    value={ form.endDate }
                                    dayAriaLabel={"Día"}
                                    dayPlaceholder={"dd"}
                                    monthAriaLabel={"Mes"}
                                    monthPlaceholder={"mm"}
                                    yearAriaLabel={"Año"}
                                    yearPlaceholder={"yyyy"}
                                    name={"endDate"}
                                    onChange={ (date)=>handleDateChange( 'endDate', date ) }
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                            {limitDays.length > 0 &&
                                <div className="d-flex flex-column w-20 field">
                                    <label className="label-field" htmlFor="limitDay">Fecha límite para pago</label>
                                    <select className="input-field" id="limitDay" name="limitDay" onChange={handleInputChange} value={form.limitDay}>
                                        <option></option>
                                        {limitDays.map((day, index) => (
                                            <option key={index}>{day}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            <div className="d-flex flex-column w-20 field">
                                <label className="label-field" htmlFor="monthlyCost">Mensualidad</label>
                                <input id="monthlyCost" name="monthlyCost" autoComplete="off" maxLength={180} className="input-field" type="number" min={0} onChange={handleInputChange} value={form.monthlyCost}></input>
                            </div>
                            <div className="w-100 d-flex d-flex justify-content-center my-4">
                                <button className="primary-button save-button" type="submit">Generar</button>
                            </div>
                        </div>
                    </form>
                </div>
            }
        </div>
    );
}

export default CreateContract;
  