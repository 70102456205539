import { formatDate } from "../actions/global-actions";
import { DEFAULT_MONTHS, GET_MONTS } from "../routes/payments/payments-data";

const createPaymentsObject = (length, contract) => {
    let startMonth = new Date(contract.startDate).getMonth();
    const currentMonth = formatDate(new Date(), 6);
    const currentDay = new Date().getDate();
    let payments = [];
    let contractObj = {};
    let paymentsCounter = 0;

    for (let index = 0; index < length; index++) {
        let paymentObj = {};
        let date = formatDate(new Date(new Date(contract.startDate).setMonth(startMonth)), 6);
        let unformatedDate = new Date(new Date(contract.startDate).setMonth(startMonth));
        let _id = '';
        let createdAt = '';
        let status = 'No realizado';
        let value = 0;

        // Se valida si el día actual es mayor al día máximo de pago para saber si está en mora
        if ( currentMonth === date && currentDay > Number(contract.limitDay) ) {
            contractObj.check = 'mora';
            contractObj.textStatus = '¡En mora!';
        }

        // Se verifica que exista un pago para el mes actual
        const existPayment = contract.payments.find(payment => formatDate(new Date(payment.monthPaid), 6) === date);

        if (existPayment) {
            _id = existPayment._id;
            value = existPayment.valuePaid;
            createdAt = existPayment.createdAt;
            status = 'Pagado';
            if (currentMonth === date) {
                contractObj.check = 'ok';
                contractObj.textStatus = 'Al día';
            }
            paymentsCounter++;
        }

        paymentObj = {
            _id,
            date,
            unformatedDate,
            value,
            createdAt,
            status
        }

        payments.push(paymentObj);
        startMonth++;

        // Se valida que haya pagado todos los meses del tiempo del contrato
        // de ser verdadero, vuelve a crear la lista de pagos partiendo del último pago realizado y,
        // Adicionalmente muestra los dos pagos anteriores
        if ( paymentsCounter === length && index === (length-1) ) {
            payments = [];
            index = -1;
            paymentsCounter = 0;
            startMonth = startMonth-2;
            length = (GET_MONTS[contract.agreementTime] || DEFAULT_MONTHS) + 2;
        }
    }

    return {
        payments: [...payments],
        contractToPush: {...contractObj}
    };
}

export default createPaymentsObject