import { Link } from "react-router-dom"

const PropertyContractDetail = ({data, request, contract}) => {
    return (
        <div className="d-flex w-100 flex-wrap mb-4">
            <h4 className='w-100'>Datos generales</h4>
            <div className="d-flex flex-column w-50 field">
                <label className='label-field'>Inquilino</label>
                <span className="span-field">{request.person}</span>
            </div>
            <div className="d-flex flex-column w-50 field">
                <label className='label-field'>Ubicación inmueble</label>
                <span className="span-field">{data.ubication}</span>
            </div>
            <div className="d-flex flex-column w-3 field">
                <label className='label-field'>Canon actual</label>
                <span className="span-field">{contract.monthlyCostText}</span>
            </div>
            <div className="d-flex flex-column w-3 field">
                <label className='label-field'>Inicio del contrato</label>
                <span className="span-field">{contract.formattedStartDate}</span>
            </div>
            <div className="d-flex flex-column w-3 field">
                <label className='label-field'>Fin del contrato</label>
                <span className="span-field">{contract.formattedEndDate}</span>
            </div>
            <div className="w-100 d-flex d-flex justify-content-center">
                <Link to={`/inmuebles/${data._id}/contratos/${contract._id}/editar`} className="end-button go">Ir al contrato</Link>
            </div>
        </div>
    )
}

export default PropertyContractDetail