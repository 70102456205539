import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { formatDate, searchListQuery } from '../../actions/global-actions';
import Paginator from '../../components/paginator/paginator';
import { getAwaitingRequests } from '../../actions/request-actions';
import { Link, useSearchParams } from 'react-router-dom';
import { setSkipValue } from '../../components/setSkipValue';
import { awaitingTableBody, awaitingTableHeaders } from './awaiting-requests-data';
import TableComponent from '../../components/table/table';

function AwaitingRequests(){
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [requests, setRequests] = useState([]);
    const [loadData, setLoadData] = useState(true);
    const user = useSelector(state => state.user.user);
    const [searchData, setSearchData] = useState( searchParams.get("q") || '' );
    const [skip, setSkip] = useState(setSkipValue(searchParams));

    const [paginatorData, setPaginatorData] = useState({
        totalitems: 0,
        totalpages: 0,
        currentpage: 0
    });

    const currentLink = "/inmuebles";

    useEffect(() => {
        const getFunction = (response) => {
            let data = response.data;
            if(data.code === "01"){
                Swal.fire({
                    title:"Error",
                    confirmButtonColor:"#76b4ff",
                    text: data.message
                });
            }else{
                //console.log('info', data.data);
                let requestsArray = [];
                data.data.forEach(property => {
                    property.requests.forEach(request => {
                        if (request.status === 'Pendiente') {
                            request.applicant = `${request.firstName} ${request.lastName} ${request.documentType} No ${request.document}`;
                            if (request.actualProperty.address !== '') {
                                request.ubication = `${request.actualProperty.address} B/${request.actualProperty.neighborhood} ${request.actualProperty.actualPropertyCity}, ${request.actualProperty.actualPropertyProvince}`;
                            } else {
                                request.ubication = `Pendiente`;
                            }
                            if (request.monthlyIncome) {
                                request.incomeText = `$${request.monthlyIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                            } else {
                                switch (request.userType) {
                                    case 'Empleado':
                                        request.incomeText = `$${request.employee.salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                                        break;
                                    case 'Independiente':
                                        request.incomeText = `$${request.independent.income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                                        break;
                                    case 'Pensionado':
                                        request.incomeText = `$${request.pensionary.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                                        break;
                                    default:
                                        request.alertClass = true;
                                        request.alertItem = "userTypeText";
                                        request.incomeText = 'Pendiente';
                                        break;
                                }
                            }
                            request.userTypeText = request.userType?request.userType:'Pendiente';
                            request.formattedDate = formatDate(request.createdAt, 4);
                            request.link = `${currentLink}/${property._id}/solicitud/${request._id}`;
                            requestsArray.push(request);
                        }
                    });
                });

                //console.log('requests', requestsArray);

                setRequests(requestsArray);
                
                setPaginatorData({
                    ...paginatorData,
                    ...response.headers,
                })
            }
        }

        if (loadData) {
            setLoadData(false);
            setRequests([]);
            if (searchData.length > 2) {
                dispatch( searchListQuery( 'property/get-awaiting-requests', searchData, getFunction, skip, user.token ) );
            } else {
                dispatch( getAwaitingRequests( skip, getFunction, user.token ) );
            }
        }
    }, [loadData, dispatch, user.token, skip, paginatorData, searchData]);

    const searchFunction = (event) => {
        let value = event.target.value;
        setSearchData(value);
        if (value.length > 2) {
            stockPaginator();
            setLoadData(true);
            setSearchParams({ q: value });
            return;
        }
        if (value.length === 0) {
            stockPaginator();
            setLoadData(true);
            setSearchParams({});
        }
    }

    const stockPaginator = () => {
        setSkip(0);
        setPaginatorData({
            totalitems: 0,
            totalpages: 0,
            currentpage: 0
        });
    }

    return(
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <h1 className="my-5">Solicitudes pendientes por revisar</h1>
                <div className="d-flex justify-content-between">
                    <div className="d-flex w-50">
                        <input className="input-field w-75" type="text" onChange={searchFunction} placeholder="Buscar por datos del aspirante o por datos del inmueble" value={searchData}/>
                    </div>
                </div><div className='mt-3'>
                    <span className='align-self-center'>Solicitudes: <b>{paginatorData.totalitems}</b></span>
                </div>
                <TableComponent
                    id={"awaiting-table"}
                    headers={awaitingTableHeaders}
                    body={awaitingTableBody}
                    skip={skip}
                    data={requests}
                    link={``}
                    hasOwnLink={true}
                />
                {paginatorData.totalpages > 0 && paginatorData.currentpage > 0 &&
                    <Paginator 
                        skip={skip}
                        data={paginatorData}
                        setSkip={(n) => setSkip(n)}
                        setLoadData={(need) => setLoadData(need)}
                        queryParams={searchParams}
                        setQueryParams={(params) => setSearchParams(params)} />
                }
            </div>
        </div>
    );
}

export default AwaitingRequests;