import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProperty } from '../../actions/property-actions';
import { useParams, useNavigate } from 'react-router-dom';
import { formatDate } from '../../actions/global-actions';
import { updateRequest } from '../../actions/request-actions';
import alert from '../../components/alert';

function SeeRequest(){
    const currentLink = "/inmuebles";
    const dispatch = useDispatch();
    const params = useParams();
    const user = useSelector(state => state.user.user);
    const [form, setForm] = useState({
        observation: ''
    });
    const [loadData, setLoadData] = useState(true);
    const [request, setRequest] = useState({});
    const [currentData, setCurrentData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (loadData) {
            const getFunction = (data) => {
                if(data.code === "01"){
                    alert('Error', data.message);
                    return;
                }
                //console.log(data.data[0]);
                const requests = data.data[0].requests;

                let request = requests.find(requestObj => requestObj._id === params.applyID);

                //console.log('request', request);

                if (request.monthlyExpenses) {
                    request.monthlyExpenses = request.monthlyExpenses.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }

                if (request.monthlyIncome) {
                    request.monthlyIncome = request.monthlyIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
                
                setCurrentData(data.data[0]);
                setRequest(request);
                setForm({ observation: request.observation })
                
            }
            setLoadData(false);

            dispatch( getProperty( params.id, getFunction, user.token ) );
        }
    }, [loadData, dispatch, params.id, user.token, params.applyID ]);

    const handleInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name] :  event.target.value
        })
    }

    const handleRequestSubmit = (event, option) => {
        event.preventDefault();
        let currentRequest = currentData.requests.find(request=>request.status==='Aprobado');
        if (currentRequest) {
            let currentContract = currentRequest.contracts.find(contract=>contract.status==='Activo');
            //console.log('d', currentContract);
            if (currentContract) {
                alert('Advertencia', 'No puede modificar una solicitud mientras exista un contrato actualmente activo')
                return;
            }
        }
        
        if (window.confirm(`¿Realmente desea ${option===1?'aprobar':'rechazar'} esta solicitud? ${option===1?'TENGA EN CUENTA que si la aprueba se generará automáticamente un contrato de arrendamiento.':''}`)) {
            let data = JSON.parse(JSON.stringify(currentData));
            let status = false;
            for (let index = 0; index < data.requests.length; index++) {
                if (data.requests[index]._id === params.applyID) {
                    if (option === 1) {
                        data.requests[index].status = 'En contrato';
                        let contract = {
                            startDate: new Date(),
                            endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                            limitDay: '10',
                            agreementTime: 'Anual',
                            monthlyCost: data.monthCost,
                        }
                        data.requests[index].contracts.push(contract);
                        status = true;
                    } else {
                        data.requests[index].status = 'Rechazado';
                    }
                    data.requests[index].observation = form.observation;
                    break;
                }
            }
    
            let toSend = {
                data,
                status,
            }
            //console.log('toSend', toSend);
    
            dispatch( updateRequest( toSend, option===1?acceptFunction:declineFunction, user.token ) );
        }
    }

    const acceptFunction = (data) => {
        if(data.code === "01"){
            alert('Error', data.message);
            return;
        }
        alert('¡Éxito!', 'Solicitud aceptada con éxito');
        navigate(`${currentLink}/${params.id}/contratos`);
    }

    const declineFunction = (data) => {
        if(data.code === "01"){
            alert('Error', data.message);
            return;
        }
        alert('¡Éxito!', 'Solicitud rechazada con éxito');
        setLoadData(true);        
    }

    useEffect(() => {
        //console.log('form', form);
        //console.log('currentData', currentData)
    }, [form, currentData]);

    return(
        <div className="w-100 d-flex">
            {request && Object.keys(request).length > 0?
                <div className="container d-flex flex-column">
                    <div className="w-100 mt-5">
                        <button onClick={()=>navigate(-1)} className="back-button">&#60; Volver</button>
                    </div>
                    <h2 className="my-5">Solicitud: {request.status}</h2>
                    <div className="d-flex w-100 flex-wrap">
                        <h4 className="w-100 my-2">Datos Generales</h4>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="firstName">Nombres y apellidos</label>
                            <span className='span-field'>{request.firstName} {request.lastName}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="documentType">Documento</label>
                            <span className='span-field'>{request.documentType} No. {request.document} de {request.documentCity}, {request.documentProvince}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="email">Email</label>
                            <span className='span-field'>{request.email}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="phone">Teléfono&#47;Celular</label>
                            <span className='span-field'>{request.phone}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="occupation">Ocupación</label>
                            <span className='span-field'>{request.occupation}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="dependents">Personas a cargo</label>
                            <span className='span-field'>{request.dependents}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="peopleWhoWillLive">No personas que habitarán el hogar</label>
                            <span className='span-field'>{request.peopleWhoWillLive}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="monthlyExpenses">Gastos mensuales</label>
                            <span className='span-field' style={{fontWeight: 'bolder'}}>${request.monthlyExpenses}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="monthlyExpenses">Ingresos mensuales</label>
                            <span className='span-field' style={{fontWeight: 'bolder'}}>${request.monthlyIncome}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="userType">Tipo</label>
                            <span className='span-field'>{request.userType}</span>
                        </div>
                        <h4 className="w-100 my-2">Vivienda actual</h4>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="type">Tipo de propiedad</label>
                            <span className='span-field'>{request.actualProperty.type}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="address">Ubicación</label>
                            <span className='span-field'>{request.actualProperty.address} B&#47; {request.actualProperty.neighborhood} {request.actualProperty.actualPropertyCity}, {request.actualProperty.actualPropertyProvince}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="landlord">Nombre del administrador&#47;propietario</label>
                            <span className='span-field'>{request.actualProperty.landlord}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="landlordPhone">Teléfono del administrador&#47;propietario</label>
                            <span className='span-field'>{request.actualProperty.landlordPhone}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="monthlyCost">Costo mensual</label>
                            <span className='span-field' style={{fontWeight: 'bolder'}}>${request.actualProperty.monthlyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>
                        </div>
                        {request.actualProperty.administrationCost !== ''?
                            <div className="d-flex flex-column w-25 field">
                                <label className="label-field request" htmlFor="administrationCost">Costo de administración (opcional)</label>
                                <span className='span-field'>${request.actualProperty.administrationCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>
                            </div>
                        :null}
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field request" htmlFor="antiquity">Antigüedad</label>
                            <span className='span-field'>{request.actualProperty.antiquity}</span>
                        </div>
                        {request.userType === 'Empleado'?
                            <div className="d-flex w-100 flex-wrap">
                                <h4 className="w-100 my-2">Empleado</h4>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request" htmlFor="company">Empresa</label>
                                    <span className='span-field'>{request.employee.company}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request" htmlFor="address">Dirección</label>
                                    <span className='span-field'>{request.employee.address}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request" htmlFor="position">Cargo</label>
                                    <span className='span-field'>{request.employee.position}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request" htmlFor="salary">Salario</label>
                                    <span className='span-field' style={{fontWeight: 'bolder'}}>${request.employee.salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request" htmlFor="admissionDay">Fecha de ingreso</label>
                                    <span className='span-field'>{formatDate(request.employee.admissionDay, 4)}</span>
                                </div>
                            </div>
                        :null}
                        {request.userType === 'Independiente'?
                            <div className="d-flex w-100 flex-wrap">
                                <h4 className="w-100 my-2">Independiente</h4>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request" htmlFor="activity">Actividad principal</label>
                                    <span className='span-field'>{request.independent.activity}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request" htmlFor="address">Dirección</label>
                                    <span className='span-field'>{request.independent.address}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request" htmlFor="employeesNumber">No empleados a cargo</label>
                                    <span className='span-field'>{request.independent.employeesNumber}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request" htmlFor="income">Ingresos</label>
                                    <span className='span-field' style={{fontWeight: 'bolder'}}>${request.independent.income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request" htmlFor="expenses">Egresos</label>
                                    <span className='span-field' style={{fontWeight: 'bolder'}}>${request.independent.expenses.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>
                                </div>
                            </div>
                        :null}
                        {request.userType === 'Pensionado'?
                            <div className="d-flex w-100 flex-wrap">
                                <h4 className="w-100 my-2">Pensionado</h4>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request" htmlFor="reason">Razón de la pensión</label>
                                    <span className='span-field'>{request.pensionary.reason}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request" htmlFor="value">Valor de la pensión</label>
                                    <span className='span-field' style={{fontWeight: 'bolder'}}>${request.pensionary.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>
                                </div>
                            </div>
                        :null}
                        <h4 className="w-100 my-2">Propiedades</h4>
                        {request.properties.map((property, index)=>(
                            <div key={index} className="d-flex w-100 flex-wrap">
                                {request.properties.length > 1? <span className='w-100'>Propiedad <b>{index+1}</b></span>:null}
                                {property.type !== ''? <div className="d-flex w-100 flex-wrap">
                                        <div className="d-flex flex-column w-25 field">
                                            <label className="label-field request" htmlFor="type">Tipo de propiedad</label>
                                            <span className='span-field'>{property.type}</span>
                                        </div>
                                        <div className="d-flex flex-column w-25 field">
                                            <label className="label-field request" htmlFor="address">Ubicación</label>
                                            <span className='span-field'>{property.address} {property.propertyCity}, {property.propertyProvince}</span>
                                        </div>
                                        <div className="d-flex flex-column w-25 field">
                                            <label className="label-field request" htmlFor="value">Valor</label>
                                            <span className='span-field' style={{fontWeight: 'bolder'}}>${property.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>
                                        </div>
                                        <div className="d-flex flex-column w-25 field">
                                            <label className="label-field request" htmlFor="realEstateRegistration">Matrícula inmobiliaria</label>
                                            <span className='span-field'>{property.realEstateRegistration}</span>
                                        </div>
                                    </div>
                                    :null
                                }
                            </div>
                        ))}
                        {request.properties.length === 0 || request.properties[0].type === ''?'No dispone de propiedades':null}
                        <h4 className="w-100 my-2">Vehículos</h4>
                        {request.vehicles.map((vehicle, index)=>(
                            <div key={index} className="d-flex w-100 flex-wrap">
                                {request.vehicles.length > 1? <span className='w-100'>Vehículo <b>{index+1}</b></span>:null}
                                {vehicle.type !== ''?<div className="d-flex w-100 flex-wrap">
                                        <div className="d-flex flex-column w-25 field">
                                            <label className="label-field request" htmlFor="type">Tipo</label>
                                            <span className='span-field'>{vehicle.type}</span>
                                        </div>
                                        <div className="d-flex flex-column w-25 field">
                                            <label className="label-field request" htmlFor="brand">Marca</label>
                                            <span className='span-field'>{vehicle.brand}</span>
                                        </div>
                                        <div className="d-flex flex-column w-25 field">
                                            <label className="label-field request" htmlFor="model">Modelo</label>
                                            <span className='span-field'>{vehicle.model}</span>
                                        </div>
                                        <div className="d-flex flex-column w-25 field">
                                            <label className="label-field request" htmlFor="plaque">Placa</label>
                                            <span className='span-field'>{vehicle.plaque}</span>
                                        </div>
                                    </div>
                                    :null
                                }
                            </div>
                        ))}
                        {request.vehicles.length === 0 || request.vehicles[0].type === ''?'No dispone de vehículos':null}
                        <h4 className="w-100 my-2">Información financiera: Obligaciones vigentes</h4>
                        {request.finantialInformation.map((information, index)=>(
                            <div key={index} className="d-flex w-100 flex-wrap">
                                {request.finantialInformation.length > 1? <span className='w-100'>Obligación <b>{index+1}</b></span>:null}
                                {information.type !== ''?<div className="d-flex w-100 flex-wrap">
                                        <div className="d-flex flex-column w-25 field">
                                            <label className="label-field request" htmlFor="type">Tipo de obligación</label>
                                            <span className='span-field'>{information.type}</span>
                                        </div>
                                        <div className="d-flex flex-column w-25 field">
                                            <label className="label-field request" htmlFor="address">Entidad</label>
                                            <span className='span-field'>{information.entity}</span>
                                        </div>
                                        <div className="d-flex flex-column w-25 field">
                                            <label className="label-field request" htmlFor="value">Valor</label>
                                            <span className='span-field' style={{fontWeight: 'bolder'}}>${information.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>
                                        </div>
                                        <div className="d-flex flex-column w-25 field">
                                            <label className="label-field request" htmlFor="realEstateRegistration">Cuota</label>
                                            <span className='span-field' style={{fontWeight: 'bolder'}}>${information.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>
                                        </div>
                                        <div className="d-flex flex-column w-25 field">
                                            <label className="label-field request" htmlFor="realEstateRegistration">Restante</label>
                                            <span className='span-field' style={{fontWeight: 'bolder'}}>${information.remaining.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>
                                        </div>
                                    </div>
                                    :null
                                }
                            </div>
                        ))}
                        {request.finantialInformation.length === 0 || request.finantialInformation[0].type === ''?'No dispone de obligaciones financieras':null}
                        <h4 className="w-100 my-2">Referencias</h4>
                        {request.references.map((reference, index) => (
                            <div key={index} className="d-flex w-100 flex-wrap">
                                {request.references.length > 1? <span className='w-100'>Referencia <b>{index+1}</b></span>:null}
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request">Tipo</label>
                                    <span className='span-field'>{reference.type}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request">Nombres y apellidos</label>
                                    <span className='span-field'>{reference.fullname}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request">Teléfono</label>
                                    <span className='span-field'>{reference.phone}</span>
                                </div>
                                {reference.type !== 'Comercial'?
                                    <div className="d-flex flex-column w-25 field">
                                        <label className="label-field request">Parentesco</label>
                                        <span className='span-field'>{reference.relationship}</span>
                                    </div>
                                :null}
                            </div>
                        ))}
                        <h4 className="w-100 my-2">Codeudores</h4>
                        {request.cosigners.map((cosigner, index) => (
                            <div key={index} className="d-flex w-100 flex-wrap">
                                {request.cosigners.length > 1? <span className='w-100'>Codeudor <b>{index+1}</b></span>:null}
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request">Nombres y apellidos</label>
                                    <span className='span-field'>{cosigner.firstName} {cosigner.lastName}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request">Documento</label>
                                    <span className='span-field'>{cosigner.documentType} No. {cosigner.document}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request">Teléfono&#47;Celular</label>
                                    <span className='span-field'>{cosigner.phone}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request">Email</label>
                                    <span className='span-field'>{cosigner.email}</span>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field request">Lugar de residencia</label>
                                    <span className='span-field'>{cosigner.address} {cosigner.cosignerCity},{cosigner.cosignerProvince}</span>
                                </div>
                            </div>
                        ))}
                        <h4 className="w-100 my-2">Administrador</h4>
                        <div className="d-flex flex-column w-100 field mb-2">
                            <label className="label-field" htmlFor='observation'>Observación</label>
                            <textarea id='observation' name="observation" rows={3} cols={5} className="input-field" placeholder='Dé una retroalimentación acerca de esta solicitud, porqué se acepta o porqué se rechaza, etc' onChange={handleInputChange} value={form.observation}></textarea>
                        </div>
                        {request.status === 'Pendiente' &&
                            <div className="w-100 d-flex d-flex justify-content-center mb-4">
                                <button className="primary-button save-button" type="button" onClick={(e)=>handleRequestSubmit(e,2)}>Rechazar</button>
                                <button className="primary-button save-button" type="button" onClick={(e)=>handleRequestSubmit(e,1)}>Aprobar</button>
                            </div>
                        }
                        {request.status === 'Aprobado' &&
                            <div className="w-100 d-flex d-flex justify-content-center mb-4">
                                <button className="primary-button save-button" type="button" onClick={(e)=>handleRequestSubmit(e,2)}>Rechazar</button>
                            </div>
                        }
                        {request.status === 'Rechazado' &&
                            <div className="w-100 d-flex d-flex justify-content-center mb-4">
                                <button className="primary-button save-button" type="button" onClick={(e)=>handleRequestSubmit(e,1)}>Aprobar</button>
                            </div>
                        }
                    </div>
                </div>
            :null}
        </div>
    );
}

export default SeeRequest;
  