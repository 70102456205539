import { getFormattedHeaders, queryIsLoading, queryNotLoading } from './global-actions';
import axios from 'axios';
import Swal from 'sweetalert2';
import { itemsPerPage } from './global-actions-data';

const apiUrl = process.env.REACT_APP_API_URL;

export function createProperty( data, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.post(`${apiUrl}/property`, data,{
                headers: {
                    token
                }
            }).then(result => {
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(result.data)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function getProperties( skip, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.get(`${apiUrl}/property`, {
            headers: {
                token
            },
            params: {
                skip,
                limit: itemsPerPage,
            },
        },).then(result => {
            const headersNeeded = [ 'totalitems', 'totalpages','currentpage' ];
            const headers = getFormattedHeaders( result.headers, headersNeeded);
            const response = { data: result.data, headers };
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(response)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function getProperty( id, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.get(`${apiUrl}/property`, {
            headers: {
                token
            },
            params: {
                skip: 0,
                limit: 0,
                query: {
                    _id: id
                }
            }
        }).then(result => {
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(result.data)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function updateProperty( data, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.put(`${apiUrl}/property/${data._id}`, data, {
            headers:{
                token
            }
        }).then(result => {
            //console.log(result);
            if(result.status === 200){
                dispatch(queryNotLoading());
                cbFunction(result);
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function createRequest( data, cbFunction ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.put(`${apiUrl}/property/create-request/${data._id}`, data, {
            headers:{
                //token
            }
        }).then(result => {
            console.log(result);
            if(result.status === 200){
                dispatch(queryNotLoading());
                cbFunction(result);
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}




export function getPropertyByCode( code, cbFunction ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.get(`${apiUrl}/property`, {
            params: {
                skip: 0,
                limit: 0,
                query: {
                    code
                }
            }
        }).then(result => {
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(result.data)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function getRequests( id, skip, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.get(`${apiUrl}/request`, {
            headers: {
                token
            },
            params: {
                skip,
                limit: itemsPerPage,
                query: {
                    propertyID: id
                }
            },
        },).then(result => {
            const headersNeeded = [ 'totalitems', 'totalpages','currentpage' ];
            const headers = getFormattedHeaders( result.headers, headersNeeded);
            const response = { data: result.data, headers };
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(response)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function getRequest( id, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.get(`${apiUrl}/request`, {
            headers: {
                token
            },
            params: {
                skip: 0,
                limit: 0,
                query: {
                    _id: id
                }
            }
        }).then(result => {
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(result.data)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}
