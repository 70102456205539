import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { formatDate, searchListQuery } from '../../actions/global-actions';
import Paginator from '../../components/paginator/paginator';
import { getContracts } from '../../actions/request-actions';
import { useSearchParams } from 'react-router-dom';
import { setSkipValue } from '../../components/setSkipValue';
import createPaymentsObject from '../../components/createPaymentsObject';
import { DEFAULT_MONTHS, GET_MONTS } from './payments-data';
import PaymentsListComponent from '../../components/paymentList/paymentList';

function PaymentsList(){
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [contracts, setContracts] = useState([]);
    const [loadData, setLoadData] = useState(true);
    const user = useSelector(state => state.user.user);
    const [searchData, setSearchData] = useState( searchParams.get("q") || '' );
    const [skip, setSkip] = useState(setSkipValue(searchParams));

    const [paginatorData, setPaginatorData] = useState({
        totalitems: 0,
        totalpages: 0,
        currentpage: 0
    });

    const [showList, setShowList] = useState({
        show: false,
        position: -1
    });
    
    useEffect(() => {
        const getFunction = (response) => {
            let data = response.data;
            if(data.code === "01"){
                Swal.fire({
                    title:"Error",
                    confirmButtonColor:"#76b4ff",
                    text: data.message
                });
                return ;
            }
            //console.log('info', data.data);
            let contracts = [];
            data.data.forEach(property => {
                property.requests.forEach(request => {
                    if (request.status === 'Aprobado' || request.status === 'En contrato') {
                        request.contracts.forEach(contract => {
                            if (contract.status === 'Activo') {
                                let contractToPush = {};
                                contractToPush.person = `${request.firstName} ${request.lastName} ${request.documentType} No ${request.document}`;
                                contractToPush.ubication = `${property.address} B/${property.neighborhood} ${property.city}, ${property.province}`;
                                contractToPush.code = property.code;
                                contractToPush.contract = contract;
                                contractToPush.property = property;
                                contractToPush.request = request;
                                contractToPush.check = 'pending';
                                contractToPush.textStatus = 'Pendiente de pago';

                                const paymentsResponse = createPaymentsObject(
                                    GET_MONTS[contract.agreementTime] || DEFAULT_MONTHS,
                                    contract
                                );

                                contractToPush = {
                                    ...contractToPush,
                                    ...paymentsResponse.contractToPush
                                };
                                contractToPush.payments = [...paymentsResponse.payments];
                                contracts.push(contractToPush);
                            }
                        });
                    }
                });
            });

            //console.log('contracts', contracts);

            setContracts(contracts);
            
            setPaginatorData({
                ...paginatorData,
                ...response.headers,
            })
        }

        if (loadData) {
            setLoadData(false);
            setContracts([]);
            if (searchData.length > 2) {
                dispatch( searchListQuery( 'property/get-contracts', searchData, getFunction, skip, user.token ) );
            } else {
                dispatch( getContracts( skip, getFunction, user.token ) );
            }
        }
    }, [loadData, dispatch, user.token, skip, paginatorData, searchData]);

    const searchFunction = (event) => {
        let value = event.target.value;
        setSearchData(value);
        if (value.length > 2) {
            stockPaginator();
            setLoadData(true);
            setSearchParams({ q: value });
            return;
        }
        if (value.length === 0) {
            stockPaginator();
            setLoadData(true);
            setSearchParams({});
        }
    }

    const stockPaginator = () => {
        setSkip(0);
        setShowList({
            show: false,
            position: -1
        });
        setPaginatorData({
            totalitems: 0,
            totalpages: 0,
            currentpage: 0
        });
    }

    const handleListEvent = (position) => {
        let flag = true;
        if (showList.position === position) {
            flag = !showList.show;
        }
        setShowList({
            show: flag,
            position
        })
    }

    return(
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <h1 className="my-5">Estado de pago de los inmuebles</h1>
                <div className="d-flex justify-content-between">
                    <div className="d-flex w-50">
                        <input className="input-field w-75" type="text" onChange={searchFunction} placeholder="Buscar por datos del inquilino o por datos del inmueble" value={searchData}/>
                    </div>
                </div>
                <div className='mt-3'>
                    <span className='align-self-center'>Contratos: <b>{paginatorData.totalitems}</b></span>
                </div>
                {contracts.map((contract, index) => (
                    <div key={index} className={`my-3 contract-container d-flex flex-column ${contract.check}`}>
                        <div className='d-flex d-flex justify-content-between'>
                            <div className='d-flex flex-column w-3 field'>
                                <span><b>Inquilino</b></span>
                                <span>{contract.person}</span>
                            </div>
                            <div className='d-flex flex-column w-3 field'>
                                <span><b>Inmueble</b></span>
                                <span>{`(${contract.code}) - ${contract.ubication}`}</span>
                            </div>
                            <div className='d-flex flex-column w-3 field'>
                                <span><b>Estado</b></span>
                                <span className={`contract-text ${contract.check}`}><b>{contract.textStatus}</b></span>
                            </div>
                        </div>
                        <div className='d-flex d-flex justify-content-between'>
                            <div className='d-flex flex-column w-3 field'>
                                <span><b>Día límite de pago</b></span>
                                <span>{contract.contract.limitDay}</span>
                            </div>
                            <div className='d-flex flex-column w-3 field'>
                                <span><b>Fecha de inicio</b></span>
                                <span>{formatDate(contract.contract.startDate, 1)}</span>
                            </div>
                            <div className='d-flex flex-column w-3 field'>
                                <span><b>Fecha de finalización</b></span>
                                <span>{formatDate(contract.contract.endDate, 1)}</span>
                            </div>
                        </div>
                        <div className='d-flex d-flex justify-content-between'>
                            <div className='d-flex flex-column w-3 field'>
                                <span><b>Valor del canon</b></span>
                                <span><b>${contract.contract.monthlyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</b></span>
                            </div>
                            <div className='d-flex flex-column w-3 field'>
                                <span><b>Tipo de contrato</b></span>
                                <span>{contract.contract.agreementTime}</span>
                            </div>
                            <div className='d-flex flex-column w-3 field'>
                                <span><b>Ver pagos realizados</b></span>
                                <span style={{cursor: 'pointer'}} onClick={()=>handleListEvent(index)}>Ver</span>
                            </div>
                        </div>
                        {showList.show && showList.position === index &&
                            <PaymentsListComponent
                                contract={contract}
                                user={user}
                                dispatch={dispatch}
                                setLoadData={setLoadData} />
                        }
                    </div> 
                ))}
                {paginatorData.totalpages > 0 && paginatorData.currentpage > 0 &&
                    <Paginator 
                        skip={skip}
                        data={paginatorData}
                        setSkip={(n) => setSkip(n)}
                        setLoadData={(need) => setLoadData(need)}
                        queryParams={searchParams}
                        setQueryParams={(params) => setSearchParams(params)} />
                }
            </div>
        </div>
    );
}

export default PaymentsList;