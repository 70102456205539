import { useState } from 'react';
import { itemsPerPage } from '../../actions/global-actions-data';

function Paginator( props ){
    const [currentPage, setCurrentPage] = useState(props.data.currentpage);

    const handlePage = (option) => {
        let newPage = 0;
        if (option === 0) {
            if (currentPage > 1) {
                newPage = currentPage-1;
            }
        } else {
            newPage = currentPage+1;
        }
        let skip = 0;
        if (newPage === 1) {
            skip = 0;
        } else {
            skip = (newPage-1) * itemsPerPage; 
        }
        props.setSkip(skip);
        props.setLoadData(true);
        setCurrentPage(newPage);
        if (props.queryParams && props.setQueryParams) {
            if (newPage > 1) {
                props.setQueryParams({
                    ...Object.fromEntries([...props.queryParams]),
                    page: newPage
                });
                return;
            }
            let newParams = Object.fromEntries([...props.queryParams]);
            delete newParams.page;
            props.setQueryParams(newParams);
        }
    }

    return(
        <div className="d-flex w-100 flex-wrap">
            <div className="d-flex justify-content-center w-100">
                <span style={{fontStyle: 'italic'}}>Página <b>{currentPage}</b> de <b>{props.data.totalpages}</b></span>
            </div>
            <div className="d-flex justify-content-center w-100 my-2">
                {currentPage > 1 &&
                    <button className="primary-button" onClick={() => handlePage(0)}>Anterior</button>
                }
                {props.data.totalpages > 1 && currentPage < props.data.totalpages &&
                    <button className="primary-button" onClick={() => handlePage(1)}>Siguiente</button>
                }
            </div>
        </div>  
    );
}

export default Paginator;