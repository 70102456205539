export const menuArray = [
    {
        tag: "Inmuebles",
        link: "inmuebles",
        privileges: 2,
        data: [
            {
                title: "Nuevo",
                link: "inmuebles/nuevo"
            },
            {
                title: "Estado de pagos",
                link: "inmuebles/pagos"
            },
            {
                title: "Solicitudes pendientes",
                link: "inmuebles/solicitudes-pendientes"
            },
            {
                title: "Circular de aumento",
                link: "inmuebles/revisar-aumentos"
            }
        ]
    },
    {
        tag: "Cargue",
        link: "subir-archivo?option=3",
        privileges: 2,
        data: [
            {
                title: "Subir contratos",
                link: "subir-archivo?option=3"
            },
            {
                title: "Subir inmuebles",
                link: "subir-archivo?option=2"
            },
            {
                title: "Subir propietarios",
                link: "subir-archivo?option=1"
            },
        ]
    },
    {
        tag: "Reportes",
        link: "reportes",
        privileges: 2,
        data: []
    },
    {
        tag: "Usuarios",
        link: "usuarios",
        privileges: 2,
        data: [
            {
                title: "Nuevo",
                link: "usuarios/nuevo"
            }
        ]
    },
];