export const incrementsTableHeaders = [
    "Fecha",
    "Inquilino",
    "%",
    "Valor anterior",
    "Nuevo valor",
    "Diferencia",
];

export const incrementsTableBody = [
    {
        data: "date",
        classname: ""
    },
    {
        data: "tenant",
        classname: ""
    },
    {
        data: "percentage",
        classname: ""
    },
    {
        data: "previousValueText",
        classname: ""
    },
    {
        data: "newValueText",
        classname: ""
    },
    {
        data: "difference",
        classname: ""
    },
];