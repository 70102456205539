import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams  } from 'react-router-dom';
import { getProperty, updateProperty } from '../../actions/property-actions';

function CreateExpense(){
    const currentLink = "/inmuebles";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.user);
    const [loadData, setLoadData] = useState(true);
    const params = useParams();
    const [currentData, setCurrentData] = useState({});
    const [canShow, setCanShow] = useState(false);

    const [form, setForm] = useState({
        value:'',
        description: '',
        creationUserID: user._id
    });
    
    useEffect(() => {
        if (loadData) {
            const getFunction = (data) => {
                if(data.code === "01"){
                    Swal.fire({
                        title:"Error",
                        confirmButtonColor:"#76b4ff",
                        text: data.message
                    });
                }else{
                    //console.log('info', data.data[0]);
                    let info = data.data[0];
                    //console.log('info', info);
                    setCurrentData(info);
                    setCanShow(true);
                }
            }
            setLoadData(false);

            setForm({
                value:'',
                description: '',
                creationUserID: user._id
            })

            dispatch( getProperty( params.id, getFunction, user.token ) );
        }
    }, [loadData, dispatch, form, params.id, user.token ]);
 
    const handleInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name] :  event.target.value
        })
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const isEmpty = validateFields();
        if (isEmpty) {
            Swal.fire({
                title:"¡Atención!",
                confirmButtonColor:"#76b4ff",
                text:"Debe llenar todos los datos para continuar",
            });
            return;
        } else {
            let data = JSON.parse(JSON.stringify(currentData));
            data.expenses.push(form);
            //console.log('data', data);
            dispatch( updateProperty( data, updateFunct, user.token ) );
        }
    }

    const validateFields = () => {
        for (const field in form) {
            if (form.hasOwnProperty(field)) {
                //console.log(`${field}: ${form[field]}`);
                if (  form[field] === '' ) {
                    return true
                }
            }
        }
        return false
    }

    const updateFunct = (data) => {
        if(data.code === "01"){
            Swal.fire({
                title:"Error",
                confirmButtonColor:"#76b4ff",
                text: data.message
            });
        }else{
            navigate(`${currentLink}/${params.id}/gastos`)
            //console.log(data);
            Swal.fire({
                title:"¡Éxito!",
                confirmButtonColor:"#76b4ff",
                text: "Gasto registrado con éxito"
            });
        }
    }

    useEffect(() => {
        //console.log(form)
    }, [form]);

    return(
        <div className="w-100 d-flex">
            { (canShow) ?
                <div className="container d-flex flex-column">
                    <div className="w-100 mt-5">
                        <Link to={`${currentLink}/${params.id}/gastos`} className="back-button">&#60; Volver - Lista de gastos</Link>
                    </div>
                    <h1 className="my-5">Registrar un gasto - Inmueble {currentData.code}</h1>
                    <form onSubmit={handleOnSubmit}>
                        <div className="d-flex w-100 flex-wrap">
                            <div className="d-flex flex-column w-20 field">
                                <label className="label-field" htmlFor="value">Valor</label>
                                <input id="value" name="value" autoComplete="off" maxLength={180} className="input-field" type="number" min={0} onChange={handleInputChange} value={form.value}></input>
                            </div>
                            <div className="d-flex flex-column field" style={{flex: 1}}>
                                <label className="label-field" htmlFor='description'>Descripción del gasto</label>
                                <textarea id='description' name='description' placeholder='' autoComplete="off" rows={1} cols={2} className="input-field" type="text" onChange={handleInputChange} value={form.description}></textarea>
                            </div>
                            <div className="w-100 d-flex d-flex justify-content-center my-4">
                                <button className="primary-button save-button" type="submit">Registrar</button>
                            </div>
                        </div>
                    </form>
                </div>
            : null}
        </div>
    );
}

export default CreateExpense;
  