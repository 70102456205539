import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { getProperties } from '../../actions/property-actions';
import { searchListQuery } from '../../actions/global-actions';
import Paginator from '../../components/paginator/paginator';
import TableComponent from '../../components/table/table';
import { propertiesTableBody, propertiesTableHeaders } from './create-property-data';
import { setSkipValue } from '../../components/setSkipValue';
import alert from '../../components/alert';

function PropertiesList(){
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [properties, setProperties] = useState([]);
    const [loadData, setLoadData] = useState(true);
    const user = useSelector(state => state.user.user);
    const currentLink = "/inmuebles";
    const [searchData, setSearchData] = useState( searchParams.get("q") || '' );
    const [paginatorData, setPaginatorData] = useState({
        totalitems: 0,
        totalpages: 0,
        currentpage: 0
    });

    const [skip, setSkip] = useState(setSkipValue(searchParams));

    useEffect(() => {
        const getFunction = (response) => {
            let data = response.data;
            if(data.code === "01"){
                alert('Error', data.message);
                return;
            }

            //console.log(data.data);
            data.data.map(property => {
                property.statusText = 'Sin alquilar';
                property.ubication = `${property.address} B/ ${property.neighborhood} \n Tipo: ${property.propertyType}, ${property.city} - ${property.province}`;
                property.ownerText = `${property.owner.userID.firstName} ${property.owner.userID.lastName}`;
                property.infoText = property.neighborhood !== 'PENDIENTE'?'Completa':'Sin revisar';
                property.alertClass = property.neighborhood === 'PENDIENTE'?true: false;
                if(property.alertClass) property.alertItem = "infoText";

                const request = property.requests.find( request=>request.status==='Aprobado' || request.status==='En contrato');

                if (request) {
                    const contract = request.contracts.find( contract=>contract.status==='Activo' );
                    if(contract) {
                        property.statusText = 'En contrato';
                    }
                }

                //console.log(property);
                return property;
            });

            setProperties( data.data );

            setPaginatorData({
                ...paginatorData,
                ...response.headers,
            })
        }

        if (loadData) {
            setLoadData(false);
            setProperties([]);
            if (searchData.length > 2) {
                dispatch( searchListQuery( 'property', searchData, getFunction, skip, user.token ) );
            } else {
                dispatch( getProperties( skip, getFunction, user.token ) );
            }
        }
    }, [loadData, dispatch, user.token, skip, paginatorData, searchData]);

    const searchFunction = (event) => {
        let value = event.target.value;
        setSearchData(value);
        if (value.length > 2) {
            stockPaginator();
            setLoadData(true);
            setSearchParams({ q: value });
            return;
        }
        if (value.length === 0) {
            stockPaginator();
            setLoadData(true);
            setSearchParams({});
        }
    }

    const stockPaginator = () => {
        setSkip(0);
        setPaginatorData({
            totalitems: 0,
            totalpages: 0,
            currentpage: 0
        });
    }
    
    return(
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <h1 className="my-5">Inmuebles</h1>
                <div className="d-flex justify-content-between">
                    <div className="d-flex w-50">
                        <input className="input-field w-75" type="text" onChange={searchFunction} placeholder="Buscar por código, dirección, barrio, ciudad o departamento" value={searchData}/>
                    </div>
                    <Link to={`${currentLink}/nuevo`} className="primary-button">Añadir inmueble +</Link>
                </div>
                <div className='mt-3'>
                    <span className='align-self-center'>Inmuebles: <b>{paginatorData.totalitems}</b></span>
                </div>
                <TableComponent
                    id={"properties-table"}
                    headers={propertiesTableHeaders}
                    body={propertiesTableBody}
                    skip={skip}
                    data={properties}
                    link={`${currentLink}/editar/`}
                    needOptions={true}
                />
                {paginatorData.totalpages > 0 && paginatorData.currentpage > 0 &&
                    <Paginator 
                        skip={skip}
                        data={paginatorData}
                        setSkip={(n) => setSkip(n)}
                        setLoadData={(need) => setLoadData(need)}
                        queryParams={searchParams}
                        setQueryParams={(params) => setSearchParams(params)} />
                }
            </div>
        </div>
    );
}

export default PropertiesList;