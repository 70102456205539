import {
    QUERY_IS_LOADING,
    QUERY_NOT_LOADING
  } from '../types';
import axios from 'axios';
import Swal from 'sweetalert2';
import { itemsPerPage } from './global-actions-data';

const apiUrl = process.env.REACT_APP_API_URL;

export const queryIsLoading = () => ({
  type: QUERY_IS_LOADING
});

export const queryNotLoading = () => ({
  type: QUERY_NOT_LOADING
});

const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Agos", "Sep", "Oct", "Nov", "Dic"];
const largeMonths = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

export function searchListQuery( link, term, cbFunction, skip, token ) {
  return (dispatch) => {
      dispatch(queryIsLoading());
      axios.get(`${apiUrl}/${link}`, {
        headers: {
            token
        },
        params: {
            skip,
            limit: itemsPerPage,
            term
        }
      }).then(result => {
          const headersNeeded = [ 'totalitems', 'totalpages','currentpage' ];
          const headers = getFormattedHeaders( result.headers, headersNeeded);
          const response = { data: result.data, headers };
          if (result.status === 200) {
              dispatch(queryNotLoading());
              cbFunction(response)
          }
      }).catch(error => {
          if (error.response) {
              if (error.response.status === 400) {
                  Swal.fire({
                      title:"¡Error!",
                      confirmButtonColor:"#76b4ff",
                      text: error.response.data.message
                  });
              } else {
                  Swal.fire({
                      title:"¡Error!",
                      confirmButtonColor:"#76b4ff",
                      text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                  });
              }
          } else if (error.request) {
              console.log(error.request);
              Swal.fire({
                  title:"¡Error!",
                  confirmButtonColor:"#76b4ff",
                  text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
              });
          } else {
              console.log('Error', error.message);
              Swal.fire({
                  title:"¡Error!",
                  confirmButtonColor:"#76b4ff",
                  text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
              });
          }
          dispatch(queryNotLoading());
      })
  }
}

export function searchQuery( link, term, cbFunction, name = '', token ) {
  return (dispatch) => {
      dispatch(queryIsLoading());
      axios.get(`${apiUrl}/${link}`, {
        headers: {
            token
        },
        params: {
            skip: 0,
            limit: 5,
            term
        }
      }).then(result => {
          if (result.status === 200) {
            dispatch(queryNotLoading());
            if (name !== '') {
              cbFunction( name, result.data )
            } else {
              cbFunction( link, result.data )
            }
          }
      }).catch(error => {
          if (error.response) {
              if (error.response.status === 400) {
                  Swal.fire({
                      title:"¡Error!",
                      confirmButtonColor:"#76b4ff",
                      text: error.response.data.message
                  });
              } else {
                  Swal.fire({
                      title:"¡Error!",
                      confirmButtonColor:"#76b4ff",
                      text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                  });
              }
          } else if (error.request) {
              console.log(error.request);
              Swal.fire({
                  title:"¡Error!",
                  confirmButtonColor:"#76b4ff",
                  text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
              });
          } else {
              console.log('Error', error.message);
              Swal.fire({
                  title:"¡Error!",
                  confirmButtonColor:"#76b4ff",
                  text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
              });
          }
          dispatch(queryNotLoading());
      })
  }
}

export function formatDate( date, option ) {
  let newDate = new Date(date);
  let year = newDate.getFullYear();
  let month = newDate.getMonth()+1;
  let dt = newDate.getDate();
  let newDt = "";
  let newMonth = "";
  let formattedDate = "";

  if (dt < 10) {
    newDt = '0' + dt;
  } else {
    newDt = dt.toString();
  }

  if (month < 10) {
    newMonth = '0' + month;
  } else {
    newMonth = month.toString();
  }

  var hours = newDate.getHours();
  var minutes = newDate.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let newHour = "";
  if (hours.toString().length < 2) {
    newHour = "0" + hours;
  } else {
    newHour = hours.toString();
  }
  let newMinutes = "";
  if (minutes.toString().length < 2) {
    newMinutes = "0" + minutes;
  } else {
    newMinutes = minutes.toString();
  }

  switch (option) {
    case 1:
      formattedDate =  newDt + '/' + newMonth + '/' + year;
      break;
    case 2:
      var strTime = newHour + ':' + newMinutes + ampm;
      formattedDate =  newDt + '/' + newMonth + '/' + year + ' ' + strTime;
      break;
    case 3:
      formattedDate =  newDt + ' de ' + largeMonths[month-1] + ' del ' + year;
      break;
    case 4:
      formattedDate =  newDt + '/' + months[month-1] + '/' + year;
      break;
    case 5:
      formattedDate =  dt + ' días del mes de ' + largeMonths[month-1].toLocaleLowerCase() + ' del año ' + year;
      break;
    case 6:
        formattedDate =  largeMonths[month-1].toLocaleLowerCase() + '/' + year;
        break;
    default:
      formattedDate =  newDt + '-' + newMonth + '-' + year;
      break;
  }
  
  return formattedDate;
}

export function getFormattedHeaders( request, headers ) {
  let returnHeaders = {};
  for (let index = 0; index < headers.length; index++) {
      const header = request[headers[index]];
      if ( isNumeric(header) ) {
          returnHeaders[headers[index]] = parseInt(header);
      } else {
          returnHeaders[headers[index]] = header;
      }
  }
  return returnHeaders;
}

export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function toCurrency(str) {
  let currency = str?`$${str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`:'';
  return currency;
}

export function stringCleaner(str) {
  return str?str.trim():'';
}