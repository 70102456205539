import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProperty, updateProperty } from '../../actions/property-actions';
import Paginator from '../../components/paginator/paginator';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../../actions/global-actions';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import alert from '../../components/alert';
import { requestsTableBody, requestsTableHeaders } from './request-data';
import TableComponent from '../../components/table/table';

function SeeRequests(){
    const dispatch = useDispatch();
    const params = useParams();
    const [requests, setRequests] = useState([]);
    const [loadData, setLoadData] = useState(true);
    const user = useSelector(state => state.user.user);
    const currentLink = "/inmuebles";
    const [currentData, setCurrentData] = useState({});
    const [skip, setSkip] = useState(0);
    const [paginatorData, setPaginatorData] = useState({
        totalitems: 0,
        totalpages: 0,
        currentpage: 0
    });
    const navigate = useNavigate();

    const forLink = process.env.REACT_APP_LINK;

    useEffect(() => {
        const getFunction = (response) => {
            let data = response.data;
            if(data.code === "01"){
                alert('Error', data.message);
                return;
            }

            //console.log(data[0]);
            let requests = data[0].requests || [];

            requests.map(request => {
                request.applicant = `${request.firstName} ${request.lastName} ${request.documentType} No ${request.document}`;
                request.ubication = `Pendiente`;
                if (request.actualProperty.address !== '') {
                    request.ubication = `${request.actualProperty.address} B/${request.actualProperty.neighborhood} ${request.actualProperty.actualPropertyCity}, ${request.actualProperty.actualPropertyProvince}`;
                }
                request.date = new Date(request.createdAt);
                if (request.monthlyIncome) {
                    request.incomeText = `$${request.monthlyIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                } else {
                    switch (request.userType) {
                        case 'Empleado':
                            request.incomeText = `$${request.employee.salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                            break;
                        case 'Independiente':
                            request.incomeText = `$${request.independent.income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                            break;
                        case 'Pensionado':
                            request.incomeText = `$${request.pensionary.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                            break;
                        default:
                            request.alertClass = true;
                            request.alertItem = "userTypeText";
                            request.incomeText = 'Pendiente';
                            break;
                    }
                }
                request.userTypeText = request.userType?request.userType:'Pendiente';
                request.creationDate = formatDate(request.createdAt, 4);
                return request;
            });

            requests.sort((a, b) => b.date - a.date);

            setRequests(requests);
            setCurrentData(data[0]);

            /*
            setPaginatorData({
                ...paginatorData,
                ...response.headers,
            })*/
        }
        if (loadData) {
            setLoadData(false);
            setRequests([]);
            dispatch( getProperty( params.id, getFunction, user.token ) );
        }
    }, [loadData, dispatch, user.token, params.id, skip, paginatorData]);

    const handleOnClick = (requestObj) => {
        if (requestObj.status === 'Aprobado' || requestObj.status === 'En contrato') {
            alert('¡Advertencia!', 'No se puede eliminar una solicitud aprobada/en contrato');
            return;
        }
        let complementText = requestObj.status==='Terminado'?', ¡ESTA OPCIÓN NO SE PUEDE DESHACER Y ELIMINARÁ TODOS LOS CONTRATOS Y PAGOS REALIZADOS ASOCIADOS!':'?';
        if (window.confirm(`¿Realmente desea eliminar la solicitud de ${requestObj.applicant}${complementText}`)) {
            for (let x = 0; x < currentData.requests.length; x++) {
                if (currentData.requests[x]._id === requestObj._id) {
                    currentData.requests.splice(x,1);
                }
            }
            //console.log('currentData', currentData);
            dispatch( updateProperty( currentData, updateFunction, user.token ) );
        }
    }

    const updateFunction = (data) => {
        if(data.data.code === "01"){
            alert('Error', data.data.message);
            return;
        }
        alert('¡Éxito!', 'Solicitud eliminada con éxito');
        setLoadData(true);
    }

    return(
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <div className="w-100 mt-5">
                    <button onClick={()=>navigate(-1)} className="back-button">&#60; Volver</button>
                </div>
                <h1 className="my-5">Solicitudes - Inmueble {currentData.code}</h1>
                <div className="d-flex justify-content-between">
                    <CopyToClipboard text={`${forLink}/solicitud/${currentData.code}`} onCopy={() => alert(`El link fue copiado exitosamente: ${forLink}/solicitud/${currentData.code}`)}>
                        <button className="primary-button">Copiar link solicitud</button>
                    </CopyToClipboard>
                    <Link to={`${currentLink}/${params.id}/contratos`} className="primary-button">Ir a contratos</Link>
                </div>
                <TableComponent
                    id={"requests-table"}
                    headers={requestsTableHeaders}
                    body={requestsTableBody}
                    skip={skip}
                    data={requests}
                    link={`${currentLink}/${params.id}/solicitud/`}
                    needOptions={true}
                    deleteAction={handleOnClick}
                />
                {paginatorData.totalpages > 0 && paginatorData.currentpage > 0 &&
                    <Paginator skip={skip} data={paginatorData} setSkip={(n) => setSkip(n)} setLoadData={(need) => setLoadData(need)} />
                }
            </div>
        </div>
    );
}

export default SeeRequests;