import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { documentTypes, provinces, actualPropertyTypes, requestTypes, vehicleTypes, referenceTypes } from '../../actions/global-actions-data';
import { finantialInformationType, propertyTypes } from '../properties/create-property-data';
import { createRequest, getPropertyByCode } from '../../actions/property-actions';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import { requestRequirements } from './request-data';
import logo from '../../assets/logo-inmobiliaria.jpeg';

function Request(){
    const dispatch = useDispatch();
    const params = useParams();
    const cosignerObj = {
        firstName: '',
        lastName: '',
        documentType: '',
        document: '',
        email: '',
        phone: '',
        cosignerProvince: '',
        cosignerCity: '',
        address: '',
    }

    const propertyObj = {
        type: '',
        address: '',
        propertyProvince: '',
        propertyCity: '',
        value: '',
        realEstateRegistration: '',
    }

    const vehicleObj = {
        type: '',
        brand: '',
        model: '',
        plaque: '',
    }

    const finantialObj = {
        type: '',
        entity: '',
        value: '',
        fee: '',
        remaining: '',
    }

    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        documentType: '',
        document: '',
        documentProvince: '',
        documentCity: '',
        email: '',
        phone: '',
        occupation: '',
        dependents: '',
        peopleWhoWillLive: '',
        monthlyExpenses: '',
        monthlyIncome: '',
        actualProperty: {
            type: '',
            address: '',
            neighborhood: '',
            actualPropertyProvince: '',
            actualPropertyCity: '',
            landlord: '',
            landlordPhone: '',
            monthlyCost: '',
            administrationCost: '',
            antiquity: '',
        },
        userType: 'Empleado',
        employee: {
            company: '',
            address: '',
            position: '',
            salary: '',
            admissionDay: '',
        },
        independent: {
            activity: '',
            address: '',
            employeesNumber: '',
            income: '',
            expenses: ''
        },
        pensionary: {
            reason: '',
            value: '',
        },
        properties: [{...propertyObj}],
        vehicles: [{...vehicleObj}],
        finantialInformation: [{...finantialObj}],
        references: [
            {
                type: 'Familiar',
                fullname: '',
                phone: '',
                relationship: '',
            },
            {
                type: 'Personal',
                fullname: '',
                phone: '',
                relationship: '',
            },
            {
                type: 'Comercial',
                fullname: '',
                phone: '',
                relationship: '',
            },
            {
                type: 'Comercial',
                fullname: '',
                phone: '',
                relationship: '',
            }
        ],
        cosigners: [],
        description: '',
        acceptTerms: false,
    });
 
    const [citiesData, setCitiesData] = useState({
        documentCities: [],
        cities: [],
        actualPropertyCities: [],
        propertiesCities: [],
        cosignersCities: [],
    });

    const [loadData, setLoadData] = useState(true);
    const [currentData, setCurrentData] = useState({});
    const [requirements, setRequirements] = useState([]);
    const descriptionText = 'ECLARO QUE LA INFORMACIÓN SUMINISTRADA EN EL PRESENTE FORMATO ES VERÍDICA Y ASUMO MI RESPONSABILIDAD PLENA POR LA INFORMACIÓN REPORTADA. TODAS MIS ACTIVIDADES LAS ADELANTO DENTRO DEL MARCO DE LA LEGALIDAD Y MANIFIESTO QUE LOS RECURSOS QUE ME PERTENECEN TIENEN SU ORIGEN EN LAS SIGUIENTES FUENTES (DETALLAR OFICIO, OCUPACIÓN, ACTIVIDAD, NEGOCIO, ETC.): '.toLocaleLowerCase();
    const acceptText = 'Autorizo al propietario o administrador para la recolección, almacenamiento, tratamiento y uso de mis datos personales con la finalidad de vincularme como cliente o usuario de A&H GROUP SAS y sus empresas o personas vinculadas.';

    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (loadData) {
            const getFunction = (data) => {
                if(data.code === "01"){
                    Swal.fire({
                        title:"Error",
                        confirmButtonColor:"#76b4ff",
                        text: data.message
                    });
                }else{
                    //console.log('propiedad', data.data[0]);
                    let currentProperty = data.data[0];
                    if (!currentProperty) {
                        return Swal.fire({
                            title:"Error",
                            confirmButtonColor:"#76b4ff",
                            text: 'No se encontró ningún inmueble con el código ingresado'
                        });
                    }
                    currentProperty.ubication = `${currentProperty.address} B/${currentProperty.neighborhood} ${currentProperty.city}, ${currentProperty.province}`;

                    if (currentProperty.monthCost > 1000000) {
                        form.cosigners.push({...cosignerObj});
                        form.cosigners.push({...cosignerObj});
                    } else {
                        form.cosigners.push({...cosignerObj});
                    }

                    setRequirements([...requestRequirements.employee]);

                    setCurrentData(currentProperty);

                    setForm({
                        ...form,
                    })
                }
            }
            setLoadData(false);

            setForm({
                propertyID: '',
                firstName: '',
                lastName: '',
                documentType: '',
                document: '',
                documentProvince: '',
                documentCity: '',
                email: '',
                phone: '',
                occupation: '',
                dependents: '',
                peopleWhoWillLive: '',
                monthlyExpenses: '',
                monthlyIncome: '',
                actualProperty: {
                    type: '',
                    address: '',
                    neighborhood: '',
                    actualPropertyProvince: '',
                    actualPropertyCity: '',
                    landlord: '',
                    landlordPhone: '',
                    monthlyCost: '',
                    administrationCost: '',
                    antiquity: '',
                },
                userType: 'Empleado',
                employee: {
                    company: '',
                    address: '',
                    position: '',
                    salary: '',
                    admissionDay: '',
                },
                independent: {
                    activity: '',
                    address: '',
                    employeesNumber: '',
                    income: '',
                    expenses: ''
                },
                pensionary: {
                    reason: '',
                    value: '',
                },
                properties: [{...propertyObj}],
                vehicles: [{...vehicleObj}],
                finantialInformation: [{...finantialObj}],
                references: [
                    {
                        type: 'Familiar',
                        fullname: '',
                        phone: '',
                        relationship: '',
                    },
                    {
                        type: 'Personal',
                        fullname: '',
                        phone: '',
                        relationship: '',
                    },
                    {
                        type: 'Comercial',
                        fullname: '',
                        phone: '',
                        relationship: '',
                    },
                    {
                        type: 'Comercial',
                        fullname: '',
                        phone: '',
                        relationship: '',
                    }
                ],
                cosigners: [],
                description: '',
                acceptTerms: false,
            })

            dispatch( getPropertyByCode( params.code, getFunction ) );
        }
    }, [loadData, dispatch, form, params.code ]);

    const handleInputChange = (event) => {
        if (event.target.name === 'userType') {
            switch (event.target.value) {
                case 'Empleado':
                    setRequirements([...requestRequirements.employee]);
                    break;
                case 'Independiente':
                    setRequirements([...requestRequirements.independent]);
                    break;
                case 'Pensionado':
                    setRequirements([...requestRequirements.pensionary]);
                    break;
                case 'Persona Jurídica':
                    setRequirements([...requestRequirements.juridical]);
                    break;
                default:
                    setRequirements([...requestRequirements.employee]);
                    break;
            }
        }
        setForm({
            ...form,
            [event.target.name] :  event.target.value
        })
    }

    const handleOnChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        if (value !== '' && value) {
            const cities = provinces.find( x => x.name === value).cities;
            //console.log('name', name);
            switch (name) {
                case 'documentProvince':
                    setCitiesData({
                        ...citiesData,
                        documentCities: cities
                    })
                    setForm({
                        ...form,
                        [name]: value
                    })
                    break;
                case 'actualPropertyProvince':
                    setCitiesData({
                        ...citiesData,
                        actualPropertyCities: cities
                    });
                    setForm({
                        ...form,
                        actualProperty: {
                            ...form.actualProperty,
                            [name]: value
                        },
                    });
                    break;
                default:
                    console.log('sin caso');
                    break;
            }
            return;
        }

        switch (name) {
            case 'documentProvince':
                setCitiesData({
                    ...citiesData,
                    documentCities: []
                })
                setForm({
                    ...form,
                    documentProvince :  '',
                    documentCity: ''
                })
                break;
            case 'actualPropertyProvince':
                setCitiesData({
                    ...citiesData,
                    actualPropertyCities: []
                })
                setForm({
                    ...form,
                    actualProperty: {
                        ...form.actualProperty,
                        actualPropertyProvince :  '',
                        actualPropertyCity: ''
                    },
                })
                break;
            default:
                console.log('sin caso');
                break;
        }
        
    }

    const handleInsideInputChange = ( field, event ) => {
        setForm({
            ...form,
            [field]: {
                ...form[field],
                [event.target.name] :  event.target.value
            },
        })
    }

    const handleDynamicInputChange = ( field, index, event ) => {
        let newArray = [...form[field]];
        newArray[index][event.target.name] = event.target.value;

        setForm({
            ...form,
            [field]: [
                ...newArray
            ],
        })
    }

    const handleDynamicOnChange = ( field, citiesName, index, event ) => {
        const value = event.target.value;
        const name = event.target.name;

        if (value !== '' && value) {
            const cities = provinces.find( x => x.name === value).cities;
            let newCitiesArray = [...citiesData[citiesName]];
            newCitiesArray[index] = cities;

            setCitiesData({
                ...citiesData,
                [citiesName]: [
                    ...newCitiesArray
                ]
            })
            
            let newArray = [...form[field]];
            newArray[index][name] = value;

            setForm({
                ...form,
                [field]: [
                    ...newArray
                ]
            })
            return;
        }
        
        let newCitiesArray = [...citiesData[citiesName]];

        newCitiesArray[index] = [];
        setCitiesData({
            ...citiesData,
            [citiesName]: [
                ...newCitiesArray
            ]
        })

        let newArray = [...form[field]];
        if (field === 'properties') {
            newArray[index]['propertyProvince'] = '';
            newArray[index]['propertyCity'] = '';
        } else {
            newArray[index]['cosignerProvince'] = '';
            newArray[index]['cosignerCity'] = '';
        }

        setForm({
            ...form,
            [field]: [
                ...newArray
            ]
        })
    }

    const handleDateChange = ( name, date ) => {
        setForm({
            ...form,
            employee: {
                ...form.employee,
                [name] :  date
            }
        })
    }

    const addObj = (option) => {
        let newArray = [...form[option]];
        switch (option) {
            case 'properties':
                newArray.push({...propertyObj});
                break;
            case 'vehicles':
                newArray.push({...vehicleObj});
                break;
            case 'cosigners':
                newArray.push({...cosignerObj});
                break;
            case 'finantialInformation':
                newArray.push({...finantialObj});
                break;
            default:
                console.log('default');
                break;
        }
        setForm({
            ...form,
            [option]: [
                ...newArray
            ],
        })
    }

    let deleteObj = (option,i) => {
        if (form[option].length > 1) {
            let newFormValues = {...form};
            newFormValues[option].splice(i, 1);
            setForm({...newFormValues});
        }
    }

    let deleteCosigner = (i) => {
        if (currentData.monthCost > 1000000) {
            if (form.cosigners.length > 2) {
                let newFormValues = {...form};
                newFormValues.cosigners.splice(i, 1);
                setForm({...newFormValues});
            }
        } else {
            if (form.cosigners.length > 1) {
                let newFormValues = {...form};
                newFormValues.cosigners.splice(i, 1);
                setForm({...newFormValues});
            }
        }
    }

    const handleCheckChange = (event) => {
        setForm({
            ...form,
            [event.target.name] :  event.target.checked
        })
    }

    const checkEmptyfield = (value) => {
        if (typeof form[value] === 'boolean' && isSubmitted && form[value] === false) {
            return 'error';
        }
        if (isSubmitted && (form[value] === '' || form[value] === null)) {
            return 'error';
        }
        return "";
    }

    const checkInsideEmptyfield = ( field, value ) => {
        if (isSubmitted && (form[field][value] === '' || form[field][value] === null)) {
            return 'error'
        }
        return "";
    }

    const checkDynamicEmptyField = ( field, index, value ) => {
        if (isSubmitted && (form[field][index][value] === '' || form[field][index][value] === null)) {
            return 'error'
        }
        return "";
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        setIsSubmitted(true);
        const isEmpty = validateFields();
        if (isEmpty) {
            if ( form.userType === '' || 
                (form.userType !== 'Empleado' && form.userType !== 'Independiente' && form.userType !== 'Pensionado' && form.userType !== 'Persona Jurídica') ) {
                return Swal.fire({
                    title:"¡Atención!",
                    confirmButtonColor:"#76b4ff",
                    text:"Debe llenar el campo de tipo de persona entre las diferentes opciones mostradas: Empleado, Independiente, Pensionado, Persona Jurídica",
                });
            }
            return Swal.fire({
                title:"¡Atención!",
                confirmButtonColor:"#76b4ff",
                text:"Debe llenar todos los datos para continuar",
            });
        } else {           
            if (form.acceptTerms === false) {
                return Swal.fire({
                    title:"¡Atención!",
                    confirmButtonColor:"#76b4ff",
                    text:"Debe aceptar la política de tratamiento de datos",
                });
            }
            setIsSubmitted(false);
            let data = JSON.parse(JSON.stringify(currentData));
            
            data.requests.push(form);
            //console.log('data', data.requests);

            dispatch( createRequest( data, createFunction ) );
        }
    }

    const validateFields = () => {
        for (const field in form) {
            if (form.hasOwnProperty(field)) {
                // Objetos
                switch (form.userType) {
                    case 'Empleado':
                        if (typeof form[field] === 'object' && field !== 'vehicles' && field !== 'properties' && field !== 'finantialInformation' && field !== 'independent' && field !== 'pensionary' && form[field] !== null) {
                            for (const fieldInside in form[field]) {
                                if (form[field].hasOwnProperty(fieldInside)) {
                                    //console.log(`Empleado ${fieldInside}x2: ${form[field][fieldInside]}`);
                                    //console.log(`type: ${form.userType}`);
        
                                    if (typeof form[field][fieldInside] === 'object' && form[field][fieldInside] !== null) {
                                        for (const fieldArray in form[field][fieldInside]) {
                                            if (form[field][fieldInside].hasOwnProperty(fieldArray)) {
                                                console.log(`Empleado ${fieldArray}x3: ${form[field][fieldInside][fieldArray]}`);
                                                console.log(`type: ${form[field][fieldInside]['type']}`);
        
                                                if ( fieldArray === 'relationship' && form[field][fieldInside][fieldArray] === '' && form[field][fieldInside]['type'] !== 'Comercial') {
                                                    return true
                                                }
                                                if (form[field][fieldInside][fieldArray] === '' && fieldArray !== 'relationship') {
                                                    return true
                                                }
                                            }
                                        }
                                    }
                                    if ( fieldInside !== 'administrationCost' && form[field][fieldInside] === '') {
                                        return true
                                    }
                                }
                            }
                        } else {
                            if (form[field] === '') {
                                //console.log(`1. ${field}: ${form[field]}`);
                                return true
                            }
                        }
                        break;
                    case 'Independiente':
                        if (typeof form[field] === 'object' && field !== 'vehicles' && field !== 'properties' && field !== 'finantialInformation' && field !== 'employee' && field !== 'pensionary' && form[field] !== null) {
                            for (const fieldInside in form[field]) {
                                if (form[field].hasOwnProperty(fieldInside)) {
                                    //console.log(`Independiente ${fieldInside}x2: ${form[field][fieldInside]}`);
                                    //console.log(`type: ${form.userType}`);
        
                                    if (typeof form[field][fieldInside] === 'object' && form[field][fieldInside] !== null) {
                                        for (const fieldArray in form[field][fieldInside]) {
                                            if (form[field][fieldInside].hasOwnProperty(fieldArray)) {
                                                console.log(`Independiente ${fieldArray}x3: ${form[field][fieldInside][fieldArray]}`);
                                                console.log(`type: ${form[field][fieldInside]['type']}`);
        
                                                if ( fieldArray === 'relationship' && form[field][fieldInside][fieldArray] === '' && form[field][fieldInside]['type'] !== 'Comercial') {
                                                    return true
                                                }
                                                if (form[field][fieldInside][fieldArray] === '' && fieldArray !== 'relationship') {
                                                    return true
                                                }
                                            }
                                        }
                                    }
                                    if ( fieldInside !== 'administrationCost' && form[field][fieldInside] === '') {
                                        return true
                                    }
                                }
                            }
                        } else {
                            if (form[field] === '') {
                                return true
                            }
                        }
                        break;
                    case 'Pensionado':
                        if (typeof form[field] === 'object' && field !== 'vehicles' && field !== 'properties' && field !== 'finantialInformation' && field !== 'employee' && field !== 'independent' && form[field] !== null) {
                            for (const fieldInside in form[field]) {
                                if (form[field].hasOwnProperty(fieldInside)) {
                                    //console.log(`Pensionado ${fieldInside}x2: ${form[field][fieldInside]}`);
                                    //console.log(`type: ${form.userType}`);
        
                                    if (typeof form[field][fieldInside] === 'object' && form[field][fieldInside] !== null) {
                                        for (const fieldArray in form[field][fieldInside]) {
                                            if (form[field][fieldInside].hasOwnProperty(fieldArray)) {
                                                console.log(`Pensionado ${fieldArray}x3: ${form[field][fieldInside][fieldArray]}`);
                                                console.log(`type: ${form[field][fieldInside]['type']}`);
        
                                                if ( fieldArray === 'relationship' && form[field][fieldInside][fieldArray] === '' && form[field][fieldInside]['type'] !== 'Comercial') {
                                                    return true
                                                }
                                                if (form[field][fieldInside][fieldArray] === '' && fieldArray !== 'relationship') {
                                                    return true
                                                }
                                            }
                                        }
                                    }
                                    if ( fieldInside !== 'administrationCost' && form[field][fieldInside] === '') {
                                        return true
                                    }
                                }
                            }
                        } else {
                            if (form[field] === '') {
                                return true
                            }
                        }
                        break;
                    default:
                        return true;
                }
            }
        }
        return false
    }

    const createFunction = (data) => {
        if(data.code === "01"){
            setLoadData(true);
            Swal.fire({
                title:"Error",
                confirmButtonColor:"#76b4ff",
                text: data.message
            });
        }else{
            setLoadData(true);
            //console.log(data);
            Swal.fire({
                title:"¡Éxito!",
                confirmButtonColor:"#76b4ff",
                text: "Solicitud creada con éxito, pronto se prondrán en contacto con usted, muchas gracias"
            });
        }
    }

    useEffect(() => {
        //console.log('form',form)
        //console.log('isSubmitted',isSubmitted)
    }, [form]);

    return(
        <div className="w-100 d-flex">
            {currentData && Object.keys(currentData).length > 0?
                <div className="container d-flex flex-column">
                    <div className="w-100 d-flex align-items-center flex-wrap request-header-container">
                        <h2 className="request-header">Información del inmueble</h2>
                        <img src={logo} alt="Logo" className="request-logo" />
                    </div>
                    <div className="d-flex w-100 flex-wrap">
                        <div className="d-flex flex-column w-100 field">
                            <label className='label-field'>Ubicación</label>
                            <span className="span-field">{currentData.ubication}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className='label-field'>Tipo de vivienda</label>
                            <span className="span-field">{currentData.propertyType}</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className='label-field'>Valor mes</label>
                            <span className="span-field">${currentData.monthCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} COP</span>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className='label-field'>¿Posee cuota de administración?</label>
                            <span className="span-field">{currentData.hasAdministrationFee? 'Sí': 'No'}</span>
                        </div>
                        {currentData.hasAdministrationFee?
                            <div className="d-flex flex-column w-25 field">
                                <label className='label-field'>¿Está incluido el valor de administración?</label>
                                <span className="span-field">{currentData.feeIncluded? 'Sí': 'No'}</span>
                            </div>
                        :null}
                        {currentData.hasAdministrationFee && !currentData.feeIncluded?
                            <div className="d-flex flex-column w-25 field">
                                <label className='label-field'>Valor de la administración</label>
                                <span className="span-field">${currentData.administrationCost}</span>
                            </div>
                        :null}
                    </div>
                    <h2 className="my-5">Nueva solicitud</h2>
                    <form onSubmit={handleOnSubmit}>
                        <div className="d-flex w-100 flex-wrap">
                            <h4 className="w-100 my-2">Datos Generales</h4>
                            <div className={`d-flex flex-column w-25 field ${checkEmptyfield('firstName')}`}>
                                <label className="label-field" htmlFor="firstName">Nombres</label>
                                <input className="input-field" id="firstName" name="firstName" autoComplete="off" maxLength={100} type="text" onChange={handleInputChange} value={form.firstName}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkEmptyfield('lastName')}`}>
                                <label className="label-field" htmlFor="lastName">Apellidos</label>
                                <input className="input-field" id="lastName" name="lastName" autoComplete="off" maxLength={100}  type="text" onChange={handleInputChange} value={form.lastName}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkEmptyfield('documentType')}`}>
                                <label className="label-field" htmlFor="documentType">Tipo de documento</label>
                                <select className="input-field" id="documentType" name="documentType" onChange={handleInputChange} value={form.documentType}>
                                    <option></option>
                                    {documentTypes.map((documentType, index) => (
                                        <option key={index} value={documentType.value}>{documentType.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkEmptyfield('document')}`}>
                                <label className="label-field" htmlFor="document">Documento</label>
                                <input className="input-field" id="document" name="document" autoComplete="off" maxLength={15} type="number" onChange={handleInputChange} value={form.document}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkEmptyfield('documentProvince')}`}>
                                <label className="label-field" htmlFor="documentProvince">Depto de expedición del documento</label>
                                <select className="input-field" id="documentProvince" name="documentProvince" onChange={handleOnChange} value={form.documentProvince}>
                                    <option></option>
                                    {provinces.map((province) => (
                                        <option key={province.name} value={province.name}>{province.name}</option>
                                    ))}
                                </select>
                            </div>
                            {form.documentProvince !== '' && citiesData.documentCities && citiesData.documentCities !== [] ? 
                                <div className={`d-flex flex-column w-25 field ${checkEmptyfield('documentCity')}`}>
                                    <label className="label-field" htmlFor="documentCity">Ciudad de expedición del documento</label>
                                    <select className="input-field" id="documentCity" name="documentCity" onChange={handleInputChange} value={form.documentCity}>
                                        <option></option>
                                        {citiesData.documentCities.map((city) => (
                                            <option key={city.name} value={city.name}>{city.name}</option>
                                        ))}
                                    </select>
                                </div>
                            :''}
                            <div className={`d-flex flex-column w-25 field ${checkEmptyfield('email')}`}>
                                <label className="label-field" htmlFor="email">Email</label>
                                <input className="input-field" id="email" name="email" autoComplete="off" maxLength={120} type="email" onChange={handleInputChange} value={form.email}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkEmptyfield('phone')}`}>
                                <label className="label-field" htmlFor="phone">Teléfono&#47;Celular</label>
                                <input className="input-field" id="phone" name="phone" autoComplete="off" maxLength={15} type="number" onChange={handleInputChange} value={form.phone}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkEmptyfield('occupation')}`}>
                                <label className="label-field" htmlFor="occupation">Ocupación</label>
                                <input className="input-field" id="occupation" name="occupation" autoComplete="off" maxLength={150} type="text" onChange={handleInputChange} value={form.occupation}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkEmptyfield('dependents')}`}>
                                <label className="label-field" htmlFor="dependents">Personas a cargo</label>
                                <input className="input-field" id="dependents" name="dependents" autoComplete="off" maxLength={15} min={0} type="number" onChange={handleInputChange} value={form.dependents}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkEmptyfield('peopleWhoWillLive')}`}>
                                <label className="label-field" htmlFor="peopleWhoWillLive">No personas que habitarán el hogar</label>
                                <input className="input-field" id="peopleWhoWillLive" name="peopleWhoWillLive" autoComplete="off" maxLength={15} min={1} type="number" onChange={handleInputChange} value={form.peopleWhoWillLive}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkEmptyfield('monthlyExpenses')}`}>
                                <label className="label-field" htmlFor="monthlyExpenses">Gastos mensuales</label>
                                <input className="input-field" id="monthlyExpenses" name="monthlyExpenses" autoComplete="off" maxLength={15} min={1} type="number" onChange={handleInputChange} value={form.monthlyExpenses}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkEmptyfield('monthlyIncome')}`}>
                                <label className="label-field" htmlFor="monthlyIncome">Ingresos mensuales</label>
                                <input className="input-field" id="monthlyIncome" name="monthlyIncome" autoComplete="off" maxLength={15} min={1} type="number" onChange={handleInputChange} value={form.monthlyIncome}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkEmptyfield('userType')}`}>
                                <label className="label-field" htmlFor="userType">Tipo</label>
                                <select className="input-field" id="userType" name="userType" onChange={handleInputChange} value={form.userType}>
                                    <option></option>
                                    {requestTypes.map((userType, index) => (
                                        <option key={index}>{userType}</option>
                                    ))}
                                </select>
                            </div>
                            <h4 className="w-100 my-2">Vivienda actual</h4>
                            <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('actualProperty', 'type')}`}>
                                <label className="label-field" htmlFor="type">Tipo de propiedad</label>
                                <select className="input-field" id="type" name="type" onChange={(e)=>handleInsideInputChange('actualProperty', e)} value={form.actualProperty.type}>
                                    <option></option>
                                    {actualPropertyTypes.map((type, index) => (
                                        <option key={index} value={type}>{type}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('actualProperty','address')}`}>
                                <label className="label-field" htmlFor="address">Dirección</label>
                                <input className="input-field" id="address" name="address" autoComplete="off" maxLength={150} type="text" onChange={(e)=>handleInsideInputChange('actualProperty', e)} value={form.actualProperty.address}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('actualProperty','neighborhood')}`}>
                                <label className="label-field" htmlFor="neighborhood">Barrio</label>
                                <input className="input-field" id="neighborhood" name="neighborhood" autoComplete="off" maxLength={100} type="text" onChange={(e)=>handleInsideInputChange('actualProperty', e)} value={form.actualProperty.neighborhood}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('actualProperty','actualPropertyProvince')}`}>
                                <label className="label-field" htmlFor="actualPropertyProvince">Departamento</label>
                                <select className="input-field" id="actualPropertyProvince" name="actualPropertyProvince" onChange={handleOnChange} value={form.actualProperty.actualPropertyProvince}>
                                    <option></option>
                                    {provinces.map((province) => (
                                        <option key={province.name} value={province.name}>{province.name}</option>
                                    ))}
                                </select>
                            </div>
                            {form.actualProperty.actualPropertyProvince !== '' && citiesData.actualPropertyCities && citiesData.actualPropertyCities !== []? 
                                <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('actualProperty','actualPropertyCity')}`}>
                                    <label className="label-field" htmlFor="actualPropertyCity">Ciudad</label>
                                    <select className="input-field" id="actualPropertyCity" name="actualPropertyCity" onChange={(e)=>handleInsideInputChange('actualProperty', e)} value={form.actualProperty.actualPropertyCity}>
                                        <option></option>
                                        {citiesData.actualPropertyCities.map((city) => (
                                            <option key={city.name} value={city.name}>{city.name}</option>
                                        ))}
                                    </select>
                                </div>
                            :''}
                            <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('actualProperty','landlord')}`}>
                                <label className="label-field" htmlFor="landlord">Nombre del administrador&#47;propietario</label>
                                <input className="input-field" id="landlord" name="landlord" autoComplete="off" maxLength={150} type="text" onChange={(e)=>handleInsideInputChange('actualProperty', e)} value={form.actualProperty.landlord}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('actualProperty','landlordPhone')}`}>
                                <label className="label-field" htmlFor="landlordPhone">Teléfono del administrador&#47;propietario</label>
                                <input className="input-field" id="landlordPhone" name="landlordPhone" autoComplete="off" maxLength={15} type="number" onChange={(e)=>handleInsideInputChange('actualProperty', e)} value={form.actualProperty.landlordPhone}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('actualProperty','monthlyCost')}`}>
                                <label className="label-field" htmlFor="monthlyCost">Costo mensual</label>
                                <input className="input-field" id="monthlyCost" name="monthlyCost" autoComplete="off" maxLength={15} type="number" onChange={(e)=>handleInsideInputChange('actualProperty', e)} value={form.actualProperty.monthlyCost}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field`}>
                                <label className="label-field" htmlFor="administrationCost">Costo de administración (opcional)</label>
                                <input className="input-field" id="administrationCost" name="administrationCost" autoComplete="off" maxLength={15} type="number" onChange={(e)=>handleInsideInputChange('actualProperty', e)} value={form.actualProperty.administrationCost}></input>
                            </div>
                            <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('actualProperty','antiquity')}`}>
                                <label className="label-field" htmlFor="antiquity">Antigüedad</label>
                                <input className="input-field" id="antiquity" name="antiquity" autoComplete="off" maxLength={15} type="text" value={form.actualProperty.antiquity} placeholder='Tiempo en años' onChange={(e)=>handleInsideInputChange('actualProperty', e)} ></input>
                            </div>
                            {form.userType === 'Empleado'?
                                <div className="d-flex w-100 flex-wrap">
                                    <h4 className="w-100 my-2">Empleado</h4>
                                    <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('employee','company')}`}>
                                        <label className="label-field" htmlFor="company">Empresa</label>
                                        <input className="input-field" id="company" name="company" autoComplete="off" maxLength={150} type="text" onChange={(e)=>handleInsideInputChange('employee',e)} value={form.employee.company}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('employee','address')}`}>
                                        <label className="label-field" htmlFor="address">Dirección</label>
                                        <input className="input-field" id="address" name="address" autoComplete="off" maxLength={150} type="text" onChange={(e)=>handleInsideInputChange('employee',e)} value={form.employee.address}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('employee','position')}`}>
                                        <label className="label-field" htmlFor="position">Cargo</label>
                                        <input className="input-field" id="position" name="position" autoComplete="off" maxLength={100} type="text" onChange={(e)=>handleInsideInputChange('employee',e)} value={form.employee.position}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('employee','salary')}`}>
                                        <label className="label-field" htmlFor="salary">Salario</label>
                                        <input className="input-field" id="salary" name="salary" autoComplete="off" maxLength={100} min={1} type="number" onChange={(e)=>handleInsideInputChange('employee',e)} value={form.employee.salary}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('employee','admissionDay')}`}>
                                        <label className="label-field" htmlFor="admissionDay">Fecha de ingreso</label>
                                        <DatePicker
                                            value={ form.employee.admissionDay }
                                            dayAriaLabel={"Día"}
                                            dayPlaceholder={"dd"}
                                            monthAriaLabel={"Mes"}
                                            monthPlaceholder={"mm"}
                                            yearAriaLabel={"Año"}
                                            yearPlaceholder={"yyyy"}
                                            name={"admissionDay"}
                                            onChange={ (date)=>handleDateChange( 'admissionDay', date ) }
                                            dateFormat="MM/dd/yyyy"
                                        />
                                    </div>
                                </div>
                            :null}
                            {form.userType === 'Independiente'?
                                <div className="d-flex w-100 flex-wrap">
                                    <h4 className="w-100 my-2">Independiente</h4>
                                    <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('independent','activity')}`}>
                                        <label className="label-field" htmlFor="activity">Actividad principal</label>
                                        <input className="input-field" id="activity" name="activity" autoComplete="off" maxLength={150} type="text" onChange={(e)=>handleInsideInputChange('independent',e)} value={form.independent.activity}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('independent','address')}`}>
                                        <label className="label-field" htmlFor="address">Dirección</label>
                                        <input className="input-field" id="address" name="address" autoComplete="off" maxLength={150} type="text" onChange={(e)=>handleInsideInputChange('independent',e)} value={form.independent.address}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('independent','employeesNumber')}`}>
                                        <label className="label-field" htmlFor="employeesNumber">No empleados a cargo</label>
                                        <input className="input-field" id="employeesNumber" name="employeesNumber" autoComplete="off" maxLength={100} type="number" onChange={(e)=>handleInsideInputChange('independent',e)} value={form.independent.employeesNumber}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('independent','income')}`}>
                                        <label className="label-field" htmlFor="income">Ingresos</label>
                                        <input className="input-field" id="income" name="income" autoComplete="off" maxLength={100} min={1} type="number" onChange={(e)=>handleInsideInputChange('independent',e)} value={form.independent.income}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('independent','expenses')}`}>
                                        <label className="label-field" htmlFor="expenses">Egresos</label>
                                        <input className="input-field" id="expenses" name="expenses" autoComplete="off" maxLength={100} min={1} type="text" onChange={(e)=>handleInsideInputChange('independent',e)} value={form.independent.expenses}></input>
                                    </div>
                                </div>
                            :null}
                            {form.userType === 'Pensionado'?
                                <div className="d-flex w-100 flex-wrap">
                                    <h4 className="w-100 my-2">Pensionado</h4>
                                    <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('pensionary','reason')}`}>
                                        <label className="label-field" htmlFor="reason">Razón de la pensión</label>
                                        <input className="input-field" id="reason" name="reason" autoComplete="off" maxLength={150} type="text" placeholder='Describa la razón por la cual fue pensionado/a' onChange={(e)=>handleInsideInputChange('pensionary',e)} value={form.pensionary.reason}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkInsideEmptyfield('pensionary','value')}`}>
                                        <label className="label-field" htmlFor="value">Valor de la pensión</label>
                                        <input className="input-field" id="value" name="value" autoComplete="off" maxLength={150} type="text" onChange={(e)=>handleInsideInputChange('pensionary',e)} value={form.pensionary.value}></input>
                                    </div>
                                </div>
                            :null}
                            <h4 className="w-100 my-2">Propiedades (Opcional)</h4>
                            {form.properties.map((property, index) => (
                                <div key={index} className="d-flex w-100 flex-wrap position-relative">
                                    {form.properties.length > 1? <span className='w-100'>Propiedad <b>{index+1}</b></span>:null}
                                    {form.properties.length > 1? <span className='delete-button' onClick={()=>deleteObj('properties',index)}>&#120;</span>:null}
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`propertyType${index}`}>Tipo de propiedad</label>
                                        <select className="input-field" id={`propertyType${index}`} name={`type`} onChange={(e)=>handleDynamicInputChange('properties',index,e)} value={property.type}>
                                            <option></option>
                                            {propertyTypes.map((type, index) => (
                                                <option key={index} value={type}>{type}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`propertyAddress${index}`}>Dirección</label>
                                        <input id={`propertyAddress${index}`} name={`address`} autoComplete="off" maxLength={150} className="input-field" type="text" onChange={(e)=>handleDynamicInputChange('properties',index,e)} value={property.address}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`propertyProvince${index}`}>Departamento</label>
                                        <select className="input-field" id={`propertyProvince${index}`} name="propertyProvince" onChange={(e)=>handleDynamicOnChange('properties','propertiesCities',index, e)} value={property.propertyProvince}>
                                            <option></option>
                                            {provinces.map((province) => (
                                                <option key={province.name} value={province.name}>{province.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {property.propertyProvince !== '' && citiesData.propertiesCities[index] && citiesData.propertiesCities[index] !== []? 
                                        <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                            <label className="label-field" htmlFor={`propertyCity${index}`}>Ciudad</label>
                                            <select className="input-field" id={`propertyCity${index}`} name="propertyCity" onChange={(e)=>handleDynamicInputChange('properties',index,e)} value={property.propertyCity}>
                                                <option></option>
                                                {citiesData.propertiesCities[index].map((city) => (
                                                    <option key={city.name} value={city.name}>{city.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    :''}
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`propertyValue${index}`}>Valor</label>
                                        <input id={`propertyValue${index}`} name="value" autoComplete="off" maxLength={100} className="input-field" type="number" onChange={(e)=>handleDynamicInputChange('properties',index,e)} value={property.value}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`realEstateRegistration${index}`}>Matrícula inmobiliaria</label>
                                        <input id={`realEstateRegistration${index}`} name="realEstateRegistration" autoComplete="off" maxLength={150} className="input-field" type="text" onChange={(e)=>handleDynamicInputChange('properties',index,e)} value={property.realEstateRegistration}></input>
                                    </div>
                                </div>
                            ))}
                            <div className="d-flex field justify-content-center w-100">
                                <button className='add-icon-button' type='button' onClick={()=>addObj('properties')}>&#43;</button>
                            </div>
                            <h4 className="w-100 my-2">Vehículos (Opcional)</h4>
                            {form.vehicles.map((vehicle, index) => (
                                <div key={index} className="d-flex w-100 flex-wrap position-relative">
                                    {form.vehicles.length > 1? <span className='w-100'>Vehículo <b>{index+1}</b></span>:null}
                                    {form.vehicles.length > 1? <span className='delete-button' onClick={()=>deleteObj('vehicles',index)}>&#120;</span>:null}
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`vehicleType${index}`}>Tipo</label>
                                        <select className="input-field" id={`vehicleType${index}`} name="type" onChange={(e)=>handleDynamicInputChange('vehicles',index,e)} value={vehicle.type}>
                                            <option></option>
                                            {vehicleTypes.map((type, index) => (
                                                <option key={index} value={type}>{type}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`brand${index}`}>Marca</label>
                                        <input id={`brand${index}`} name="brand" autoComplete="off" maxLength={150} className="input-field" type="text" onChange={(e)=>handleDynamicInputChange('vehicles',index,e)} value={vehicle.brand}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`model${index}`}>Modelo</label>
                                        <input id={`model${index}`} name="model" autoComplete="off" maxLength={150} className="input-field" type="text" onChange={(e)=>handleDynamicInputChange('vehicles',index,e)} value={vehicle.model}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`plaque${index}`}>Placa</label>
                                        <input id={`plaque${index}`} name="plaque" autoComplete="off" maxLength={150} className="input-field" type="text" onChange={(e)=>handleDynamicInputChange('vehicles',index,e)} value={vehicle.plaque}></input>
                                    </div>
                                </div>
                            ))}
                            <div className="d-flex field justify-content-center w-100">
                                <button className='add-icon-button' type='button' onClick={()=>addObj('vehicles')}>&#43;</button>
                            </div>
                            <h4 className="w-100 my-2">Información financiera: Obligaciones vigentes (Opcional)</h4>
                            {form.finantialInformation.map((information, index) => (
                                <div key={index} className="d-flex w-100 flex-wrap position-relative">
                                    {form.finantialInformation.length > 1? <span className='w-100'>Obligación <b>{index+1}</b></span>:null}
                                    {form.finantialInformation.length > 1? <span className='delete-button' onClick={()=>deleteObj('finantialInformation',index)}>&#120;</span>:null}
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`informationType${index}`}>Tipo de obligación</label>
                                        <select className="input-field" id={`informationType${index}`} name={`type`} onChange={(e)=>handleDynamicInputChange('finantialInformation',index,e)} value={information.type}>
                                            <option></option>
                                            {finantialInformationType.map((type, index) => (
                                                <option key={index} value={type}>{type}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`entity${index}`}>Entidad</label>
                                        <input id={`entity${index}`} name={`entity`} autoComplete="off" maxLength={150} className="input-field" type="text" onChange={(e)=>handleDynamicInputChange('finantialInformation',index,e)} value={information.entity}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`informationValue${index}`}>Valor</label>
                                        <input id={`informationValue${index}`} name="value" autoComplete="off" maxLength={100} className="input-field" type="number" onChange={(e)=>handleDynamicInputChange('finantialInformation',index,e)} value={information.value}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`fee${index}`}>Valor cuota</label>
                                        <input id={`fee${index}`} name="fee" autoComplete="off" maxLength={150} className="input-field" type="number" onChange={(e)=>handleDynamicInputChange('finantialInformation',index,e)} value={information.fee}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkEmptyfield('')}`}>
                                        <label className="label-field" htmlFor={`remaining${index}`}>Valor restante</label>
                                        <input id={`remaining${index}`} name="remaining" autoComplete="off" maxLength={150} className="input-field" type="number" onChange={(e)=>handleDynamicInputChange('finantialInformation',index,e)} value={information.remaining}></input>
                                    </div>
                                </div>
                            ))}
                            <div className="d-flex field justify-content-center w-100">
                                <button className='add-icon-button' type='button' onClick={()=>addObj('finantialInformation')}>&#43;</button>
                            </div>
                            <h4 className="w-100 my-2">Referencias</h4>
                            <span className='w-100'>Son obligatorias una referencia familiar, una personal y 2 comerciales</span>
                            {form.references.map((reference, index) => (
                                <div key={index} className="d-flex w-100 flex-wrap">
                                    <div className={`d-flex flex-column w-25 field`}>
                                        <label className="label-field" htmlFor={`type${index}`}>Tipo</label>
                                        <select className="input-field" id={`type${index}`} name={`type${index}`} value={reference.type} disabled={true}>
                                            {referenceTypes.map((type, index) => (
                                                <option key={index} value={type}>{type}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkDynamicEmptyField('references', index, 'fullname')}`}>
                                        <label className="label-field" htmlFor={`fullname${index}`}>Nombres completos</label>
                                        <input id={`fullname${index}`} name={`fullname`} autoComplete="off" maxLength={180} className="input-field" type="text" onChange={(e)=>handleDynamicInputChange('references',index,e)} value={reference.fullname}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkDynamicEmptyField('references', index, 'phone')}`}>
                                        <label className="label-field" htmlFor={`phone${index}`}>Teléfono</label>
                                        <input id={`phone${index}`} name={`phone`} autoComplete="off" maxLength={15} className="input-field" type="number" onChange={(e)=>handleDynamicInputChange('references',index,e)} value={reference.phone}></input>
                                    </div>
                                    {reference.type !== 'Comercial'?
                                        <div className={`d-flex flex-column w-25 field ${checkDynamicEmptyField('references', index, 'relationship')}`}>
                                            <label className="label-field" htmlFor={`relationship${index}`}>Parentesco</label>
                                            <input id={`relationship${index}`} name={`relationship`} autoComplete="off" maxLength={150} className="input-field" type="text" onChange={(e)=>handleDynamicInputChange('references',index,e)} value={reference.relationship}></input>
                                        </div>
                                    :null}
                                </div>
                            ))}
                            <h4 className="w-100 my-2">Codeudores</h4>
                            <span className='w-100'>Puede añadir más si así lo desea</span>
                            {form.cosigners.map((cosigner, index) => (
                                <div key={index} className="d-flex w-100 flex-wrap position-relative">
                                    {form.cosigners.length > 1? <span className='w-100'>Codeudor <b>{index+1}</b></span>:null}
                                    {form.cosigners.length > 1? <span className='delete-button' onClick={()=>deleteCosigner(index)}>&#120;</span>:null}
                                    <div className={`d-flex flex-column w-25 field ${checkDynamicEmptyField('cosigners',index,'firstName')}`}>
                                        <label className="label-field" htmlFor={`firstName${index}`}>Nombres</label>
                                        <input id={`firstName${index}`} name={`firstName`} autoComplete="off" maxLength={100} className="input-field" type="text" onChange={(e)=>handleDynamicInputChange('cosigners',index,e)} value={cosigner.firstName}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkDynamicEmptyField('cosigners',index,'lastName')}`}>
                                        <label className="label-field" htmlFor={`lastName${index}`}>Apellidos</label>
                                        <input id={`lastName${index}`} name={`lastName`} autoComplete="off" maxLength={100} className="input-field" type="text" onChange={(e)=>handleDynamicInputChange('cosigners',index,e)} value={cosigner.lastName}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkDynamicEmptyField('cosigners',index,'documentType')}`}>
                                        <label className="label-field" htmlFor={`documentType${index}`}>Tipo de documento</label>
                                        <select className="input-field" id={`documentType${index}`} name={`documentType`} onChange={(e)=>handleDynamicInputChange('cosigners',index,e)} value={cosigner.documentType}>
                                            <option></option>
                                            {documentTypes.map((documentType, index) => (
                                                <option key={index} value={documentType.value}>{documentType.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkDynamicEmptyField('cosigners',index,'document')}`}>
                                        <label className="label-field" htmlFor={`document${index}`}>Documento</label>
                                        <input id={`document${index}`} name={`document`} autoComplete="off" maxLength={15} className="input-field" type="number" onChange={(e)=>handleDynamicInputChange('cosigners',index,e)} value={cosigner.document}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkDynamicEmptyField('cosigners',index,'phone')}`}>
                                        <label className="label-field" htmlFor={`cosignerPhone${index}`}>Teléfono&#47;Celular</label>
                                        <input id={`cosignerPhone${index}`} name={`phone`} autoComplete="off" maxLength={15} className="input-field" type="number" onChange={(e)=>handleDynamicInputChange('cosigners',index,e)} value={cosigner.phone}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkDynamicEmptyField('cosigners',index,'email')}`}>
                                        <label className="label-field" htmlFor={`email${index}`}>Email</label>
                                        <input id={`email${index}`} name="email" autoComplete="off" maxLength={120} className="input-field" type="email" onChange={(e)=>handleDynamicInputChange('cosigners',index,e)} value={cosigner.email}></input>
                                    </div>
                                    <div className={`d-flex flex-column w-25 field ${checkDynamicEmptyField('cosigners',index,'cosignerProvince')}`}>
                                        <label className="label-field" htmlFor={`cosignerProvince${index}`}>Departamento de residencia</label>
                                        <select className="input-field" id={`cosignerProvince${index}`} name={`cosignerProvince`} onChange={(e)=>handleDynamicOnChange('cosigners','cosignersCities',index, e)} value={cosigner.cosignerProvince}>
                                            <option></option>
                                            {provinces.map((province) => (
                                                <option key={province.name} value={province.name}>{province.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {form.cosigners[index].cosignerProvince !== '' && citiesData.cosignersCities[index] && citiesData.cosignersCities[index] !== []? 
                                        <div className={`d-flex flex-column w-25 field ${checkDynamicEmptyField('cosigners',index,'cosignerCity')}`}>
                                            <label className="label-field" htmlFor={`cosignerCity${index}`}>Ciudad de residencia</label>
                                            <select className="input-field" id={`cosignerCity${index}`} name={`cosignerCity`} onChange={(e)=>handleDynamicInputChange('cosigners',index,e)} value={cosigner.cosignerCity}>
                                                <option></option>
                                                {citiesData.cosignersCities[index].map((city) => (
                                                    <option key={city.name} value={city.name}>{city.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    :''}
                                    <div className={`d-flex flex-column w-25 field ${checkDynamicEmptyField('cosigners',index,'address')}`}>
                                        <label className="label-field" htmlFor={`address${index}`}>Dirección</label>
                                        <input id={`address${index}`} name={`address`} autoComplete="off" maxLength={150} className="input-field" type="text" onChange={(e)=>handleDynamicInputChange('cosigners',index,e)} value={cosigner.address}></input>
                                    </div>
                                </div>
                            ))}
                            <div className="d-flex field justify-content-center w-100">
                                <button className='add-icon-button' type='button' onClick={()=>addObj('cosigners')}>&#43;</button>
                            </div>
                            <h4 className="w-100 my-2">Requisitos</h4>
                            <ul>
                                {requirements.map((requirement, index)=>(
                                    <li key={index}>{requirement}</li>
                                ))}
                            </ul>
                            <p className="w-100" style={{textAlign: 'justify'}}>Todos los documentos anteriormente mencionados deben de ser enviados al correo: <b>notificaciones@ayhgrupo.com</b></p>
                            <p className="w-100" style={{textAlign: 'justify'}}><b>NOTA:</b> Para la acreditación de la finca raíz de los codeudores se debe de presentar certificado de tradición y libertad (la propiedad debe estar libre de cualquier limitación al dominio) y un recibo de servicios públicos</p>
                            <div className={`d-flex flex-column w-100 field ${checkEmptyfield('description')}`}>
                                <label className="label-field" htmlFor='description' style={{textAlign: 'justify'}}>D{descriptionText}</label>
                                <textarea className="input-field" id='description' name="description" rows={3} cols={5}  onChange={handleInputChange} value={form.description}></textarea>
                            </div>
                            <div className={`d-flex flex-column w-100 field ${checkEmptyfield('acceptTerms')}`}>
                                <span className="label-field" style={{textAlign: 'justify'}}>{acceptText}</span>
                                <div className="d-flex field justify-content-center align-items-center w-100">
                                    <input type='checkbox' id='acceptTerms' name='acceptTerms' onChange={handleCheckChange}></input><label htmlFor='acceptTerms' style={{marginLeft: '.5rem', fontWeight: 'bold'}}>Acepto</label>
                                </div>
                            </div>
                            <div className="w-100 d-flex d-flex justify-content-center my-4">
                                <button className="primary-button save-button" type="submit">Guardar &#43;</button>
                            </div>
                        </div>
                    </form>
                </div>
            :null}
        </div>
    );
}

export default Request;
  