import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProperty, updateProperty } from '../../actions/property-actions';
import Paginator from '../../components/paginator/paginator';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatDate, toCurrency } from '../../actions/global-actions';
import { incrementsTableBody, incrementsTableHeaders } from './increments-list-data';
import TableComponent from './../../components/table/table';
import alert from '../../components/alert';

function IncrementsList() {
    const dispatch = useDispatch();
    const params = useParams();
    const [increments, setIncrements] = useState([]);
    const [loadData, setLoadData] = useState(true);
    const user = useSelector(state => state.user.user);
    const currentLink = "/inmuebles";
    const [currentData, setCurrentData] = useState({});

    const [skip, setSkip] = useState(0);
    const [paginatorData, setPaginatorData] = useState({
        totalitems: 0,
        totalpages: 0,
        currentpage: 0
    });
    const navigate = useNavigate();

    useEffect(() => {
        const getFunction = (response) => {
            let data = response.data;
            if(data.code === "01"){
                alert('Error', data.message);
                return;
            }
            
            //console.log(data[0]);
            let requests = data[0].requests || [];
            //console.log('requests', requests);
            let increments = [];
            requests.forEach((request, requestIndex) => {
                request.contracts.forEach((contract, contractIndex) => {
                    contract.increments.forEach((increment, incrementIndex) => {
                        let newIncrement = {};
                        newIncrement.requestIndex = requestIndex;
                        newIncrement.tenant = `${request.firstName} ${request.lastName} ${request.documentType} No ${request.document}`;
                        newIncrement.contractIndex = contractIndex;
                        newIncrement.date = formatDate(increment.createdAt, 4);
                        newIncrement.createdAt = new Date(increment.createdAt);
                        newIncrement.incrementIndex = incrementIndex;
                        let difference = increment.newValue - increment.previousValue;
                        newIncrement.percentage = `${Math.floor(((difference)*100)/increment.previousValue)}%`;
                        //newIncrement.percentage = Math.ceil((increment.previousValue*100)/increment.newValue);
                        newIncrement.previousValueText = toCurrency(increment.previousValue);
                        newIncrement.newValueText = toCurrency(increment.newValue);
                        newIncrement.difference = toCurrency(difference);
                        increments.push(newIncrement);
                    });
                });
            });

            increments.sort((a, b) => b.createdAt - a.createdAt);

            //console.log('increments', increments);

            setIncrements(increments);
            setCurrentData(data[0]);
            /*
            setPaginatorData({
                ...paginatorData,
                ...response.headers,
            })*/
        }

        if (loadData) {
            setLoadData(false);
            setIncrements([]);
            setCurrentData({});
            dispatch( getProperty( params.id, getFunction, user.token ) );
        }
    }, [loadData, dispatch, user.token, params.id, skip, paginatorData]);
    
    const handleOnClick = (increment) => {
        if (window.confirm(`¿Realmente desea eliminar el incremento con datos: Inquilino: ${increment.tenant}, Fecha ${increment.date}, ¡ESTA OPCIÓN NO SE PUEDE DESHACER!`)) {
            currentData.requests[increment.requestIndex].contracts[increment.contractIndex].increments.splice(increment.incrementIndex,1);
            dispatch( updateProperty( currentData, updateFunction, user.token ) );
        }
    }

    const updateFunction = (data) => {
        if(data.data.code === "01"){
            alert('Error', data.data.message);
            return;
        }
        //console.log('data',data);
        alert('¡Éxito!', 'Incremento eliminado con éxito');
        setLoadData(true);
    }

    useEffect(() => {
        //console.log(increments)
    }, [increments]);
    return (
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <div className="w-100 mt-5">
                    <button onClick={()=>navigate(-1)} className="back-button">&#60; Volver</button>
                </div>
                <h1 className="my-5">Aumentos - Inmueble {currentData.code}</h1>
                <div className="d-flex justify-content-end">
                    <Link to={`${currentLink}/${params.id}/nuevo-aumento`} className="primary-button">Registrar aumento</Link>
                </div>
                <TableComponent
                    id={"increments-table"}
                    headers={incrementsTableHeaders}
                    body={incrementsTableBody}
                    skip={skip}
                    data={increments}
                    link={``}
                    needOptions={true}
                    deleteAction={handleOnClick}
                />
                {paginatorData.totalpages > 0 && paginatorData.currentpage > 0 && 
                    <Paginator skip={skip} data={paginatorData} setSkip={(n) => setSkip(n)} setLoadData={(need) => setLoadData(need)} />
                }
            </div>
        </div>
    )
}

export default IncrementsList