import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams  } from 'react-router-dom';
import { getProperty } from '../../actions/property-actions';
import { createPayment } from '../../actions/request-actions';
import alert from '../../components/alert';
import DatePicker from "react-datepicker";
import { formatDate, toCurrency } from '../../actions/global-actions';
import PropertyContractDetail from '../../components/propertyContractDetail/propertyContractDetail';

function EditPayment(){
    const currentLink = "/inmuebles";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.user);
    const [loadData, setLoadData] = useState(true);
    const { id, paymentID } = useParams();
    const [currentData, setCurrentData] = useState({});
    const [currentRequest, setCurrentRequest] = useState({});
    const [currentContract, setCurrentContract] = useState({});
    const [canShow, setCanShow] = useState(false);

    const [form, setForm] = useState({
      _id: '',
      monthPaid: new Date(),
      valuePaid: 0,
      creationUserID: '',
      observation: '',
      createdAt: new Date(),
    });
    
    useEffect(() => {
        if (loadData) {
            const getFunction = (data) => {
                if(data.code === "01"){
                    alert('Error', data.message);
                    return;
                }

                let info = data.data[0];
                //console.log('info', info);
                info.ubication = `${info?.address} B/${info?.neighborhood} ${info?.city}, ${info?.province}`;
                setCurrentData(info);

                let requestObj = {};
                let contractObj = {};
                let paymentObj = {};

                loopX:
                for (let x = 0; x < info.requests.length; x++) {
                    const request = info.requests[x];
                    for (let y = 0; y < request.contracts.length; y++) {
                      const contract = request.contracts[y];
                      const payment = contract.payments.find(payment=>payment._id===paymentID);
                      if (payment) {
                          requestObj = request;
                          contractObj  = contract;
                          paymentObj  = payment;
                          break loopX;
                      }
                    }
                }

                if (Object.keys(contractObj).length === 0 ) {
                    alert('Error', 'No existe un contrato con los datos proporcionados');
                    return;
                }

                if (Object.keys(paymentObj).length === 0 ) {
                    alert('Error', 'No existe un pago con los datos proporcionados');
                    return;
                }

                paymentObj.monthPaid = new Date( paymentObj.monthPaid );
                paymentObj.createdAt = new Date( paymentObj.createdAt );
                requestObj.person = `${requestObj.firstName} ${requestObj.lastName} ${requestObj.documentType} No ${requestObj.document}`;
                contractObj.monthlyCostText = toCurrency(contractObj.monthlyCost);
                contractObj.formattedStartDate = formatDate(contractObj.startDate, 4);
                contractObj.formattedEndDate = formatDate(contractObj.endDate, 4);

                setCurrentRequest(requestObj);

                setCurrentContract(contractObj);
                setCanShow(true);
                setForm({
                    ...form,
                    ...paymentObj
                })
                
            }
            setLoadData(false);

            setForm({
              _id: '',
              monthPaid: new Date(),
              valuePaid: 0,
              creationUserID: '',
              observation: '',
              createdAt: new Date(),
            })
            dispatch( getProperty( id, getFunction, user.token ) );
        }
    }, [loadData, dispatch, form, id, user.token, paymentID ]);
 
    const handleInputChange = (event) => {
      setForm({
          ...form,
          [event.target.name] :  event.target.value
      })
    }

    const handleDateChange = ( name, date ) => {
      setForm({
          ...form,
          [name] :  name==='monthPaid'?new Date(new Date(date).setHours(0,0,0)):date
      })
    }

    const handleOnSubmit = (event) => {
      event.preventDefault();
      const isEmpty = validateFields();
      if (isEmpty) {
          alert('¡Atención!','Debe llenar todos los datos excepto la observación para continuar')
          return;
      }
      let data = JSON.parse(JSON.stringify(currentData));
      let request = JSON.parse(JSON.stringify(currentRequest));
      let contract = JSON.parse(JSON.stringify(currentContract));
      const date = formatDate(form.monthPaid, 6);

      //Se valida que no exista un pago en el mismo mes
      const existPayment = contract.payments.find(payment => (formatDate(new Date(payment.monthPaid), 6) === date && payment._id !== form._id));
      if (existPayment) {
          alert('¡Atención!',`Ya existe un pago realizado para la fecha '${date}'`);
          return;
      }
      const paymentIndex = contract.payments.findIndex( paymentObj => paymentObj._id === form._id );
      const contractIndex = request.contracts.findIndex( contractObj => contractObj._id === contract._id );
      const requestIndex = data.requests.findIndex( requestObj => requestObj._id === request._id );

      data.requests[requestIndex].contracts[contractIndex].payments[paymentIndex] = form;

      dispatch( createPayment( data, updateFunct, user.token ) );
    }

    const validateFields = () => {
        for (const field in form) {
            if (form.hasOwnProperty(field)) {
                //console.log(`${field}: ${form[field]}`);
                if ( form[field] === '' && field !== 'observation' ) {
                    return true
                }
            }
        }
        return false
    }

    const updateFunct = (data) => {
        if(data.code === "01"){
            alert('Error', data.message);
            return;
        }
        alert('¡Éxito!', 'Pago actualizado con éxito');
        navigate(-1);      
    }

    useEffect(() => {
        //console.log(form)
    }, [form]);

    return(
        <div className="w-100 d-flex">
            { (canShow) &&
                <div className="container d-flex flex-column">
                    <div className="w-100 mt-5">
                        <button onClick={()=>navigate(-1)} className="back-button">&#60; Volver</button>
                    </div>
                    <h1 className="my-5">Editar pago - Inmueble {currentData.code}</h1>
                    <PropertyContractDetail
                        data={currentData}
                        request={currentRequest}
                        contract={currentContract}
                        />
                    <form onSubmit={handleOnSubmit}>
                        <div className="d-flex w-100 flex-wrap">
                            <h4 className="w-100 my-2">Datos del pago</h4>
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="createdAt">Fecha de pago</label>
                                <DatePicker
                                    selected={form.createdAt}
                                    dayAriaLabel={"Día"}
                                    dayPlaceholder={"dd"}
                                    monthAriaLabel={"Mes"}
                                    monthPlaceholder={"mm"}
                                    yearAriaLabel={"Año"}
                                    yearPlaceholder={"yyyy"}
                                    name={"createdAt"}
                                    onChange={ (date)=>handleDateChange( 'createdAt', date ) }
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="monthPaid">Fecha a pagar</label>
                                <DatePicker
                                    selected={form.monthPaid}
                                    onChange={(date) => handleDateChange('monthPaid', date)}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    locale="es"
                                />
                            </div>
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="valuePaid">Valor</label>
                                <input id="valuePaid" name="valuePaid" autoComplete="off" maxLength={180} className="input-field" type="number" min={1} onChange={handleInputChange} value={form.valuePaid}></input>
                            </div>
                            <div className="d-flex flex-column w-100 field">
                                <label className="label-field" htmlFor="observation">Observación (Opcional)</label>
                                <textarea id='observation' name="observation" rows={3} cols={5} className="input-field" maxLength={256} placeholder='Ejem: No pago el canon completo por x o y motivo..' onChange={handleInputChange} value={form.observation}></textarea>
                            </div>
                            <div className="w-100 d-flex d-flex justify-content-center my-4">
                                <button className="primary-button save-button" type="submit">Actualizar</button>
                            </div>
                        </div>
                    </form>
                </div>
            }
        </div>
    );
}

export default EditPayment;