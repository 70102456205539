import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { updateUser } from '../../actions/user-actions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../actions/user-actions';
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { documentTypes, userTypes, provinces } from '../../actions/global-actions-data';

function EditUser(){
    const currentLink = "/usuarios";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loadData, setLoadData] = useState(true);
    const params = useParams();
    const user = useSelector(state => state.user.user);

    const accountObj = {
        bank: '',
        accountType: '',
        accountNumber: '',
        owner: '',
    }

    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        documentType: '',
        document: '',
        email: '',
        phone: '',
        userType: '',
        password: '',
        repassword: '',
        city: '',
        province: '',
        documentCity: '',
        documentProvince: '',
        signature: '',
        bankAccount: [{...accountObj}],
    });

    const [imgData, setImgData] = useState({
        name: 'Seleccione un archivo..',
        value: '',
    });
    
    const[ showInfo, setShowInfo ] = useState(false);
    const[ fieldType, setFieldType ] = useState("password");

    const [citiesData, setCitiesData] = useState({
        documentCities: [],
        cities: []
    });

    useEffect(() => {
        if (loadData) {
            const getUserFunction = (data) => {
                if(data.code === "01"){
                    Swal.fire({
                        title:"Error",
                        confirmButtonColor:"#76b4ff",
                        text: data.message
                    });
                }else{
                    //console.log(data.data);
                    const signature = data.data[0].signature;
                    if (signature && signature !== '') {
                        setImgData({
                            ...imgData,
                            value: signature
                        })
                    }

                    if (data.data[0].documentProvince && data.data[0].province) {
                        setCaseCities( data.data[0].documentProvince, data.data[0].province );
                    }

                    if (data.data[0].bankAccount.length === 0) {
                        data.data[0].bankAccount = [{...accountObj}];
                    }

                    setForm({
                        ...form,
                        ...data.data[0]
                    })
                }
            }

            const setCaseCities = ( documentProvince, province ) => {
                const documentCities = provinces.find( x => x.name === documentProvince).cities;
                const cities = provinces.find( x => x.name === province).cities;
                setCitiesData({
                    documentCities,
                    cities
                })
            }

            setLoadData(false);
            setForm({
                firstName: '',
                lastName: '',
                documentType: '',
                document: '',
                email: '',
                phone: '',
                userType: '',
                password: '',
                repassword: '',
                city: '',
                province: '',
                documentCity: '',
                documentProvince: '',
                signature: '',
                bankAccount: [{...accountObj}],
            });
            dispatch( getUser( params.id, getUserFunction, user.token ) );
        }
    }, [loadData, dispatch, params.id, form, imgData, user.token, citiesData ]);

    const handleInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name] :  event.target.value
        })
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (event.target.files.length === 0) {
            setImgData({
                name: 'Seleccione un archivo..',
                value: ''
            })

            setForm({
                ...form,
                signature: ''
            })
            return;
          }
      
        // Asignación y validación de formato del archivo subido, ya que solo se permiten imágenes
        let mimeType = file.type;
        if (mimeType.match(/image\/*/) == null) {
            alert("Solo se permiten imágenes");
            return;
        }
        const fileReader = new FileReader();
        let base64 = '';
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            const unencodedB64 = fileReader.result.split('base64,');
            base64 = unencodedB64[1];
            setImgData({
                name: file.name,
                value: base64
            })

            setForm({
                ...form,
                signature: base64
            })
            //console.log(base64)
        }
        fileReader.onerror = (error) => {
          console.log(error);
        }
    }

    const needShowInfo = () =>{
        if( fieldType === "password" ){
            setFieldType("text");
            setShowInfo(true);
        }
        else{
            setFieldType("password");
            setShowInfo(false);
        }
    }

    const handleOnChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        if (value !== '' && value) {
            const cities = provinces.find( x => x.name === value).cities;
            if (name === 'documentProvince') {
                setCitiesData({
                    ...citiesData,
                    documentCities: cities
                })
            } else {
                setCitiesData({
                    ...citiesData,
                    cities
                })
            }

            setForm({
                ...form,
                [name]: value
            })
        } else {

            if (name === 'documentProvince') {
                setCitiesData({
                    ...citiesData,
                    documentCities: []
                })
                setForm({
                    ...form,
                    documentProvince :  '',
                    documentCity: ''
                })
                return;
            }

            setCitiesData({
                ...citiesData,
                cities: []
            })
            setForm({
                ...form,
                province :  '',
                city: ''
            })

        }
    }

    const handleDynamicInputChange = ( index, event ) => {
        let newArray = [...form.bankAccount];
        newArray[index][event.target.name] = event.target.value;

        setForm({
            ...form,
            bankAccount: [
                ...newArray
            ],
        })
    }

    const addAccount= () => {
        let newArray = [...form.bankAccount];
        newArray.push({...accountObj});

        setForm({
            ...form,
            bankAccount: [
                ...newArray
            ],
        })
    }

    let deleteAcoount = (i) => {
        if (form.bankAccount.length > 1) {
            let newFormValues = {...form};
            newFormValues.bankAccount.splice(i, 1);
            setForm({...newFormValues});
        }
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const isEmpty = validateFields();
        if (isEmpty) {
            Swal.fire({
                title:"¡Atención!",
                confirmButtonColor:"#76b4ff",
                text:"Debe llenar todos los datos para continuar",
            });
            return;
        } else {
            if (validatePasswords()) {
                let newObj = {
                    ...form
                };

                if (newObj.password === '') {
                    delete newObj.password;
                    delete newObj.repassword;
                }

                if (newObj.signature === '') {
                    delete newObj.signature;
                }

                dispatch( updateUser( newObj, updateUserFunction, user.token ) );
            } else {
                Swal.fire({
                    title:"¡Atención!",
                    confirmButtonColor:"#76b4ff",
                    text:"Las contraseñas deben coincidir",
                });
                return;
            }
        }
    }

    const validatePasswords = () => {
        if (form.password !== '') {
            if (form.password !== form.repassword) {
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }
    
    const validateFields = () => {
        for (const field in form) {
            if (form.hasOwnProperty(field)) {
                console.log(`${field}: ${form[field]}`);
                if ( field !== 'password' && field !== 'repassword' && field !== 'signature' && form[field] === '') {
                    return true
                }
            }
        }
        return false
    }

    const updateUserFunction = (data) => {
        if(data.code === "01"){
            Swal.fire({
                title:"Error",
                confirmButtonColor:"#76b4ff",
                text: data.message
            });
        }else{
            navigate(currentLink)
            console.log(data);
            Swal.fire({
                title:"¡Éxito!",
                confirmButtonColor:"#76b4ff",
                text: "Usuario actualizado con éxito"
            });
        }
    }

    //const Signature = ({ data }) => <img src={`data:image/jpeg;base64,${data}`} alt="userSignature" className="preview-signature" />

    useEffect(() => {
        //console.log(form)
    }, [form]);

    return(
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <div className="w-100 mt-5">
                    <Link to={currentLink} className="back-button">&#60; Volver</Link>
                </div>
                <h1 className="my-5">Editar usuario</h1>
                <form onSubmit={handleOnSubmit}>
                    <div className="d-flex w-100 flex-wrap">
                        <h4 className="w-100 mb-2">Datos generales</h4>
                        <div className="d-flex flex-column w-50 field">
                            <label className="label-field" htmlFor="firstName">Nombres</label>
                            <input id="firstName" name="firstName" autoComplete="off" maxLength={100} className="input-field" type="text" onChange={handleInputChange} value={form.firstName}></input>
                        </div>
                        <div className="d-flex flex-column w-50 field">
                            <label className="label-field" htmlFor="lastName">Apellidos</label>
                            <input id="lastName" name="lastName" autoComplete="off" maxLength={100} className="input-field" type="text" onChange={handleInputChange} value={form.lastName}></input>
                        </div>
                        <div className="d-flex flex-column w-3 field">
                            <label className="label-field" htmlFor="documentType">Tipo de documento</label>
                            <select className="input-field" id="documentType" name="documentType" onChange={handleInputChange} value={form.documentType}>
                                <option></option>
                                {documentTypes.map((documentType, index) => (
                                    <option key={index} value={documentType.value}>{documentType.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex flex-column w-3 field">
                            <label className="label-field" htmlFor="document">Documento</label>
                            <input id="document" name="document" autoComplete="off"  maxLength={15} className="input-field" type="number" onChange={handleInputChange} value={form.document}></input>
                        </div>
                        <div className="d-flex flex-column w-3 field">
                            <label className="label-field" htmlFor="documentProvince">Departamento de expedición del documento</label>
                            <select className="input-field" id="documentProvince" name="documentProvince" onChange={handleOnChange} value={form.documentProvince}>
                                <option></option>
                                {provinces.map((province) => (
                                    <option key={province.name} value={province.name}>{province.name}</option>
                                ))}
                            </select>
                        </div>
                        {form.documentProvince !== '' && citiesData.documentCities && citiesData.documentCities !== [] ? 
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="documentCity">Ciudad de expedición del documento</label>
                                <select className="input-field" id="documentCity" name="documentCity" onChange={handleInputChange} value={form.documentCity}>
                                    <option></option>
                                    {citiesData.documentCities.map((city) => (
                                        <option key={city.name} value={city.name}>{city.name}</option>
                                    ))}
                                </select>
                            </div>
                        :''}
                        <div className="d-flex flex-column w-3 field">
                            <label className="label-field" htmlFor="email">Email</label>
                            <input id="email" name="email" autoComplete="off" maxLength={120} className="input-field" type="email" onChange={handleInputChange} value={form.email}></input>
                        </div>
                        <div className="d-flex flex-column w-3 field">
                            <label className="label-field" htmlFor="phone">Teléfono&#47;Celular</label>
                            <input id="phone" name="phone" autoComplete="off"  maxLength={15} className="input-field" type="number" onChange={handleInputChange} value={form.phone}></input>
                        </div>
                        <div className="d-flex flex-column w-3 field">
                            <label className="label-field" htmlFor="province">Departamento de residencia</label>
                            <select className="input-field" id="province" name="province" onChange={handleOnChange} value={form.province}>
                                <option></option>
                                {provinces.map((province) => (
                                    <option key={province.name} value={province.name}>{province.name}</option>
                                ))}
                            </select>
                        </div>
                        {form.province !== '' && citiesData.cities && citiesData.cities !== []? 
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="city">Ciudad de residencia</label>
                                <select className="input-field" id="city" name="city" onChange={handleInputChange} value={form.city}>
                                    <option></option>
                                    {citiesData.cities.map((city) => (
                                        <option key={city.name} value={city.name}>{city.name}</option>
                                    ))}
                                </select>
                            </div>
                        :''}
                        <h4 className="w-100 my-2">Cuenta bancaria</h4>
                        {form.bankAccount.map((account, index) => (
                            <div key={index} className="d-flex w-100 flex-wrap position-relative">
                                {form.bankAccount.length > 1? <span className='w-100'>Cuenta <b>{index+1}</b></span>:null}
                                {form.bankAccount.length > 1? <span className='delete-button' onClick={()=>deleteAcoount(index)}>&#120;</span>:null}
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field" htmlFor={`bank${index}`}>Banco</label>
                                    <input autoComplete="off" id={`bank${index}`} name="bank" className="input-field" type="text" required={true} onChange={(e)=>handleDynamicInputChange(index,e)} value={account.bank}></input>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field" htmlFor={`accountType${index}`}>Tipo de cuenta</label>
                                    <input autoComplete="off" id={`accountType${index}`} name="accountType" className="input-field" type="text" required={true} onChange={(e)=>handleDynamicInputChange(index,e)} value={account.accountType}></input>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field" htmlFor={`accountNumber${index}`}>Número de cuenta bancaria</label>
                                    <input autoComplete="off" id={`accountNumber${index}`} name="accountNumber" className="input-field" type="text" required={true} onChange={(e)=>handleDynamicInputChange(index,e)} value={account.accountNumber}></input>
                                </div>
                                <div className="d-flex flex-column w-25 field">
                                    <label className="label-field" htmlFor={`owner${index}`}>Nombre del propietario de la cuenta</label>
                                    <input autoComplete="off" id={`owner${index}`} name="owner" className="input-field" type="text" required={true} onChange={(e)=>handleDynamicInputChange(index,e)} value={account.owner}></input>
                                </div>
                            </div>
                        ))}
                        <div className="d-flex field justify-content-center w-100">
                            <button className='add-icon-button' type='button' onClick={addAccount}>&#43;</button>
                        </div>
                        <h4 className="w-100 my-2">Administrador</h4>
                        <div className="d-flex flex-column w-3 field">
                            <label className="label-field" htmlFor="userType">Tipo de usuario</label>
                            <select className="input-field" id="userType" name="userType" onChange={handleInputChange} value={form.userType}>
                                <option></option>
                                {userTypes.map((userType, index) => (
                                    <option key={index}>{userType}</option>
                                ))}
                            </select>
                        </div>
                        {/*
                        <div className="d-flex flex-column w-3 field">
                            <label className="label-field" htmlFor="signature">Firma digital</label>
                            <label htmlFor="signature" className="file-upload">
                                {imgData.name}
                            </label>
                            <input id="signature" onChange={handleFileChange} accept="'image/*" type="file"/>
                        </div>
                        */}
                        <div className="d-flex flex-column w-3 field position-relative">
                            <label className="label-field" htmlFor="password">Nueva contraseña (Opcional)</label>
                            <input autoComplete="off" id="password" name="password" minLength={6} maxLength={10} className="input-field" onChange={handleInputChange} placeholder="Mínimo 6 caracteres" type={fieldType}></input>
                            { !showInfo ? 
                                <BsFillEyeFill onClick={needShowInfo} className='eye-icon' />
                            :
                                <BsFillEyeSlashFill onClick={needShowInfo} className='eye-icon' />
                            }
                        </div>
                        <div className="d-flex flex-column w-3 field position-relative">
                            <label className="label-field" htmlFor="repassword">Repetir contraseña</label>
                            <input autoComplete="off" id="repassword" name="repassword" minLength={6} maxLength={10} className="input-field" onChange={handleInputChange} placeholder="Mínimo 6 caracteres" type={fieldType}></input>
                            { !showInfo ? 
                                <BsFillEyeFill onClick={needShowInfo} className='eye-icon' />
                            :
                                <BsFillEyeSlashFill onClick={needShowInfo} className='eye-icon' />
                            }
                        </div>
                        {/*form.signature !== '' ?
                            <div className="d-flex w-100 justify-content-center my-3">
                                <div className="d-flex flex-wrap" style={{width: 'min-content'}}>
                                    <h4 className="w-100 mb-2">Firma previa</h4>
                                    <Signature data={imgData.value} />
                                    <span className="w-100">{form.firstName.toUpperCase()} {form.lastName.toUpperCase()}</span>
                                    <span className="w-100">{form.documentType} No {form.document}</span>
                                </div>
                            </div>
                        :''*/}
                        <div className="w-100 d-flex d-flex justify-content-center my-4">
                            <button className="primary-button save-button" type="submit">Guardar &#43;</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditUser;
  