import logo from '../../assets/logo-inmobiliaria.jpeg';
import { Link, useParams } from 'react-router-dom';
import { getProperty } from './../../actions/property-actions';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { getDocumentData } from '../../actions/documents-actions';
import { documents } from './create-property-data';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import { templateData } from './create-property-data';
import { formatDate } from '../../actions/global-actions';
import alert from '../../components/alert';

const AGREEMENT_TIMES = {
    'Mensual': "1 mes",
    'Mensual': "3 meses",
    'Mensual': "6 meses",
    'Mensual': "1 año",
};

const AGREEMENT_TIMES_DEFAULT_VALUE = "1 año";

function Documents(){
    const currentLink = "/inmuebles";
    const dispatch = useDispatch();
    const [loadData, setLoadData] = useState(true);
    const params = useParams();
    const user = useSelector(state => state.user.user);
    const [currentData, setCurrentData] = useState({});
    const [currentApply, setCurrentApply] = useState({});
    const [documentName, setDocumentName] = useState('');
    const [canShow, setCanShow] = useState(false);
    const [needUpdate, setNeedUpdate] = useState(false);

    useEffect(() => {
        if (loadData) {

            const getFunction = (data) => {
                if(data.code === "01"){
                    alert('Error', data.message);
                    return;
                }
                //console.log('datos', data.data[0]);
                let rows = data.data[0];

                if(rows){
                    rows.administratorName = `${rows.administrator.userID.firstName} ${rows.administrator.userID.lastName}`.toUpperCase();
                    rows.ownerName = `${rows.owner.userID.firstName} ${rows.owner.userID.lastName}`.toUpperCase();
                    rows.managementTime = rows.managementTime.toLowerCase();
                    rows.minimumNotificationTime = rows.minimumNotificationTime.toLowerCase();
                    rows.fullAddress = `${rows.address} B/ ${rows.neighborhood}, ${rows.city} - ${rows.province}`.toUpperCase();
                }

                if(params.type === '3') {
                    let request = rows.requests.find(request => request.status === 'Aprobado' || request.status === 'En contrato');
                    if(!request) {
                        alert('Error', 'Para generar un contrato de arrendamiento primero debe aceptar una solicitud de algún aspirante');
                        return;
                    }
                    const contract = request.contracts.find(contract => contract.status === 'Activo');
                    request.name = `${request.firstName} ${request.lastName}`.toUpperCase();
                    request.actualProperty.fullAddress = `${request.actualProperty.address} B/ ${request.actualProperty.neighborhood}, ${request.actualProperty.actualPropertyCity} - ${request.actualProperty.actualPropertyProvince}`;

                    request.cosigners.map(cosigner => {
                        cosigner.fullName = `${cosigner.firstName} ${cosigner.lastName}`.toUpperCase();
                        cosigner.fullAddress = `${cosigner.address}, ${cosigner.cosignerCity} - ${cosigner.cosignerProvince}`;
                        return cosigner;
                    })

                    contract.startDate = formatDate(new Date(contract.startDate), 1);
                    contract.unformatedStartDate = new Date(contract.startDate);
                    contract.endDate = formatDate(new Date(contract.endDate), 1);
                    contract.unformatedEndDate = new Date(contract.endDate);

                    contract.totalTime = AGREEMENT_TIMES[contract.agreementTime] || AGREEMENT_TIMES_DEFAULT_VALUE;
                    request.currentContract = contract;
                    setCurrentApply(request);
                }

                if(params.type === '5') {
                    const request = rows.requests.find(request => request.status === 'Aprobado' || request.status === 'En contrato');
                    if (!request) {
                        alert('Error', 'Para generar un documento de aumento de canon primero debe aceptar una solicitud de algún aspirante');
                        return;
                    }
                    const contract = request.contracts.find(contract => contract.status === 'Activo');
                    if (!contract) {
                        alert('Error', 'Para generar un documento de aumento de canon primero debe generar un contrato con algún aspirante');
                        return;
                    }
                    if (contract.increments.length === 0) {
                        alert('Error', 'Para generar un documento de aumento de canon primero debe tener registrado algún aumento en el sistema');
                        return;
                    }
                    let increment = contract.increments[contract.increments.length-1];
                    let difference = increment.newValue - increment.previousValue;
                    increment.percentage = Math.floor(((difference)*100)/increment.previousValue);
                    rows.increment = increment;
                }

                let name = '';
                name = documents.find( x=> x.value.toString() === params.type).name;
                setDocumentName(name);

                setCurrentData({ ...rows });
                //console.log('params', params);
            }

            setLoadData(false);

            dispatch( getProperty( params.id, getFunction, user.token ) );
        }
    }, [loadData, dispatch, params, user.token, documentName ]);

    const downloadFile = () => {
        let doc = new jsPDF('p', 'pt', 'a4');
        var element = document.querySelector("#capture");

        const opt = {
            margin: [38, 20, 19, 20],
            filename: `${documentName} ${currentData.address}.pdf`,
            enableLinks: false,
            pagebreak: {
              avoid: ['tr'],
              mode: ['css', 'avoid-all'],
            },
            image: { type: 'jpeg', quality: 0.7 },
            html2canvas: {
              allowTaint: true,
              dpi: 300,
              letterRendering: true,
              logging: false,
              scale: 2,
              scrollX: 0,
              scrollY: 0,
            },
            jsPDF: { doc },
        };

        html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get('pdf')
        .then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i < totalPages + 1; i++) {
                pdf.setPage(i);
                pdf.setFontSize(7);

                let heightSpace = pdf.internal.pageSize.getHeight() - 9;
                pdf.addImage(logo, pdf.internal.pageSize.getWidth()-60, 10, 40, 25);

                Object.keys(templateData.company).map((keyName, i) => {
                    pdf.text(
                        `${templateData.company[keyName]}`,
                        (pdf.internal.pageSize.getWidth()/2)-(templateData.company[keyName].length/2),
                        heightSpace,
                    );
                    heightSpace += 3;
                    return i;
                })
            }
        })
        .save();
    }

    useEffect(() => {
        //console.log('currentData', currentData);
        //console.log('currentApply', currentApply);
        if (params && params.type !== '3' && currentData._id ){
            setCanShow(true);
        } else if(params && params.type === '3' && currentData._id && Object.keys(currentApply).length > 0) {
            setCanShow(true);
        } else {
            setCanShow(false);
        }
    }, [ params, currentData, currentApply ]);

    return (
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <div className="w-100 mt-5">
                    <Link to={`${currentLink}/editar/${params.id}`} key={params.id} className="back-button">&#60; Volver</Link>
                </div>
                <h1 className="my-5">{documentName}</h1>
                { (canShow) ? 
                    <div className="container d-flex flex-column">
                        <div className="d-flex flex-column p-4 template-container document-container">
                            <img src={logo} alt="Logo" className="lawer-logo" />
                            {getDocumentData(params.type, currentData, currentApply)}
                        </div>
                        <div className="d-flex justify-content-center w-100 my-2">
                            <button className="btn btn-primary" onClick={downloadFile}>Descargar</button>
                        </div>
                    </div>
                : null}
            </div>
        </div>
    );
}
 
export default Documents;