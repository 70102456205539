import { Link } from "react-router-dom";
import { BsFillTrashFill } from "react-icons/bs";

const defaultDeleteAction = item => {
    //console.log(item);
}

function TableComponent({id, headers, body, data, skip, link, needOptions = false, hasOwnLink = false, deleteAction = defaultDeleteAction}){
    return (
        <>
            { data.length === 0?
                <span style={{textAlign: 'center'}}>Sin registros</span>
            :
                <table id={id} className="list-table my-3">
                    <thead>
                        <tr>
                            <th>#</th>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                            {needOptions &&
                                <th></th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    {!link && !hasOwnLink?
                                        <span>{ (index+1) + skip }</span>
                                        :
                                        <Link to={hasOwnLink?`${item.link}`:`${link}${item._id}`}>{ (index+1) + skip }</Link>
                                    }
                                </td>
                                {body.map( (bodyItem, bodyIndex) => (
                                    <td key={`${index}${bodyIndex}`}>
                                        {!link && !hasOwnLink?
                                            <span className={`${bodyItem.classname} ${item.alertClass && (item.alertItem === bodyItem.data)?'pending-item':''}`} >
                                                {item[bodyItem.data]}
                                            </span>
                                            :
                                            <Link
                                                to={hasOwnLink?`${item.link}`:`${link}${item._id}`}
                                                className={`${bodyItem.classname} ${item.alertClass && (item.alertItem === bodyItem.data)?'pending-item':''}`} >
                                                    {item[bodyItem.data]}
                                            </Link>
                                        }
                                    </td>
                                ))}
                                {needOptions &&
                                    <td>
                                        <BsFillTrashFill onClick={()=>deleteAction(item)} className='trash-icon' />
                                    </td>
                                }
                            </tr> 
                        ))}
                    </tbody>
                </table>
        }
        </>
    )
}

export default TableComponent;