export const documentTypes = [
    {
        name: "Cédula",
        value: "CC"
    },
    {
        name: "Pasaporte",
        value: "PAP"
    },
    {
        name: "Cédula de extranjería",
        value: "CE"
    },
    {
        name: "NIT",
        value: "NIT"
    }
];

export const userTypes = [
    "Administrador", "Propietario"
];

export const actualPropertyTypes = [
    "Propia", "Familiar", "Arriendo"
];

export const requestTypes = [
    "Empleado", "Independiente", "Pensionado", "Persona Jurídica"
];

export const vehicleTypes = [
    "Carro", "Moto"
];

export const referenceTypes = [
    "Familiar", "Personal", "Comercial"
];

export const provinces = [
    { name: "Valle del Cauca", cities: [
        { name: "Cali"},
        { name: "Palmira"},
        { name: "Jamundí"},
        { name: "Alcalá"},
        { name: "Andalucía"},
        { name: "Ansermanuevo"},
        { name: "Argelia"},
        { name: "Bolívar"},
        { name: "Buenaventura"},
        { name: "Guadalajara de Buga"},
        { name: "Bugalagrande"},
        { name: "Caicedonia"},
        { name: "Calima"},
        { name: "Candelaria"},
        { name: "Cartago"},
        { name: "Dagua"},
        { name: "El Águila"},
        { name: "El Cairo"},
        { name: "El Cerrito"},
        { name: "El Dovio"},
        { name: "Florida"},
        { name: "Ginebra"},
        { name: "Guacarí"},
        { name: "La Cumbre"},
        { name: "La Unión"},
        { name: "La Victoria"},
        { name: "Obando"},
        { name: "Pradera"},
        { name: "Restrepo"},
        { name: "Riofrío"},
        { name: "Roldanillo"},
        { name: "San Pedro"},
        { name: "Sevilla"},
        { name: "Toro"},
        { name: "Trujillo"},
        { name: "Tuluá"},
        { name: "Ulloa"},
        { name: "Versalles"},
        { name: "Vijes"},
        { name: "Yotoco"},
        { name: "Yumbo"},
        { name: "Zarzal"}
    ]},
    { name: "Antioquia", cities: [
        { name: "Medellín"},
        { name: "Abejorral"},
        { name: "Abriaquí"},
        { name: "Alejandría"},
        { name: "Amagá"},
        { name: "Amalfi"},
        { name: "Andes"},
        { name: "Angelópolis"},
        { name: "Angostura"},
        { name: "Anorí"},
        { name: "Santafé de Antioquia"},
        { name: "Anza"},
        { name: "Apartadó"},
        { name: "Arboletes"},
        { name: "Argelia"},
        { name: "Armenia"},
        { name: "Barbosa"},
        { name: "Belmira"},
        { name: "Bello"},
        { name: "Betania"},
        { name: "Betulia"},
        { name: "Ciudad Bolívar"},
        { name: "Briceño"},
        { name: "Buriticá"},
        { name: "Cáceres"},
        { name: "Caicedo"},
        { name: "Caldas"},
        { name: "Campamento"},
        { name: "Cañasgordas"},
        { name: "Caracolí"},
        { name: "Caramanta"},
        { name: "Carepa"},
        { name: "El Carmen de Viboral"},
        { name: "Carolina"},
        { name: "Caucasia"},
        { name: "Chigorodó"},
        { name: "Cisneros"},
        { name: "Cocorná"},
        { name: "Concepción"},
        { name: "Concordia"},
        { name: "Copacabana"},
        { name: "Dabeiba"},
        { name: "Don Matías"},
        { name: "Ebéjico"},
        { name: "El Bagre"},
        { name: "Entrerrios"},
        { name: "Envigado"},
        { name: "Fredonia"},
        { name: "Frontino"},
        { name: "Giraldo"},
        { name: "Girardota"},
        { name: "Gómez Plata"},
        { name: "Granada"},
        { name: "Guadalupe"},
        { name: "Guarne"},
        { name: "Guatapé"},
        { name: "Heliconia"},
        { name: "Hispania"},
        { name: "Itagui"},
        { name: "Ituango"},
        { name: "Jardín"},
        { name: "Jericó"},
        { name: "La Ceja"},
        { name: "La Estrella"},
        { name: "La Pintada"},
        { name: "La Unión"},
        { name: "Liborina"},
        { name: "Maceo"},
        { name: "Marinilla"},
        { name: "Montebello"},
        { name: "Murindó"},
        { name: "Mutatá"},
        { name: "Nariño"},
        { name: "Necoclí"},
        { name: "Nechí"},
        { name: "Olaya"},
        { name: "Peñol"},
        { name: "Peque"},
        { name: "Pueblorrico"},
        { name: "Puerto Berrío"},
        { name: "Puerto Nare"},
        { name: "Puerto Triunfo"},
        { name: "Remedios"},
        { name: "Retiro"},
        { name: "Rionegro"},
        { name: "Sabanalarga"},
        { name: "Sabaneta"},
        { name: "Salgar"},
        { name: "San Andrés de Cuerquía"},
        { name: "San Carlos"},
        { name: "San Francisco"},
        { name: "San Jerónimo"},
        { name: "San José de La Montaña"},
        { name: "San Juan de Urabá"},
        { name: "San Luis"},
        { name: "San Pedro"},
        { name: "San Pedro de Uraba"},
        { name: "San Rafael"},
        { name: "San Roque"},
        { name: "San Vicente"},
        { name: "Santa Bárbara"},
        { name: "Santa Rosa de Osos"},
        { name: "Santo Domingo"},
        { name: "El Santuario"},
        { name: "Segovia"},
        { name: "Sonson"},
        { name: "Sopetrán"},
        { name: "Támesis"},
        { name: "Tarazá"},
        { name: "Tarso"},
        { name: "Titiribí"},
        { name: "Toledo"},
        { name: "Turbo"},
        { name: "Uramita"},
        { name: "Urrao"},
        { name: "Valdivia"},
        { name: "Valparaíso"},
        { name: "Vegachí"},
        { name: "Venecia"},
        { name: "Vigía del Fuerte"},
        { name: "Yalí"},
        { name: "Yarumal"},
        { name: "Yolombó"},
        { name: "Yondó"},
        { name: "Zaragoza"},
    ]},
    { name: "Atlántico", cities: [
        { name: "Barranquilla"},
        { name: "Baranoa"},
        { name: "Campo de La Cruz"},
        { name: "Candelaria"},
        { name: "Galapa"},
        { name: "Juan de Acosta"},
        { name: "Luruaco"},
        { name: "Malambo"},
        { name: "Manatí"},
        { name: "Palmar de Varela"},
        { name: "Piojó"},
        { name: "Polonuevo"},
        { name: "Ponedera"},
        { name: "Puerto Colombia"},
        { name: "Repelón"},
        { name: "Sabanagrande"},
        { name: "Sabanalarga"},
        { name: "Santa Lucía"},
        { name: "Santo Tomás"},
        { name: "Soledad"},
        { name: "Suan"},
        { name: "Tubará"},
        { name: "Usiacurí"}
    ]},
    { name: "Bogotá D.C.", cities: [
        { name: "Bogotá, D.C."}
    ]},
    { name: "Bolívar", cities: [
        { name: "Cartagena"},
        { name: "Achí"},
        { name: "Altos del Rosario"},
        { name: "Arenal"},
        { name: "Arjona"},
        { name: "Arroyohondo"},
        { name: "Barranco de Loba"},
        { name: "Calamar"},
        { name: "Cantagallo"},
        { name: "Cicuco"},
        { name: "Córdoba"},
        { name: "Clemencia"},
        { name: "El Carmen de Bolívar"},
        { name: "El Guamo"},
        { name: "El Peñón"},
        { name: "Hatillo de Loba"},
        { name: "Magangué"},
        { name: "Mahates"},
        { name: "Margarita"},
        { name: "María La Baja"},
        { name: "Montecristo"},
        { name: "Mompós"},
        { name: "Morales"},
        { name: "Norosí"},
        { name: "Pinillos"},
        { name: "Regidor"},
        { name: "Río Viejo"},
        { name: "San Cristóbal"},
        { name: "San Estanislao"},
        { name: "San Fernando"},
        { name: "San Jacinto"},
        { name: "San Jacinto del Cauca"},
        { name: "San Juan Nepomuceno"},
        { name: "San Martín de Loba"},
        { name: "San Pablo"},
        { name: "Santa Catalina"},
        { name: "Santa Rosa"},
        { name: "Santa Rosa del Sur"},
        { name: "Simití"},
        { name: "Soplaviento"},
        { name: "Talaigua Nuevo"},
        { name: "Tiquisio"},
        { name: "Turbaco"},
        { name: "Turbaná"},
        { name: "Villanueva"},
        { name: "Zambrano"}
    ]},
    { name: "Boyacá", cities: [
        { name: "Tunja"},
        { name: "Almeida"},
        { name: "Aquitania"},
        { name: "Arcabuco"},
        { name: "Belén"},
        { name: "Berbeo"},
        { name: "Betéitiva"},
        { name: "Boavita"},
        { name: "Boyacá"},
        { name: "Briceño"},
        { name: "Buenavista"},
        { name: "Busbanzá"},
        { name: "Caldas"},
        { name: "Campohermoso"},
        { name: "Cerinza"},
        { name: "Chinavita"},
        { name: "Chiquinquirá"},
        { name: "Chiscas"},
        { name: "Chita"},
        { name: "Chitaraque"},
        { name: "Chivatá"},
        { name: "Ciénega"},
        { name: "Cómbita"},
        { name: "Coper"},
        { name: "Corrales"},
        { name: "Covarachía"},
        { name: "Cubará"},
        { name: "Cucaita"},
        { name: "Cuítiva"},
        { name: "Chíquiza"},
        { name: "Chivor"},
        { name: "Duitama"},
        { name: "El Cocuy"},
        { name: "El Espino"},
        { name: "Firavitoba"},
        { name: "Floresta"},
        { name: "Gachantivá"},
        { name: "Gameza"},
        { name: "Garagoa"},
        { name: "Guacamayas"},
        { name: "Guateque"},
        { name: "Guayatá"},
        { name: "Güicán"},
        { name: "Iza"},
        { name: "Jenesano"},
        { name: "Jericó"},
        { name: "Labranzagrande"},
        { name: "La Capilla"},
        { name: "La Victoria"},
        { name: "La Uvita"},
        { name: "Villa de Leyva"},
        { name: "Macanal"},
        { name: "Maripí"},
        { name: "Miraflores"},
        { name: "Mongua"},
        { name: "Monguí"},
        { name: "Moniquirá"},
        { name: "Motavita"},
        { name: "Muzo"},
        { name: "Nobsa"},
        { name: "Nuevo Colón"},
        { name: "Oicatá"},
        { name: "Otanche"},
        { name: "Pachavita"},
        { name: "Páez"},
        { name: "Paipa"},
        { name: "Pajarito"},
        { name: "Panqueba"},
        { name: "Pauna"},
        { name: "Paya"},
        { name: "Paz de Río"},
        { name: "Pesca"},
        { name: "Pisba"},
        { name: "Puerto Boyacá"},
        { name: "Quípama"},
        { name: "Ramiriquí"},
        { name: "Ráquira"},
        { name: "Rondón"},
        { name: "Saboyá"},
        { name: "Sáchica"},
        { name: "Samacá"},
        { name: "San Eduardo"},
        { name: "San José de Pare"},
        { name: "San Luis de Gaceno"},
        { name: "San Mateo"},
        { name: "San Miguel de Sema"},
        { name: "San Pablo de Borbur"},
        { name: "Santana"},
        { name: "Santa María"},
        { name: "Santa Rosa de Viterbo"},
        { name: "Santa Sofía"},
        { name: "Sativanorte"},
        { name: "Sativasur"},
        { name: "Siachoque"},
        { name: "Soatá"},
        { name: "Socotá"},
        { name: "Socha"},
        { name: "Sogamoso"},
        { name: "Somondoco"},
        { name: "Sora"},
        { name: "Sotaquirá"},
        { name: "Soracá"},
        { name: "Susacón"},
        { name: "Sutamarchán"},
        { name: "Sutatenza"},
        { name: "Tasco"},
        { name: "Tenza"},
        { name: "Tibaná"},
        { name: "Tibasosa"},
        { name: "Tinjacá"},
        { name: "Tipacoque"},
        { name: "Toca"},
        { name: "Togüí"},
        { name: "Tópaga"},
        { name: "Tota"},
        { name: "Tununguá"},
        { name: "Turmequé"},
        { name: "Tuta"},
        { name: "Tutazá"},
        { name: "Umbita"},
        { name: "Ventaquemada"},
        { name: "Viracachá"},
        { name: "Zetaquira"}
    ]},
    { name: "Caldas", cities: [
        { name: "Manizales"},
        { name: "Aguadas"},
        { name: "Anserma"},
        { name: "Aranzazu"},
        { name: "Belalcázar"},
        { name: "Chinchiná"},
        { name: "Filadelfia"},
        { name: "La Dorada"},
        { name: "La Merced"},
        { name: "Manzanares"},
        { name: "Marmato"},
        { name: "Marquetalia"},
        { name: "Marulanda"},
        { name: "Neira"},
        { name: "Norcasia"},
        { name: "Pácora"},
        { name: "Palestina"},
        { name: "Pensilvania"},
        { name: "Riosucio"},
        { name: "Risaralda"},
        { name: "Salamina"},
        { name: "Samaná"},
        { name: "San José"},
        { name: "Supía"},
        { name: "Victoria"},
        { name: "Villamaría"},
        { name: "Viterbo"}
    ]},
    { name: "Caquetá", cities: [
        { name: "Florencia"},
        { name: "Albania"},
        { name: "Belén de Los Andaquies"},
        { name: "Cartagena del Chairá"},
        { name: "Curillo"},
        { name: "El Doncello"},
        { name: "El Paujil"},
        { name: "La Montañita"},
        { name: "Milán"},
        { name: "Morelia"},
        { name: "Puerto Rico"},
        { name: "San José del Fragua"},
        { name: "San Vicente del Caguán"},
        { name: "Solano"},
        { name: "Solita"},
        { name: "Valparaíso"}
    ]},
    { name: "Cauca", cities: [
        { name: "Popayán"},
        { name: "Almaguer"},
        { name: "Argelia"},
        { name: "Balboa"},
        { name: "Bolívar"},
        { name: "Buenos Aires"},
        { name: "Cajibío"},
        { name: "Caldono"},
        { name: "Caloto"},
        { name: "Corinto"},
        { name: "El Tambo"},
        { name: "Florencia"},
        { name: "Guachené"},
        { name: "Guapi"},
        { name: "Inzá"},
        { name: "Jambaló"},
        { name: "La Sierra"},
        { name: "La Vega"},
        { name: "López"},
        { name: "Mercaderes"},
        { name: "Miranda"},
        { name: "Morales"},
        { name: "Padilla"},
        { name: "Paez"},
        { name: "Patía"},
        { name: "Piamonte"},
        { name: "Piendamó"},
        { name: "Puerto Tejada"},
        { name: "Puracé"},
        { name: "Rosas"},
        { name: "San Sebastián"},
        { name: "Santander de Quilichao"},
        { name: "Santa Rosa"},
        { name: "Silvia"},
        { name: "Sotara"},
        { name: "Suárez"},
        { name: "Sucre"},
        { name: "Timbío"},
        { name: "Timbiquí"},
        { name: "Toribio"},
        { name: "Totoró"},
        { name: "Villa Rica"}
    ]},
    { name: "Cesar", cities: [
        { name: "Valledupar"},
        { name: "Aguachica"},
        { name: "Agustín Codazzi"},
        { name: "Astrea"},
        { name: "Becerril"},
        { name: "Bosconia"},
        { name: "Chimichagua"},
        { name: "Chiriguaná"},
        { name: "Curumaní"},
        { name: "El Copey"},
        { name: "El Paso"},
        { name: "Gamarra"},
        { name: "González"},
        { name: "La Gloria"},
        { name: "La Jagua de Ibirico"},
        { name: "Manaure"},
        { name: "Pailitas"},
        { name: "Pelaya"},
        { name: "Pueblo Bello"},
        { name: "Río de Oro"},
        { name: "La Paz"},
        { name: "San Alberto"},
        { name: "San Diego"},
        { name: "San Martín"},
        { name: "Tamalameque"}
    ]},
    { name: "Córdoba", cities: [
        { name: "Montería"},
        { name: "Ayapel"},
        { name: "Buenavista"},
        { name: "Canalete"},
        { name: "Cereté"},
        { name: "Chimá"},
        { name: "Chinú"},
        { name: "Ciénaga de Oro"},
        { name: "Cotorra"},
        { name: "La Apartada"},
        { name: "Lorica"},
        { name: "Los Córdobas"},
        { name: "Momil"},
        { name: "Montelíbano"},
        { name: "Moñitos"},
        { name: "Planeta Rica"},
        { name: "Pueblo Nuevo"},
        { name: "Puerto Escondido"},
        { name: "Puerto Libertador"},
        { name: "Purísima"},
        { name: "Sahagún"},
        { name: "San Andrés Sotavento"},
        { name: "San Antero"},
        { name: "San Bernardo del Viento"},
        { name: "San Carlos"},
        { name: "San José de Uré"},
        { name: "San Pelayo"},
        { name: "Tierralta"},
        { name: "Tuchín"},
        { name: "Valencia"}
    ]},
    { name: "Cundinamarca", cities: [
        { name: "Agua de Dios"},
        { name: "Albán"},
        { name: "Anapoima"},
        { name: "Anolaima"},
        { name: "Arbeláez"},
        { name: "Beltrán"},
        { name: "Bituima"},
        { name: "Bojacá"},
        { name: "Cabrera"},
        { name: "Cachipay"},
        { name: "Cajicá"},
        { name: "Caparrapí"},
        { name: "Caqueza"},
        { name: "Carmen de Carupa"},
        { name: "Chaguaní"},
        { name: "Chía"},
        { name: "Chipaque"},
        { name: "Choachí"},
        { name: "Chocontá"},
        { name: "Cogua"},
        { name: "Cota"},
        { name: "Cucunubá"},
        { name: "El Colegio"},
        { name: "El Peñón"},
        { name: "El Rosal"},
        { name: "Facatativá"},
        { name: "Fomeque"},
        { name: "Fosca"},
        { name: "Funza"},
        { name: "Fúquene"},
        { name: "Fusagasugá"},
        { name: "Gachala"},
        { name: "Gachancipá"},
        { name: "Gachetá"},
        { name: "Gama"},
        { name: "Girardot"},
        { name: "Granada"},
        { name: "Guachetá"},
        { name: "Guaduas"},
        { name: "Guasca"},
        { name: "Guataquí"},
        { name: "Guatavita"},
        { name: "Guayabal de Siquima"},
        { name: "Guayabetal"},
        { name: "Gutiérrez"},
        { name: "Jerusalén"},
        { name: "Junín"},
        { name: "La Calera"},
        { name: "La Mesa"},
        { name: "La Palma"},
        { name: "La Peña"},
        { name: "La Vega"},
        { name: "Lenguazaque"},
        { name: "Macheta"},
        { name: "Madrid"},
        { name: "Manta"},
        { name: "Medina"},
        { name: "Mosquera"},
        { name: "Nariño"},
        { name: "Nemocón"},
        { name: "Nilo"},
        { name: "Nimaima"},
        { name: "Nocaima"},
        { name: "Venecia"},
        { name: "Pacho"},
        { name: "Paime"},
        { name: "Pandi"},
        { name: "Paratebueno"},
        { name: "Pasca"},
        { name: "Puerto Salgar"},
        { name: "Pulí"},
        { name: "Quebradanegra"},
        { name: "Quetame"},
        { name: "Quipile"},
        { name: "Apulo"},
        { name: "Ricaurte"},
        { name: "San Antonio del Tequendama"},
        { name: "San Bernardo"},
        { name: "San Cayetano"},
        { name: "San Francisco"},
        { name: "San Juan de Río Seco"},
        { name: "Sasaima"},
        { name: "Sesquilé"},
        { name: "Sibaté"},
        { name: "Silvania"},
        { name: "Simijaca"},
        { name: "Soacha"},
        { name: "Sopó"},
        { name: "Subachoque"},
        { name: "Suesca"},
        { name: "Supatá"},
        { name: "Susa"},
        { name: "Sutatausa"},
        { name: "Tabio"},
        { name: "Tausa"},
        { name: "Tena"},
        { name: "Tenjo"},
        { name: "Tibacuy"},
        { name: "Tibirita"},
        { name: "Tocaima"},
        { name: "Tocancipá"},
        { name: "Topaipí"},
        { name: "Ubalá"},
        { name: "Ubaque"},
        { name: "Villa de San Diego de Ubate"},
        { name: "Une"},
        { name: "Útica"},
        { name: "Vergara"},
        { name: "Vianí"},
        { name: "Villagómez"},
        { name: "Villapinzón"},
        { name: "Villeta"},
        { name: "Viotá"},
        { name: "Yacopí"},
        { name: "Zipacón"},
        { name: "Zipaquirá"}
    ]},
    { name: "Chocó", cities: [
        { name: "Quibdó"},
        { name: "Acandí"},
        { name: "Alto Baudo"},
        { name: "Atrato"},
        { name: "Bagadó"},
        { name: "Bahía Solano"},
        { name: "Bajo Baudó"},
        { name: "Bojaya"},
        { name: "El Cantón del San Pablo"},
        { name: "Carmen del Darien"},
        { name: "Cértegui"},
        { name: "Condoto"},
        { name: "El Carmen de Atrato"},
        { name: "El Litoral del San Juan"},
        { name: "Istmina"},
        { name: "Juradó"},
        { name: "Lloró"},
        { name: "Medio Atrato"},
        { name: "Medio Baudó"},
        { name: "Medio San Juan"},
        { name: "Nóvita"},
        { name: "Nuquí"},
        { name: "Río Iro"},
        { name: "Río Quito"},
        { name: "Riosucio"},
        { name: "San José del Palmar"},
        { name: "Sipí"},
        { name: "Tadó"},
        { name: "Unguía"},
        { name: "Unión Panamericana"}
    ]},
    { name: "Huila", cities: [
        { name: "Neiva"},
        { name: "Acevedo"},
        { name: "Agrado"},
        { name: "Aipe"},
        { name: "Algeciras"},
        { name: "Altamira"},
        { name: "Baraya"},
        { name: "Campoalegre"},
        { name: "Colombia"},
        { name: "Elías"},
        { name: "Garzón"},
        { name: "Gigante"},
        { name: "Guadalupe"},
        { name: "Hobo"},
        { name: "Iquira"},
        { name: "Isnos"},
        { name: "La Argentina"},
        { name: "La Plata"},
        { name: "Nátaga"},
        { name: "Oporapa"},
        { name: "Paicol"},
        { name: "Palermo"},
        { name: "Palestina"},
        { name: "Pital"},
        { name: "Pitalito"},
        { name: "Rivera"},
        { name: "Saladoblanco"},
        { name: "San Agustín"},
        { name: "Santa María"},
        { name: "Suaza"},
        { name: "Tarqui"},
        { name: "Tesalia"},
        { name: "Tello"},
        { name: "Teruel"},
        { name: "Timaná"},
        { name: "Villavieja"},
        { name: "Yaguará"}
    ]},
    { name: "La Guajira", cities: [
        { name: "Riohacha"},
        { name: "Albania"},
        { name: "Barrancas"},
        { name: "Dibulla"},
        { name: "Distracción"},
        { name: "El Molino"},
        { name: "Fonseca"},
        { name: "Hatonuevo"},
        { name: "La Jagua del Pilar"},
        { name: "Maicao"},
        { name: "Manaure"},
        { name: "San Juan del Cesar"},
        { name: "Uribia"},
        { name: "Urumita"},
        { name: "Villanueva"}
    ]},
    { name: "Magdalena", cities: [
        { name: "Santa Marta"},
        { name: "Algarrobo"},
        { name: "Aracataca"},
        { name: "Ariguaní"},
        { name: "Cerro San Antonio"},
        { name: "Chivolo"},
        { name: "Ciénaga"},
        { name: "Concordia"},
        { name: "El Banco"},
        { name: "El Piñon"},
        { name: "El Retén"},
        { name: "Fundación"},
        { name: "Guamal"},
        { name: "Nueva Granada"},
        { name: "Pedraza"},
        { name: "Pijiño del Carmen"},
        { name: "Pivijay"},
        { name: "Plato"},
        { name: "Puebloviejo"},
        { name: "Remolino"},
        { name: "Sabanas de San Angel"},
        { name: "Salamina"},
        { name: "San Sebastián de Buenavista"},
        { name: "San Zenón"},
        { name: "Santa Ana"},
        { name: "Santa Bárbara de Pinto"},
        { name: "Sitionuevo"},
        { name: "Tenerife"},
        { name: "Zapayán"},
        { name: "Zona Bananera"}
    ]},
    { name: "Meta", cities: [
        { name: "Villavicencio"},
        { name: "Acacías"},
        { name: "Barranca de Upía"},
        { name: "Cabuyaro"},
        { name: "Castilla la Nueva"},
        { name: "Cubarral"},
        { name: "Cumaral"},
        { name: "El Calvario"},
        { name: "El Castillo"},
        { name: "El Dorado"},
        { name: "Fuente de Oro"},
        { name: "Granada"},
        { name: "Guamal"},
        { name: "Mapiripán"},
        { name: "Mesetas"},
        { name: "La Macarena"},
        { name: "Uribe"},
        { name: "Lejanías"},
        { name: "Puerto Concordia"},
        { name: "Puerto Gaitán"},
        { name: "Puerto López"},
        { name: "Puerto Lleras"},
        { name: "Puerto Rico"},
        { name: "Restrepo"},
        { name: "San Carlos de Guaroa"},
        { name: "San Juan de Arama"},
        { name: "San Juanito"},
        { name: "San Martín"},
        { name: "Vistahermosa"}
    ]},
    { name: "Nariño", cities: [
        { name: "Pasto"},
        { name: "Albán"},
        { name: "Aldana"},
        { name: "Ancuyá"},
        { name: "Arboleda"},
        { name: "Barbacoas"},
        { name: "Belén"},
        { name: "Buesaco"},
        { name: "Colón"},
        { name: "Consaca"},
        { name: "Contadero"},
        { name: "Córdoba"},
        { name: "Cuaspud"},
        { name: "Cumbal"},
        { name: "Cumbitara"},
        { name: "Chachagüí"},
        { name: "El Charco"},
        { name: "El Peñol"},
        { name: "El Rosario"},
        { name: "El Tablón de Gómez"},
        { name: "El Tambo"},
        { name: "Funes"},
        { name: "Guachucal"},
        { name: "Guaitarilla"},
        { name: "Gualmatán"},
        { name: "Iles"},
        { name: "Imués"},
        { name: "Ipiales"},
        { name: "La Cruz"},
        { name: "La Florida"},
        { name: "La Llanada"},
        { name: "La Tola"},
        { name: "La Unión"},
        { name: "Leiva"},
        { name: "Linares"},
        { name: "Los Andes"},
        { name: "Magüi"},
        { name: "Mallama"},
        { name: "Mosquera"},
        { name: "Nariño"},
        { name: "Olaya Herrera"},
        { name: "Ospina"},
        { name: "Francisco Pizarro"},
        { name: "Policarpa"},
        { name: "Potosí"},
        { name: "Providencia"},
        { name: "Puerres"},
        { name: "Pupiales"},
        { name: "Ricaurte"},
        { name: "Roberto Payán"},
        { name: "Samaniego"},
        { name: "Sandoná"},
        { name: "San Bernardo"},
        { name: "San Lorenzo"},
        { name: "San Pablo"},
        { name: "San Pedro de Cartago"},
        { name: "Santa Bárbara"},
        { name: "Santacruz"},
        { name: "Sapuyes"},
        { name: "Taminango"},
        { name: "Tangua"},
        { name: "San Andres de Tumaco"},
        { name: "Túquerres"},
        { name: "Yacuanquer"}
    ]},
    { name: "Norte de Santander", cities: [
        { name: "Cúcuta"},
        { name: "Abrego"},
        { name: "Arboledas"},
        { name: "Bochalema"},
        { name: "Bucarasica"},
        { name: "Cácota"},
        { name: "Cachirá"},
        { name: "Chinácota"},
        { name: "Chitagá"},
        { name: "Convención"},
        { name: "Cucutilla"},
        { name: "Durania"},
        { name: "El Carmen"},
        { name: "El Tarra"},
        { name: "El Zulia"},
        { name: "Gramalote"},
        { name: "Hacarí"},
        { name: "Herrán"},
        { name: "Labateca"},
        { name: "La Esperanza"},
        { name: "La Playa"},
        { name: "Los Patios"},
        { name: "Lourdes"},
        { name: "Mutiscua"},
        { name: "Ocaña"},
        { name: "Pamplona"},
        { name: "Pamplonita"},
        { name: "Puerto Santander"},
        { name: "Ragonvalia"},
        { name: "Salazar"},
        { name: "San Calixto"},
        { name: "San Cayetano"},
        { name: "Santiago"},
        { name: "Sardinata"},
        { name: "Silos"},
        { name: "Teorama"},
        { name: "Tibú"},
        { name: "Toledo"},
        { name: "Villa Caro"},
        { name: "Villa del Rosario"}
    ]},
    { name: "Quindio", cities: [
        { name: "Armenia"},
        { name: "Buenavista"},
        { name: "Calarca"},
        { name: "Circasia"},
        { name: "Córdoba"},
        { name: "Filandia"},
        { name: "Génova"},
        { name: "La Tebaida"},
        { name: "Montenegro"},
        { name: "Pijao"},
        { name: "Quimbaya"},
        { name: "Salento"}
    ]},
    { name: "Risaralda", cities: [
        { name: "Pereira"},
        { name: "Apía"},
        { name: "Balboa"},
        { name: "Belén de Umbría"},
        { name: "Dosquebradas"},
        { name: "Guática"},
        { name: "La Celia"},
        { name: "La Virginia"},
        { name: "Marsella"},
        { name: "Mistrató"},
        { name: "Pueblo Rico"},
        { name: "Quinchía"},
        { name: "Santa Rosa de Cabal"},
        { name: "Santuario"}
    ]},
    { name: "Santander", cities: [
        { name: "Bucaramanga"},
        { name: "Aguada"},
        { name: "Albania"},
        { name: "Aratoca"},
        { name: "Barbosa"},
        { name: "Barichara"},
        { name: "Barrancabermeja"},
        { name: "Betulia"},
        { name: "Bolívar"},
        { name: "Cabrera"},
        { name: "California"},
        { name: "Capitanejo"},
        { name: "Carcasí"},
        { name: "Cepitá"},
        { name: "Cerrito"},
        { name: "Charalá"},
        { name: "Charta"},
        { name: "Chima"},
        { name: "Chipatá"},
        { name: "Cimitarra"},
        { name: "Concepción"},
        { name: "Confines"},
        { name: "Contratación"},
        { name: "Coromoro"},
        { name: "Curití"},
        { name: "El Carmen de Chucurí"},
        { name: "El Guacamayo"},
        { name: "El Peñón"},
        { name: "El Playón"},
        { name: "Encino"},
        { name: "Enciso"},
        { name: "Florián"},
        { name: "Floridablanca"},
        { name: "Galán"},
        { name: "Gambita"},
        { name: "Girón"},
        { name: "Guaca"},
        { name: "Guadalupe"},
        { name: "Guapotá"},
        { name: "Guavatá"},
        { name: "Güepsa"},
        { name: "Hato"},
        { name: "Jesús María"},
        { name: "Jordán"},
        { name: "La Belleza"},
        { name: "Landázuri"},
        { name: "La Paz"},
        { name: "Lebríja"},
        { name: "Los Santos"},
        { name: "Macaravita"},
        { name: "Málaga"},
        { name: "Matanza"},
        { name: "Mogotes"},
        { name: "Molagavita"},
        { name: "Ocamonte"},
        { name: "Oiba"},
        { name: "Onzaga"},
        { name: "Palmar"},
        { name: "Palmas del Socorro"},
        { name: "Páramo"},
        { name: "Piedecuesta"},
        { name: "Pinchote"},
        { name: "Puente Nacional"},
        { name: "Puerto Parra"},
        { name: "Puerto Wilches"},
        { name: "Rionegro"},
        { name: "Sabana de Torres"},
        { name: "San Andrés"},
        { name: "San Benito"},
        { name: "San Gil"},
        { name: "San Joaquín"},
        { name: "San José de Miranda"},
        { name: "San Miguel"},
        { name: "San Vicente de Chucurí"},
        { name: "Santa Bárbara"},
        { name: "Santa Helena del Opón"},
        { name: "Simacota"},
        { name: "Socorro"},
        { name: "Suaita"},
        { name: "Sucre"},
        { name: "Suratá"},
        { name: "Tona"},
        { name: "Valle de San José"},
        { name: "Vélez"},
        { name: "Vetas"},
        { name: "Villanueva"},
        { name: "Zapatoca"}
    ]},
    { name: "Sucre", cities: [
        { name: "Sincelejo"},
        { name: "Buenavista"},
        { name: "Caimito"},
        { name: "Coloso"},
        { name: "Corozal"},
        { name: "Coveñas"},
        { name: "Chalán"},
        { name: "El Roble"},
        { name: "Galeras"},
        { name: "Guaranda"},
        { name: "La Unión"},
        { name: "Los Palmitos"},
        { name: "Majagual"},
        { name: "Morroa"},
        { name: "Ovejas"},
        { name: "Palmito"},
        { name: "Sampués"},
        { name: "San Benito Abad"},
        { name: "San Juan de Betulia"},
        { name: "San Marcos"},
        { name: "San Onofre"},
        { name: "San Pedro"},
        { name: "San Luis de Sincé"},
        { name: "Sucre"},
        { name: "Santiago de Tolú"},
        { name: "Tolú Viejo"}
    ]},
    { name: "Tolima", cities: [
        { name: "Ibagué"},
        { name: "Alpujarra"},
        { name: "Alvarado"},
        { name: "Ambalema"},
        { name: "Anzoátegui"},
        { name: "Armero"},
        { name: "Ataco"},
        { name: "Cajamarca"},
        { name: "Carmen de Apicalá"},
        { name: "Casabianca"},
        { name: "Chaparral"},
        { name: "Coello"},
        { name: "Coyaima"},
        { name: "Cunday"},
        { name: "Dolores"},
        { name: "Espinal"},
        { name: "Falan"},
        { name: "Flandes"},
        { name: "Fresno"},
        { name: "Guamo"},
        { name: "Herveo"},
        { name: "Honda"},
        { name: "Icononzo"},
        { name: "Lérida"},
        { name: "Líbano"},
        { name: "Mariquita"},
        { name: "Melgar"},
        { name: "Murillo"},
        { name: "Natagaima"},
        { name: "Ortega"},
        { name: "Palocabildo"},
        { name: "Piedras"},
        { name: "Planadas"},
        { name: "Prado"},
        { name: "Purificación"},
        { name: "Rioblanco"},
        { name: "Roncesvalles"},
        { name: "Rovira"},
        { name: "Saldaña"},
        { name: "San Antonio"},
        { name: "San Luis"},
        { name: "Santa Isabel"},
        { name: "Suárez"},
        { name: "Valle de San Juan"},
        { name: "Venadillo"},
        { name: "Villahermosa"},
        { name: "Villarrica"}
    ]},
    { name: "Arauca", cities: [
        { name: "Arauca"},
        { name: "Arauquita"},
        { name: "Cravo Norte"},
        { name: "Fortul"},
        { name: "Puerto Rondón"},
        { name: "Saravena"},
        { name: "Tame"}
    ]},
    { name: "Casanare", cities: [
        { name: "Yopal"},
        { name: "Aguazul"},
        { name: "Chameza"},
        { name: "Hato Corozal"},
        { name: "La Salina"},
        { name: "Maní"},
        { name: "Monterrey"},
        { name: "Nunchía"},
        { name: "Orocué"},
        { name: "Paz de Ariporo"},
        { name: "Pore"},
        { name: "Recetor"},
        { name: "Sabanalarga"},
        { name: "Sácama"},
        { name: "San Luis de Palenque"},
        { name: "Támara"},
        { name: "Tauramena"},
        { name: "Trinidad"},
        { name: "Villanueva"}
    ]},
    { name: "Putumayo", cities: [
        { name: "Mocoa"},
        { name: "Colón"},
        { name: "Orito"},
        { name: "Puerto Asís"},
        { name: "Puerto Caicedo"},
        { name: "Puerto Guzmán"},
        { name: "Leguízamo"},
        { name: "Sibundoy"},
        { name: "San Francisco"},
        { name: "San Miguel"},
        { name: "Santiago"},
        { name: "Valle del Guamuez"},
        { name: "Villagarzón"}
    ]},
    { name: "Archipiélago de San Andrés", cities: [
        { name: "San Andrés"},
        { name: "Providencia"}
    ]},
    { name: "Amazonas", cities: [
        { name: "Leticia"},
        { name: "El Encanto"},
        { name: "La Chorrera"},
        { name: "La Pedrera"},
        { name: "La Victoria"},
        { name: "Miriti - Paraná"},
        { name: "Puerto Alegría"},
        { name: "Puerto Arica"},
        { name: "Puerto Nariño"},
        { name: "Puerto Santander"},
        { name: "Tarapacá"}
    ]},
    { name: "Guainía", cities: [
        { name: "Inírida"},
        { name: "Barranco Minas"},
        { name: "Mapiripana"},
        { name: "San Felipe"},
        { name: "Puerto Colombia"},
        { name: "La Guadalupe"},
        { name: "Cacahual"},
        { name: "Pana Pana"},
        { name: "Morichal"}
    ]},
    { name: "Guaviare", cities: [
        { name: "San José del Guaviare"},
        { name: "Calamar"},
        { name: "El Retorno"},
        { name: "Miraflores"}
    ]},
    { name: "Vaupés", cities: [
        { name: "Mitú"},
        { name: "Caruru"},
        { name: "Pacoa"},
        { name: "Taraira"},
        { name: "Papunaua"},
        { name: "Yavaraté"}
    ]},
    { name: "Vichada", cities: [
        { name: "Puerto Carreño"},
        { name: "La Primavera"},
        { name: "Santa Rosalía"},
        { name: "Cumaribo"}
    ]}
];

export const itemsPerPage = 10;