import { isNumeric } from "../actions/global-actions";
import { itemsPerPage } from "../actions/global-actions-data";

export const setSkipValue = (searchParams) => {
    let page = searchParams.get('page') || 0;
    if (!isNumeric(page)) return 0;
    if (parseInt(page) < 1) return 0;
    page = (parseInt(page)-1) * itemsPerPage;
    return page;
}
