import { Link } from 'react-router-dom';
import { formatDate, queryIsLoading, queryNotLoading, toCurrency } from '../../actions/global-actions';
import { createPayment } from '../../actions/request-actions';
import alert from '../alert';

const PaymentsListComponent = ({contract, user, dispatch, setLoadData}) => {
    const payMonth = (event, date) => {
        event.preventDefault();
        if (window.confirm(`¿Desea realizar el pago del mes: ${formatDate(date, 6)}, inquilino: ${contract.person}, valor: ${toCurrency(contract.contract.monthlyCost)}`)) {
            //console.log('contract', contract);
            const payment = {
                monthPaid: date,
                valuePaid: contract.contract.monthlyCost,
                creationUserID: user._id,
            }
            //console.log('payment',payment);
            
            let data = JSON.parse(JSON.stringify(contract.property));
            let requests = data.requests;
            //console.log('data', data);
    
            let request = JSON.parse(JSON.stringify(contract.request));
            //console.log('request', request);
    
            for (let index = 0; index < request.contracts.length; index++) {
                if (request.contracts[index]._id === contract.contract._id) {
                    request.contracts[index].payments.push(payment);
                }
            }
    
            //console.log('sis',request.contracts);
            
            for (let index = 0; index < requests.length; index++) {
                if (requests[index]._id === request._id) {
                    requests[index] = request;
                }
            }
            data.requests = requests;
    
            //console.log('data', data);

            dispatch( createPayment( data, createPaymentFunc, user.token ) );
        }
    }

    const createPaymentFunc = (data) => {
        if(data.code === "01"){
            alert('Error', data.message);
            return;
        }
        alert('¡Éxito!', 'Pago registrado con éxito');
        setLoadData(true);
    }
    
    const payMonths = () => {
        if (window.confirm(`¿Realmente desea realizar todos los pagos al inquilino: ${contract.person} por valor: ${toCurrency(contract.contract.monthlyCost)} hasta el mes actual?`)) {
            let startMonth = new Date(contract.contract.startDate).getMonth();
            let currentDate = new Date(contract.contract.startDate);
            const endDate = new Date();
            dispatch(queryIsLoading());
            while (endDate.getTime() > currentDate.getTime()) {
                // Se verifica si existe un pago para el mes actual
                const existPayment = contract.contract.payments.find(payment => formatDate(new Date(payment.monthPaid), 6) === formatDate(new Date(currentDate), 6));
                if (!existPayment) {
                    const payment = {
                        monthPaid: currentDate,
                        valuePaid: contract.contract.monthlyCost,
                        creationUserID: user._id,
                        observation: 'Creado desde la opción de Poner al día del módulo Estado de pagos'
                    }
                    contract.contract.payments.push(payment);
                }
                startMonth++;
                currentDate = new Date(new Date(contract.contract.startDate).setMonth(startMonth));
            }

            let data = JSON.parse(JSON.stringify(contract.property));
            let requests = data.requests;    
            let request = JSON.parse(JSON.stringify(contract.request));
            
            for (let index = 0; index < request.contracts.length; index++) {
                if (request.contracts[index]._id === contract.contract._id) {
                    request.contracts[index].payments = contract.contract.payments;
                    break;
                }
            }

            for (let index = 0; index < requests.length; index++) {
                if (requests[index]._id === request._id) {
                    requests[index] = request;
                    break;
                }
            }

            data.requests = requests;
            //console.log('data', data);
            dispatch(queryNotLoading());
            dispatch( createPayment( data, createPaymentFunc, user.token ) );
        }
    }

    return (
        <div className='d-flex field w-100 justify-content-center align-items-center flex-column'>
            <table className='table mt-4' style={{width: '80%'}}>
                <thead>
                    <tr>
                        <th>Mes</th>
                        <th>Valor pagado</th>
                        <th>Fecha de pago</th>
                        <th>Estado</th>
                        <th>Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    {contract.payments.map((payment, paymentIdx) => (
                        <tr key={paymentIdx}>
                            {payment.value!==0?
                                <>
                                    <td><Link to={`/inmuebles/${contract.property._id}/pagos/${payment._id}/editar`}>{payment.date}</Link></td>
                                    <td><Link to={`/inmuebles/${contract.property._id}/pagos/${payment._id}/editar`}>{payment.value!==0?<span>{toCurrency(payment.value)}</span>:null}</Link></td>
                                    <td><Link to={`/inmuebles/${contract.property._id}/pagos/${payment._id}/editar`}>{payment.value!==0?<span>{formatDate( new Date(payment.createdAt), 1)}</span>:null}</Link></td>
                                    <td><Link to={`/inmuebles/${contract.property._id}/pagos/${payment._id}/editar`}>{payment.status}</Link></td>
                                </>
                            :
                                <>
                                    <td>{payment.date}</td>
                                    <td>{payment.value!==0?<span>{toCurrency(payment.value)}</span>:null}</td>
                                    <td>{payment.value!==0?<span>{formatDate( new Date(payment.createdAt), 1)}</span>:null}</td>
                                    <td>{payment.status}</td>
                                </>
                            }
                            <td>
                                {payment.value===0?
                                    <div className='d-flex flex-column'>
                                        <span className='end-button go' style={{margin: 0}} onClick={(e)=>payMonth(e, payment.unformatedDate)}>Registrar</span>
                                        <Link className='end-button go' style={{margin: 0}} to={`/inmuebles/${contract.property._id}/nuevo-pago?date=${payment.unformatedDate.getTime()}`}>Pago manual</Link>
                                        {/*<span className='end-button go' style={{margin: 0}}>Recibo de pago</span>*/}
                                    </div>
                                :
                                    <div className='d-flex flex-column'>
                                        {/*<span className='end-button go' style={{margin: 0}}>Comprobante de pago</span>*/}
                                    </div>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Link to={`/inmuebles/${contract.property._id}/pagos`} className="end-button go">Ver lista de pagos</Link>
            { contract.textStatus !== 'Al día' && <button className="end-button go" onClick={payMonths}>Poner al día</button> }
        </div>
    )
}

export default PaymentsListComponent