import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { Link, useParams } from 'react-router-dom';
import { inventoryItems, itemStates } from './create-property-data';
import { getProperty, updateProperty } from '../../actions/property-actions';

function Inventory(){
    const currentLink = "/inmuebles";
    const dispatch = useDispatch();
    //const navigate = useNavigate();
    const user = useSelector(state => state.user.user);
    const [loadData, setLoadData] = useState(true);
    const params = useParams();

    const [form, setForm] = useState({
        _id: '',
        implements: '',
        observations: '',
        services: '',
        inventory: []
    });

    const [currentData, setCurrentData] = useState({});

    useEffect(() => {
        if (loadData) {
            const getUserFunction = (data) => {
                if(data.code === "01"){
                    Swal.fire({
                        title:"Error",
                        confirmButtonColor:"#76b4ff",
                        text: data.message
                    });
                }else{
                    //console.log(data.data);
                    setCurrentData(data.data[0]);

                    let newInventory = [];

                    if (data.data[0].inventory.length > 0) {
                        newInventory = data.data[0].inventory
                    } else {
                        inventoryItems.map( item => {
                            let obj = {
                                name: item,
                                state: 'Bueno',
                                quantity: 1,
                                brand: '',
                                material: '',
                                observations: '',
                            }
                            newInventory.push(obj)
    
                            return item
                        });
                    }

                    setForm({
                        ...form,
                        _id: data.data[0]._id,
                        services: data.data[0].services,
                        implements: data.data[0].implements,
                        observations: data.data[0].observations,
                        inventory: newInventory
                    })

                }
            }
            setLoadData(false);

            setForm({
                _id: '',
                implements: '',
                observations: '',
                services: '',
                inventory: []
            })

            dispatch( getProperty( params.id, getUserFunction, user.token ) );
        }
    }, [loadData, dispatch, form, params.id, user.token ]);

    const handleInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name] :  event.target.value
        })
    }

    const handleInventoryChange = (event, index) => {
        form.inventory[index][event.target.name] = event.target.value;
        setForm({
            ...form
        })
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        dispatch( updateProperty( form, updateFunction, user.token ) );
    }

    const updateFunction = (data) => {
        if(data.code === "01"){
            Swal.fire({
                title:"Error",
                confirmButtonColor:"#76b4ff",
                text: data.message
            });
        }else{
            //navigate(currentLink)
            console.log(data);
            //setLoadData(true);
            Swal.fire({
                title:"¡Éxito!",
                confirmButtonColor:"#76b4ff",
                text: "Inmueble actualizado con éxito"
            });
        }
    }

    useEffect(() => {
        //console.log(form)
    }, [form]);

    return(
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <div className="w-100 mt-5">
                    <Link to={`${currentLink}/${params.id}/editar`} className="back-button">&#60; Volver - Editar inmueble</Link>
                </div>
                {Object.keys(currentData).length > 0 ?
                    <h1 className="my-5">Inventario del inmueble {currentData.code} </h1>
                : null }
                <form onSubmit={handleOnSubmit}>
                    <div className="d-flex w-100 flex-wrap">
                        <div className="row w-100">
                            <div className="col-2">
                                <span className="label-field">Item</span>
                            </div>
                            <div className="col state-container">
                                <span className="label-field">Estado</span>
                            </div>
                            <div className="col quantity-container">
                                <span className="label-field">Cantidad</span>
                            </div>
                            <div className="col item-info-container">
                                <span className="label-field">Material</span>
                            </div>
                            <div className="col item-info-container">
                                <span className="label-field">Marca</span>
                            </div>
                            <div className="col">
                                <span className="label-field">Observaciones</span>
                            </div>
                        </div>
                        {form.inventory.map((item, index) => (
                            <div key={`${item}${index}`} className="row w-100">
                                <div className="col-2">
                                    <span>{item.name}</span>
                                </div>
                                <div className="col state-container">
                                    <select className="input-field w-100" id={`state${index}`} name={`state`} value={form.inventory[index].state} onChange={(e) => handleInventoryChange(e, index)}>
                                        <option></option>
                                        {itemStates.map((state, index) => (
                                            <option key={`${state}${index}`} value={state}>{state}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col quantity-container">
                                    <input id={`quantity${index}`} name={`quantity`} value={form.inventory[index].quantity} autoComplete="off" className="input-field" type="text" onChange={(e) => handleInventoryChange(e, index)}></input>
                                </div>
                                <div className="col item-info-container">
                                    <input id={`material${index}`} name={`material`} value={form.inventory[index].material} placeholder='Metal, madera..' autoComplete="off" className="input-field" type="text" onChange={(e) => handleInventoryChange(e, index)}></input>
                                </div>
                                <div className="col item-info-container">
                                    <input id={`brand${index}`} name={`brand`} value={form.inventory[index].brand} placeholder='Corona..' autoComplete="off" className="input-field" type="text" onChange={(e) => handleInventoryChange(e, index)}></input>
                                </div>
                                <div className="col">
                                    <input id={`observations${index}`} name={`observations`} value={form.inventory[index].observations} className="input-field" type="text" onChange={(e) => handleInventoryChange(e, index)}></input>
                                </div>
                            </div>
                        ))}
                        <div className="d-flex flex-column w-100 field">
                            <label className="label-field" htmlFor='implements'>Pisos, paredes, implementos</label>
                            <textarea id='implements' name='implements' placeholder='' autoComplete="off" rows={5} cols={5} className="input-field" type="text" onChange={handleInputChange} value={form.implements}></textarea>
                        </div>
                        <div className="d-flex flex-column w-100 field">
                            <label className="label-field" htmlFor='services'>Servicios, cosas y usos adicionales</label>
                            <textarea id='services' name='services' placeholder='' autoComplete="off" rows={5} cols={5} className="input-field" type="text" onChange={handleInputChange} value={form.services}></textarea>
                        </div>
                        <div className="d-flex flex-column w-100 field">
                            <label className="label-field" htmlFor='observations'>Observaciones</label>
                            <textarea id='observations' name='observations' placeholder='Todo se encuentra OK' autoComplete="off" rows={5} cols={5} className="input-field" type="text" onChange={handleInputChange} value={form.observations}></textarea>
                        </div>                    
                        <div className="w-100 d-flex d-flex justify-content-center my-4">
                            <button className="primary-button save-button" type="submit">Actualizar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Inventory;
  