export const requestRequirements = {
    employee: [
        'Fotocopia de la cédula',
        'Carta laboral que incluya sueldo, cargo, tiempo y tipo de contrato',
        'Últimos 3 desprendibles de pago'
    ],
    independent: [
        'Fotocopia de la cédula',
        'Fotocopia del RUT',
        'Certificado de existencia y representación legal (no mayor a 3 meses)',
        'Estados financieros del último periodo certificados por un contador público',
        'Extractos bancarios de los últimos 3 meses'
    ],
    pensionary: [
        'Fotocopia de la cédula',
        'Últimos 3 desprendibles de la pensión',
        'Extractos bancarios de los últimos 3 meses'
    ],
    juridical: [
        'Fotocopia del representante legal',
        'Fotocopia del RUT de la empresa',
        'Certificado de existencia y representación legal (no mayor a 3 meses)',
        'Estados financieros del último periodo certificados por un contador público',
        'Extractos bancarios de los últimos 3 meses'
    ],
};

export const requestsTableHeaders = [
    "Solicitante",
    "Tipo",
    "Ubicación",
    "Ingresos",
    "Fecha solicitud",
    "Estado",
];

export const requestsTableBody = [
    {
        data: "applicant",
        classname: ""
    },
    {
        data: "userTypeText",
        classname: ""
    },
    {
        data: "ubication",
        classname: ""
    },
    {
        data: "incomeText",
        classname: ""
    },
    {
        data: "creationDate",
        classname: ""
    },
    {
        data: "status",
        classname: ""
    },
];