import {
    LOGIN_USER,
    LOGOUT_USER
} from '../types';
import { getFormattedHeaders, queryIsLoading, queryNotLoading } from './global-actions';
import axios from 'axios';
import Swal from 'sweetalert2';
import { itemsPerPage } from './global-actions-data';

const apiUrl = process.env.REACT_APP_API_URL;

export function loginUser(email, pass) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.post(`${apiUrl}/auth/login`, 
        {
            email,
            password:pass
        }).then(result => {
            if(result.data.code === "01"){
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text:"No existe un usuario con los datos ingresados, verifique e intente nuevamente."
                });
            }else{
                dispatch(queryNotLoading());
                dispatch(getUserData(result.data.token));
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

function getUserData( token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.get(`${apiUrl}/auth/whoiam`, {
            headers: {
                token
            }
            }).then(userResult => {
                //console.log(userResult);
                if(userResult.data.code === "01"){
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text:"Token inválido, verifique e intente nuevamente."
                    });
                }else{
                    userResult.data.data.token = token;
                    dispatch(lgUser(userResult.data.data));
                }
                dispatch(queryNotLoading());
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
        dispatch(queryNotLoading());
    }
}

export function createUser( data, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.post(`${apiUrl}/user`, data,{
                headers: {
                    token
                }
            }).then(result => {
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(result.data)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function getUsers( skip, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.get(`${apiUrl}/user`, {
            headers: {
                token
            },
            params: {
                skip,
                limit: itemsPerPage,
            }
        }).then(result => {
            const headersNeeded = [ 'totalitems', 'totalpages','currentpage' ];
            const headers = getFormattedHeaders( result.headers, headersNeeded);
            const response = { data: result.data, headers };
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(response)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function getUser( id, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.get(`${apiUrl}/user`, {
            headers: {
                token
            },
            params: {
                skip: 0,
                limit: 0,
                query: {
                    _id: id
                }
            }
        }).then(result => {
            if (result.status === 200) {
                dispatch(queryNotLoading());
                cbFunction(result.data)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export function updateUser( data, cbFunction, token ) {
    return (dispatch) => {
        dispatch(queryIsLoading());
        axios.put(`${apiUrl}/user/${data._id}`, data, {
            headers:{
                token
            }
        }).then(result => {
            console.log(result);
            if(result.status === 200){
                dispatch(queryNotLoading());
                cbFunction(result);
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: error.response.data.message
                    });
                } else {
                    Swal.fire({
                        title:"¡Error!",
                        confirmButtonColor:"#76b4ff",
                        text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                    });
                }
            } else if (error.request) {
                console.log(error.request);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            } else {
                console.log('Error', error.message);
                Swal.fire({
                    title:"¡Error!",
                    confirmButtonColor:"#76b4ff",
                    text: "Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador."
                });
            }
            dispatch(queryNotLoading());
        })
    }
}

export const lgUser = (payload) => ({
    type: LOGIN_USER,
    payload
});

export const logoutUser = () => ({
    type: LOGOUT_USER
});
