export const usersTableHeaders = [
    "Nombre",
    "Documento",
    "Teléfono",
    "Correo",
    "Tipo",
    "Estado"
];

export const usersTableBody = [
    {
        data: "userText",
        classname: ""
    },
    {
        data: "documentTex",
        classname: ""
    },
    {
        data: "phone",
        classname: ""
    },
    {
        data: "email",
        classname: ""
    },
    {
        data: "userType",
        classname: ""
    },
    {
        data: "statusText",
        classname: ""
    },
];