import {
    LOGIN_USER,
    LOGOUT_USER
} from '../types';

let userStorage = JSON.parse(localStorage.getItem('userRealState'));

const initialState = {
    user:{
        ...userStorage,
    }
}

export default function userReducer(state = initialState, action) {
    switch(action.type) {
        case LOGIN_USER:
            localStorage.setItem('userRealState', JSON.stringify(action.payload));
            //console.log(action.payload);
            return {
                ...state,
                user:action.payload
            };
        case LOGOUT_USER:
            localStorage.removeItem('userRealState');
            return {
                ...state,
                user:undefined
            };
        default:
            return state;
    }
}