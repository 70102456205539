import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams  } from 'react-router-dom';
import { getProperty, updateProperty } from '../../actions/property-actions';
import DatePicker from 'react-date-picker';
import alert from '../../components/alert';
import { formatDate } from '../../actions/global-actions';
import { getDocumentData } from '../../actions/documents-actions';
import logo from '../../assets/logo-inmobiliaria.jpeg';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import { templateData } from '../properties/create-property-data';
import ReactDOMServer from 'react-dom/server';
import PropertyContractDetail from '../../components/propertyContractDetail/propertyContractDetail';

function CreateIncrement() {
    const currentLink = "/inmuebles";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.user);
    const [loadData, setLoadData] = useState(true);
    const params = useParams();
    const [currentData, setCurrentData] = useState({});
    const [currentRequest, setCurrentRequest] = useState({});
    const [currentContract, setCurrentContract] = useState({});
    const [canShow, setCanShow] = useState(false);

    const [form, setForm] = useState({
        startDate: new Date(),
        previousValue: 0,
        newValue: 0,
        percentage: 0,
    });
    
    useEffect(() => {
        if (loadData) {
            const getFunction = (data) => {
                if(data.code === "01"){
                    alert('Error', data.message);
                    return;
                }

                //console.log('info', data.data[0]);
                let info = data.data[0];
                //console.log('info', info);
                info.ubication = `${info?.address} B/${info?.neighborhood} ${info?.city}, ${info?.province}`;
                setCurrentData(info);

                let request = info.requests.find(request=>request.status==='Aprobado' || request.status==='En contrato');
                if (!request) {
                    alert('Error', 'Para generar un aumento del canon primero debe aceptar una solicitud de algún aspirante');
                    navigate(`${currentLink}/${params.id}/contratos`)
                    return;
                }
                request.person = `${request.firstName} ${request.lastName} ${request.documentType} No ${request.document}`;

                let currentContract = request.contracts.find(contract=>contract.status==='Activo');
                if (!currentContract) {
                    alert('Advertencia', 'No existe un contrato activo para este inmueble, para generar un aumento a algún inquilino, primero debes generar un contrato');
                    navigate(`${currentLink}/${params.id}/contratos`)
                    return;
                }
                currentContract.monthlyCostText = `$${currentContract.monthlyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                currentContract.formattedStartDate = formatDate(currentContract.startDate, 4);
                currentContract.formattedEndDate = formatDate(currentContract.endDate, 4);
                setCurrentContract(currentContract);

                setCurrentRequest(request);
                setCanShow(true);
                setForm({
                    ...form,
                    startDate: new Date(currentContract.endDate),
                    previousValue: currentContract.monthlyCost,
                    newValue: currentContract.monthlyCost
                })
            }

            setLoadData(false);

            setForm({
                startDate: new Date(),
                previousValue: 0,
                newValue: 0,
                percentage: 0,
            })

            dispatch( getProperty( params.id, getFunction, user.token ) );
        }
    }, [loadData, dispatch, form, params.id, user.token ]);
 
    const handleInputChange = (event, option) => {
        const value = event.target.value;
        if (option === 1 && value.length > 2) return;
        if (option === 1) {
            setForm({
                ...form,
                [event.target.name] :  value,
                newValue: currentContract.monthlyCost + Math.floor(currentContract.monthlyCost * (value/100)) 
            })
            return;
        }
        setForm({
            ...form,
            [event.target.name] :  value,
            percentage: Math.floor(((value - currentContract.monthlyCost) * 100)/ currentContract.monthlyCost)
        })
    }

    const handleDateChange = ( name, date ) => {
        setForm({
            ...form,
            [name] :  date
        })
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const isEmpty = validateFields();
        if (isEmpty) {
            alert('¡Atención!','Debe llenar todos los datos excepto el porcentaje para continuar')
            return;
        }
        let data = {...currentData};
        let requests = data.requests;
        let currentRequestToSend = {...currentRequest};
        let currentContractToSend = {...currentContract};
        currentContractToSend.endDate = new Date(new Date(currentContract.endDate).setFullYear(new Date(currentContract.endDate).getFullYear() + 1));
        currentContractToSend.monthlyCost = form.newValue;
        currentContractToSend.increments.push(form);

        for (let index = 0; index < currentRequestToSend.contracts.length; index++) {
            if (currentRequestToSend.contracts[index]._id === currentContractToSend._id) {
                currentRequestToSend.contracts[index] = currentContractToSend;
                break;
            }
        }

        for (let index = 0; index < data.requests.length; index++) {
            if (data.requests[index]._id === currentRequestToSend._id) {
                data.requests[index] = currentRequestToSend;
                break;
            }
        }

        data.requests = requests;
        downloadDocument();
        dispatch( updateProperty( data, createFunct, user.token ) );
    }

    const validateFields = () => {
        for (const field in form) {
            if (form.hasOwnProperty(field)) {
                //console.log(`${field}: ${form[field]}`);
                if ( field !== 'percentage' && form[field] === '' ) {
                    return true
                }
            }
        }
        return false
    }

    const createFunct = (data) => {
        if(data.code === "01"){
            alert('Error', data.message);
            return;
        }
        navigate(-1);
        //console.log(data);
        alert('¡Éxito!', 'Aumento registrado con éxito');
    }

    const downloadDocument = () => {
        currentData.increment = form;
        let doc = new jsPDF('p', 'pt', 'a4');

        const element = ReactDOMServer.renderToString(getDocumentData('5', currentData, {}));

        const opt = {
            margin: [38, 20, 19, 20],
            filename: `5. Aumento Canon de arrendamiento ${currentData.address}.pdf`,
            enableLinks: false,
            pagebreak: {
              avoid: ['tr'],
              mode: ['css', 'avoid-all'],
            },
            image: { type: 'jpeg', quality: 0.7 },
            html2canvas: {
              allowTaint: true,
              dpi: 300,
              letterRendering: true,
              logging: false,
              scale: 2,
              scrollX: 0,
              scrollY: 0,
            },
            jsPDF: { doc },
        };

        html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get('pdf')
        .then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i < totalPages + 1; i++) {
                pdf.setPage(i);
                pdf.setFontSize(7);

                let heightSpace = pdf.internal.pageSize.getHeight() - 9;
                pdf.addImage(logo, pdf.internal.pageSize.getWidth()-60, 10, 40, 25);

                Object.keys(templateData.company).map((keyName, i) => {
                    pdf.text(
                        `${templateData.company[keyName]}`,
                        (pdf.internal.pageSize.getWidth()/2)-(templateData.company[keyName].length/2),
                        heightSpace,
                    );
                    heightSpace += 3;
                    return i;
                })
            }
        })
        .save();
    }

    useEffect(() => {
        //console.log(form)
    }, [form]);

    return(
        <div className="w-100 d-flex">
            { (canShow) &&
                <div className="container d-flex flex-column">
                    <div className="w-100 mt-5">
                        <button onClick={()=>navigate(-1)} className="back-button">&#60; Volver</button>
                    </div>
                    <h1 className="my-5">Nuevo aumento - Inmueble {currentData.code}</h1>
                    <PropertyContractDetail
                        data={currentData}
                        request={currentRequest}
                        contract={currentContract}
                        />
                    <form onSubmit={handleOnSubmit}>
                        <div className="d-flex w-100 flex-wrap">
                            <h4 className="w-100 my-2">Datos del aumento</h4>
                            <p className="w-100 my-2"><i>
                                <b>Información importante:</b> La fecha de inicio de este formulario solo es un valor informativo para el documento y se refiere a la fecha en la cual se hará efectivo
                                el aumento del canon en los pagos, pero el nuevo valor del canon será aplicado al contrato una vez guarde el aumento,
                                la fecha de inicio por defecto es la fecha de finalización del contrato.<br/> <br/>
                                El contrato se renovará por el mismo tiempo que el estipulado en el tipo de contrato ({currentContract.agreementTime})
                            </i></p>
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="startDate">Fecha de inicio</label>
                                <DatePicker
                                    value={ form.startDate }
                                    dayAriaLabel={"Día"}
                                    dayPlaceholder={"dd"}
                                    monthAriaLabel={"Mes"}
                                    monthPlaceholder={"mm"}
                                    yearAriaLabel={"Año"}
                                    yearPlaceholder={"yyyy"}
                                    name={"startDate"}
                                    onChange={ (date)=>handleDateChange( 'startDate', date ) }
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="percentage">% de aumento (Opcional)</label>
                                <input id="percentage" name="percentage" autoComplete="off" min={0} max={100} className="input-field" type="number" onChange={(e)=>handleInputChange(e,1)} value={form.percentage}></input>
                            </div>
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="newValue">Nuevo canon</label>
                                <input id="newValue" name="newValue" autoComplete="off" className="input-field" type="number" min={0} onChange={(e)=>handleInputChange(e,2)} value={form.newValue}></input>
                            </div>
                            <div className="w-100 d-flex d-flex justify-content-center my-4">
                                <button className="primary-button save-button" type="submit">Generar</button>
                            </div>
                        </div>
                    </form>
                </div>
            }
        </div>
    );
}

export default CreateIncrement