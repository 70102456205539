import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import '../../styles/menu.css';
import logo from '../../assets/logo-inmobiliaria.jpeg';
import { menuArray } from './menu-data';
import { logoutUser } from '../../actions/user-actions';

function Menu(){
    let location = useLocation();
    const [path, svPath] = useState();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const [loadData, setLoadData] = useState(true);
    const [links, setLinks] = useState([]);

    useEffect(() => {
        if (loadData) {
            setLoadData(false);
            if (user.userType === "Administrador") {
                setLinks(menuArray);
            } else {
                let newLinks = [];
                for (let index = 0; index < menuArray.length; index++) {
                    if (menuArray[index].privileges === 1) {
                        newLinks.push(menuArray[index]);
                    }
                }        
                setLinks(newLinks)
            }
        }
        //console.log(links);
    }, [loadData, links, user.userType]);

    useEffect(() => {
      svPath(location.pathname);
    }, [location]);

    return(
        <div className="h-100 menu-primary-container p-3 d-flex flex-column">
            <div className="d-flex flex-column align-items-center justify-content-center mt-4">
                <div><img alt={"logo"} src={logo} className="logo-img"/></div>
                <span className="mt-5 user-info-text name">Hola, <b>{user.firstName} {user.lastName}</b></span>
                <span className="user-info-text">{user.userType}</span>
            </div>
            <div className="d-flex flex-column my-auto">
                {links.map( ( link, index ) => (
                    <div key={index} className="d-flex flex-column list-content">
                        <Link 
                            to={link.link} 
                            className={path === ('/'+link.link) ? "tag active" : "tag"}>
                            {link.tag} 
                        </Link>
                        {link.data.map( (sublink, subindex) => (
                            <Link 
                                key={'sub' + subindex} 
                                to={sublink.link} 
                                className={path === ('/'+sublink.link) ? "sublink active" : "sublink"}>
                                {sublink.title}
                            </Link>
                        ))}
                    </div>
                ))}
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <a href='https://drive.google.com/drive/folders/1posutZ6Rw6rwREtbkyrJ55pnBSMhRFGd?usp=sharing'><span className='user-info-text'><i>Manual de usuario</i></span></a>
                <a href='https://wa.me/573188963462'><span className='user-info-text'><i>¿Necesitas ayuda?</i></span></a>
                <div className="logout-button" onClick={() => dispatch(logoutUser())}><i className='user-info-text'><b>Cerrar Sesión</b></i></div>
                <span className='user-info-text'><i>CasApp @2023</i></span>
            </div>
        </div>
    );
}

export default Menu;