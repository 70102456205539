import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProperty, updateProperty } from '../../actions/property-actions';
import Paginator from '../../components/paginator/paginator';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatDate, toCurrency } from '../../actions/global-actions';
import { propertyPaymentsTableHeaders, propertyPaymentsTableBody } from './property-payments-list-data';
import TableComponent from './../../components/table/table';
import alert from '../../components/alert';

function PropertyPaymentsList() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [payments, setPayments] = useState([]);
    const [loadData, setLoadData] = useState(true);
    const user = useSelector(state => state.user.user);
    const currentLink = "/inmuebles";
    const [currentData, setCurrentData] = useState({});

    const [skip, setSkip] = useState(0);
    const [paginatorData, setPaginatorData] = useState({
        totalitems: 0,
        totalpages: 0,
        currentpage: 0
    });

    const navigate = useNavigate();

    useEffect(() => {
        const getFunction = (response) => {
            let data = response.data;
            if(data.code === "01"){
                alert('Error', data.message);
                return;
            }
            
            //console.log(data[0]);
            let requests = data[0].requests || [];
            //console.log('requests', requests);
            let payments = [];
            requests.forEach((request, requestIndex) => {
                request.contracts.forEach((contract, contractIndex) => {
                    contract.payments.forEach((payment, paymentIndex) => {
                        let newPayment = {};
                        newPayment.requestIndex = requestIndex;
                        newPayment.tenant = `${request.firstName} ${request.lastName} ${request.documentType} No ${request.document}`;
                        newPayment.contractIndex = contractIndex;
                        newPayment.date = formatDate(payment.createdAt, 4);
                        newPayment.monthPaidText = formatDate(payment.monthPaid, 6);
                        newPayment.monthPaid = new Date(payment.monthPaid);
                        newPayment.valuePaidText = toCurrency(payment.valuePaid);
                        newPayment.paymentIndex = paymentIndex;
                        newPayment.link = `${currentLink}/${id}/pagos/${payment._id}/editar`;
                        payments.push(newPayment);
                    });
                });
            });

            payments.sort((a, b) => b.monthPaid - a.monthPaid);

            //console.log('payments', payments);

            setPayments(payments);
            setCurrentData(data[0]);
            /*
            setPaginatorData({
                ...paginatorData,
                ...response.headers,
            })*/
        }

        if (loadData) {
            setLoadData(false);
            setPayments([]);
            setCurrentData({});
            dispatch( getProperty( id, getFunction, user.token ) );
        }
    }, [loadData, dispatch, user.token, id, skip, paginatorData]);
    
    const handleOnClick = (payment) => {
        if (window.confirm(`¿Realmente desea eliminar el pago con datos: Inquilino: ${payment.tenant}, Fecha ${payment.date}, ¡ESTA OPCIÓN NO SE PUEDE DESHACER!`)) {
            currentData.requests[payment.requestIndex].contracts[payment.contractIndex].payments.splice(payment.paymentIndex,1);
            dispatch( updateProperty( currentData, updateFunction, user.token ) );
        }
    }

    const updateFunction = (data) => {
        if(data.data.code === "01"){
            alert('Error', data.data.message);
            return;
        }
        //console.log('data',data);
        alert('¡Éxito!', 'Pago eliminado con éxito');
        setLoadData(true);
    }

    useEffect(() => {
        //console.log(payments)
    }, [payments]);

    return (
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <div className="w-100 mt-5">
                    <button onClick={()=>navigate(-1)} className="back-button">&#60; Volver</button>
                </div>
                <h1 className="my-5">Pagos - Inmueble {currentData.code}</h1>
                <div className="d-flex justify-content-end">
                    <Link to={`${currentLink}/${id}/nuevo-pago`} className="primary-button">Registrar pago</Link>
                </div>
                <TableComponent
                    id={"property-payments-table"}
                    headers={propertyPaymentsTableHeaders}
                    body={propertyPaymentsTableBody}
                    skip={skip}
                    data={payments}
                    link={``}
                    hasOwnLink={true}
                    needOptions={true}
                    deleteAction={handleOnClick}
                />
                {paginatorData.totalpages > 0 && paginatorData.currentpage > 0 && 
                    <Paginator skip={skip} data={paginatorData} setSkip={(n) => setSkip(n)} setLoadData={(need) => setLoadData(need)} />
                }
            </div>
        </div>
    )
}

export default PropertyPaymentsList