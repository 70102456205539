export const awaitingTableHeaders = [
    "Fecha solicitud",
    "Solicitante",
    "Tipo",
    "Ubicación",
    "Ingresos",
];

export const awaitingTableBody = [
    {
        data: "formattedDate",
        classname: ""
    },
    {
        data: "applicant",
        classname: ""
    },
    {
        data: "userTypeText",
        classname: ""
    },
    {
        data: "ubication",
        classname: ""
    },
    {
        data: "incomeText",
        classname: ""
    },
];