import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams  } from 'react-router-dom';
import { getProperty, updateProperty } from '../../actions/property-actions';
import DatePicker from 'react-date-picker';
import { agreementTimes } from '../properties/create-property-data';
import alert from '../../components/alert';
import { DEFAULT_MONTHS, GET_MONTS } from '../payments/payments-data';

function EditContract(){
    const currentLink = "/inmuebles";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.user);
    const [loadData, setLoadData] = useState(true);
    const params = useParams();
    const [currentData, setCurrentData] = useState({});
    const [currentRequest, setCurrentRequest] = useState({});
    const [currentContract, setCurrentContract] = useState({});

    const [limitDays, setLimitDays] = useState({});
    const [canShow, setCanShow] = useState(false);

    const [form, setForm] = useState({
        startDate: '',
        endDate: '',
        limitDay: '10',
        agreementTime: 'Anual',
        monthlyCost: '',
    });
    
    useEffect(() => {
        if (loadData) {
            const getFunction = (data) => {
                if(data.code === "01"){
                    alert('Error', data.message);
                    return;
                }

                let info = data.data[0];
                //console.log('info', info);
                setCurrentData(info);

                let requestObj = {};
                let contractObj = {};

                for (let index = 0; index < info.requests.length; index++) {
                    const request = info.requests[index];
                    const contract = request.contracts.find(contract=>contract._id===params.contractID);
                    if (contract) {
                        requestObj = request;
                        contractObj  = contract;
                        break;
                    }
                }

                if (Object.keys(contractObj).length === 0 ) {
                    alert('Error', 'No existe un contrato con los datos proporcionados');
                    return;
                }

                contractObj.startDate = new Date( contractObj.startDate );
                contractObj.endDate = new Date( contractObj.endDate );

                let days = [];
                for (let index = 1; index < 32; index++) {
                    days.push(index);
                }
                setLimitDays(days);

                requestObj.person = `${requestObj.firstName} ${requestObj.lastName} ${requestObj.documentType} No ${requestObj.document}`;
                requestObj.monthlyIncome = requestObj.monthlyIncome?requestObj.monthlyIncome:0;
                requestObj.incomeText = `$${requestObj.monthlyIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                requestObj.monthlyExpenses = requestObj.monthlyExpenses?requestObj.monthlyExpenses:0;
                requestObj.expensesText = `$${requestObj.monthlyExpenses.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                setCurrentRequest(requestObj);

                setCurrentContract(contractObj);
                setCanShow(true);
                setForm({
                    ...form,
                    ...contractObj
                })
                
            }
            setLoadData(false);

            setForm({
                startDate: '',
                endDate: '',
                limitDay: '10',
                agreementTime: 'Anual',
                monthlyCost: '',
            })
            dispatch( getProperty( params.id, getFunction, user.token ) );
        }
    }, [loadData, dispatch, form, params.id, user.token, params.contractID ]);
 
    const handleInputChange = (event) => {
        if (currentContract.status === 'Activo') {
            setForm({
                ...form,
                [event.target.name] :  event.target.value
            })
        }
    }

    const handleDateChange = ( name, date ) => {
        if (currentContract.status === 'Activo') {
            if (name === 'endDate') {
                setForm({
                    ...form,
                    [name] :  date,
                })
                return;
            }
            
            const newEndDate = new Date( new Date(date).setMonth(new Date(date).getMonth() + (GET_MONTS[form.agreementTime] || DEFAULT_MONTHS)) )
            setForm({
                ...form,
                [name] :  date,
                endDate: newEndDate
            })
        }
    }

    const endContract = () => {
        if (window.confirm(`¿Realmente desea finalizar el contrato?`)) {
            let data = {...currentData};
            let requests = data.requests;
            let currentRequestToSend = {...currentRequest};
            let currentContractToSend = {...currentContract};

            loopX:
            for (let index = 0; index < requests.length; index++) {
                let request = requests[index];
                if (request._id === currentRequestToSend._id) {
                    for (let y = 0; y < request.contracts.length; y++) {
                        let contract = request.contracts[y];
                        if (contract._id === currentContractToSend._id) {
                            requests[index].contracts[y].status = "Finalizado";
                            requests[index].contracts[y].finishDate = new Date();
                            requests[index].status = "Terminado";
                            //console.log('requests[index]', requests[index]);
                            break loopX;
                        }
                    }
                }
            }
            //console.log('requests', requests);

            data.requests = requests;
            //console.log('data', data);
            dispatch( updateProperty( data, updateFunct, user.token ) );
        }        
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const isEmpty = validateFields();
        if (isEmpty) {
            alert('¡Atención!', 'Debe llenar todos los datos para continuar');
            return;
        }
        let data = JSON.parse(JSON.stringify(currentData));
        let requests = data.requests;
        let currentRequestToSend = JSON.parse(JSON.stringify(currentRequest));
        let currentContractToSend = JSON.parse(JSON.stringify(currentContract));

        for (let index = 0; index < requests.length; index++) {
            let request = requests[index];
            if (request._id === currentRequestToSend._id) {
                for (let y = 0; y < request.contracts.length; y++) {
                    let contract = request.contracts[y];
                    if (contract._id === currentContractToSend._id) {
                        requests[index].contracts[y] = form;
                    }
                }
            }
        }
        //console.log('requests', requests);

        data.requests = requests;
        //console.log('data', data);
        dispatch( updateProperty( data, updateFunct, user.token ) );
    }

    const validateFields = () => {
        for (const field in form) {
            if (form.hasOwnProperty(field)) {
                //console.log(`${field}: ${form[field]}`);
                if ( form[field] === '' ) {
                    return true
                }
            }
        }
        return false
    }

    const updateFunct = (data) => {
        if(data.code === "01"){
            alert('Error', data.message);
            return;
        }
        alert('¡Éxito!', 'Contrato actualizado con éxito');
        navigate(-1)        
    }

    useEffect(() => {
        //console.log(form)
    }, [form]);

    return(
        <div className="w-100 d-flex">
            { (canShow) ?
                <div className="container d-flex flex-column">
                    <div className="w-100 mt-5">
                        <button onClick={()=>navigate(-1)} className="back-button">&#60; Volver</button>
                    </div>
                    <h1 className="my-5">{currentContract.status!=='Activo'?'Ver':'Editar'} contrato - Inmueble {currentData.code}</h1>
                    <div className="d-flex w-100 flex-wrap mb-4">
                        <h4 className='w-100'>Datos de la solicitud aprobada</h4>
                        <div className="d-flex flex-column w-3 field">
                            <label className='label-field'>Persona</label>
                            <span className="span-field">{currentRequest.person}</span>
                        </div>
                        <div className="d-flex flex-column w-3 field">
                            <label className='label-field'>Ingresos</label>
                            <span className="span-field">{currentRequest.incomeText}</span>
                        </div>
                        <div className="d-flex flex-column w-3 field">
                            <label className='label-field'>Gastos</label>
                            <span className="span-field">{currentRequest.expensesText}</span>
                        </div>
                        <div className="d-flex flex-column w-100 field">
                            <label className='label-field'>Observación</label>
                            <span className="span-field">{currentRequest.observation}</span>
                        </div>
                        <div className="w-100 d-flex d-flex justify-content-center">
                            <Link to={`${currentLink}/${params.id}/solicitud/${currentRequest._id}`} className="end-button go">Ir a solicitud</Link>
                        </div>
                    </div>
                    <form onSubmit={handleOnSubmit}>
                        <div className="d-flex w-100 flex-wrap">
                            <h4 className="w-100 my-2">Datos Generales</h4>
                            {currentContract.status==='Activo'?
                                <p className='w-100 m-0' style={{fontStyle: 'italic'}}>
                                    Este es el contrato actualmente activo <br/>
                                    <b>Importante:</b> Recuerde que aumentar el valor del canon sin registrar un <b>aumento</b> hará que el
                                    documento de "aumento de canon" no se genere adecuadamente, si lo desea puede registrar un aumento <Link to={`${currentLink}/${params.id}/nuevo-aumento`} style={{textDecoration: 'underline'}}>aquí</Link>
                                </p>
                                :
                                <span className='w-100' style={{fontStyle: 'italic'}}>Este contrato ya finalizó, los contratos finalizados no se pueden modificar</span>
                            }
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="agreementTime">Tiempo de contrato</label>
                                <select className="input-field" id="agreementTime" name="agreementTime" onChange={handleInputChange} value={form.agreementTime}>
                                    <option></option>
                                    {agreementTimes.map((type, index) => (
                                        <option key={`${type}${index}`} value={type}>{type}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="startDate">Fecha de inicio</label>
                                <DatePicker
                                    value={ form.startDate }
                                    dayAriaLabel={"Día"}
                                    dayPlaceholder={"dd"}
                                    monthAriaLabel={"Mes"}
                                    monthPlaceholder={"mm"}
                                    yearAriaLabel={"Año"}
                                    yearPlaceholder={"yyyy"}
                                    name={"startDate"}
                                    onChange={ (date)=>handleDateChange( 'startDate', date ) }
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="endDate">Fecha de finalización</label>
                                <DatePicker
                                    value={ form.endDate }
                                    dayAriaLabel={"Día"}
                                    dayPlaceholder={"dd"}
                                    monthAriaLabel={"Mes"}
                                    monthPlaceholder={"mm"}
                                    yearAriaLabel={"Año"}
                                    yearPlaceholder={"yyyy"}
                                    name={"endDate"}
                                    onChange={ (date)=>handleDateChange( 'endDate', date ) }
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                            {limitDays.length > 0 &&
                                <div className="d-flex flex-column w-3 field">
                                    <label className="label-field" htmlFor="limitDay">Fecha límite para pago</label>
                                    <select className="input-field" id="limitDay" name="limitDay" onChange={handleInputChange} value={form.limitDay}>
                                        <option></option>
                                        {limitDays.map((day, index) => (
                                            <option key={index}>{day}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="monthlyCost">Mensualidad</label>
                                <input id="monthlyCost" name="monthlyCost" autoComplete="off" maxLength={180} className="input-field" type="number" min={0} onChange={handleInputChange} value={form.monthlyCost}></input>
                            </div>
                            {currentContract.status === 'Activo' &&
                                <div className="w-100 d-flex d-flex justify-content-center my-2">
                                    <button className="primary-button save-button" type="submit">Actualizar</button>
                                </div>
                            }
                            {currentContract.status === 'Activo' &&
                                <div className="w-100 d-flex d-flex justify-content-center mb-2">
                                    <button className="end-button delete" type="button" onClick={endContract}>Finalizar contrato </button>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            : null}
        </div>
    );
}

export default EditContract;
  