import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import alert from '../../components/alert';
import TableComponent from '../../components/table/table';
import useQuery from '../../components/useQuery';
import readXlsxFile from 'read-excel-file';
import axios from 'axios';
import { queryIsLoading, queryNotLoading, stringCleaner } from '../../actions/global-actions';

const apiUrl = process.env.REACT_APP_API_URL;

const OPTION_NAME = {
  '1': 'Propietarios',
  '2': 'Inmuebles',
  '3': 'Contratos',
}

const OPTION_DEFAULT_NAME = 'Selección inválida';

const OPTION_FILE = {
  '1': 'propietarios.xlsx',
  '2': 'inmuebles.xlsx',
  '3': 'contratos.xlsx',
}

const OPTION_COLUMNS = {
  '1': 6,
  '2': 6,
  '3': 7,
}

function Upload() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [canShow, setCanShow] = useState(false);
  const [fileName, setFileName] = useState('Seleccionar archivo..');
  const [headers, setHeaders] = useState([]);
  const [body, setBody] = useState([]);
  const user = useSelector(state => state.user.user);
  let query = useQuery(); 

  useEffect(() => {
    if (!query.get('option') || !OPTION_NAME[query.get('option')]) {
      alert('Error', 'Tiene que seleccionar una opción válida')
      return;
    }
    defaultValues();
    setCanShow(true);
  }, [query]);

  const defaultValues = () => {
    setData([]);
    setFileName('Seleccionar archivo..');
  }
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (event.target.files.length === 0) {
      defaultValues();
      return;
    }
  
    // validación de formato del archivo subido, ya que solo se permiten excel
    const validExts = new Array(".xlsx", ".xls");
    let fileExt = event.target.value;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0) {
      alert('Advertencia', `Selección inválida, solo se pueden subir archivos de tipo: ${validExts.toString()}`);
      defaultValues();
      return;
    }

    // Validación de nombre de archivo
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      // Validación de que el archivo seleccionado tenga el mismo nombre que el esperado
      if (file.name !== OPTION_FILE[query.get('option')]) {
        alert(
          'Advertencia',
          `El archivo no cuenta con el nombre correcto, el nombre del archivo debe ser '${OPTION_FILE[query.get('option')]}' (sin las comillas)`
        );
        defaultValues();
        return;
      }
      setFileName(file.name);
      readXlsxFile(file).then((rows) => {
        // Validación de que existan registros en el archivo
        if (rows.length < 2) {
          alert(
            'Advertencia',
            'El archivo no cuenta con registros, por favor verifique la información'
          );
          defaultValues();
          return;
        }

        // Validación de que el archivo contenga las columnas necesarias
        if (rows[0].length < OPTION_COLUMNS[query.get('option')] || rows[0].length > OPTION_COLUMNS[query.get('option')]) {
          alert(
            'Advertencia',
            'El archivo no cuenta con el número de columnas esperado, por favor revise la información o vuelva a descargar la plantilla de cargue'
          );
          defaultValues();
          return;
        }

        // Se extraen los nombres de las columnas
        let headersArray = [];
        let bodyArray = []
        const initialRow = rows[0];
        initialRow.forEach( (cell, cellIndex) => {
          headersArray.push(stringCleaner(rows[0][cellIndex]));
          bodyArray.push({
            data: stringCleaner(rows[0][cellIndex]),
            classname: ''
          });
        });
        setHeaders(headersArray);
        setBody(bodyArray);

        // Se extraen los valores de cada fila
        let dataArray = [];
        for (let index = 1; index < rows.length; index++) {
          const row = rows[index];
          let obj = {};
          row.forEach( (cell, cellIndex) => {
            obj[rows[0][cellIndex]] = typeof cell === 'number'?cell:stringCleaner(cell);
          });
          dataArray.push(obj);
        }
        setData(dataArray);
      })
    }
    fileReader.onerror = (error) => {
      console.log(error);
    }
  }

  const sendRequest = ( link, newObj, array ) => {
    dispatch(queryIsLoading());
    axios.post(`${apiUrl}/${link}`, newObj , {
      headers: {
          token: user.token
      }
    }).then(result => {
      if (result.status === 200) {
          dispatch(queryNotLoading());
          if (data.length > 0) {
            createObject(array);
          }
          //cbFunction(result.data)
      }
    }).catch(error => {
      dispatch(queryNotLoading());
      setData(array);
      if (error.response) {
        //console.log('response', error.response);
        alert('¡Error!', error.response.data.message);
        return;
      }

      console.log('Error', error.message);
      alert('¡Error!', 'Hubo un error, vuelve a intentarlo, Si el error persiste contacte al administrador.');
    })
  }

  const createObject = (array) => {
    let newArray = JSON.parse(JSON.stringify(array));
    if (newArray.length > 0) {
      const length = data.length - array.length;
      alert('Procesando..', `Subiendo ${length} de ${data.length}`);
      switch (query.get('option')) {
        case '1':
          // Caso de propietarios
          const userObj = {
            firstName: newArray[0]['Nombres'],
            lastName: newArray[0]['Apellidos'],
            email: newArray[0]['Email'],
            documentType: newArray[0]['Tipo'],
            document: newArray[0]['Identificación'],
            phone: newArray[0]['Teléfono'],
            password: '123456',
            userType: 'Propietario',
            city: 'Cali',
            province: 'Valle del Cauca',
            documentCity: 'Cali',
            documentProvince: 'Valle del Cauca',
            bankAccount: [{
              bank: '',
              accountType: '',
              accountNumber: '',
              owner: '',
            }],
          };
          newArray.splice(0,1);
          sendRequest('user', userObj, newArray);
          break;
        case '2':
          // Caso de inmuebles
          const propertyObj = {
            propertyType: newArray[0]['Tipo'],
            address: newArray[0]['Dirección'].toLocaleUpperCase(),
            neighborhood: newArray[0]['Barrio (Vacío si no sabe)']?newArray[0]['Barrio (Vacío si no sabe)']:'PENDIENTEPRU',
            province: 'Valle del Cauca',
            city: 'Cali',
            description: 'Inmueble creado desde un archivo excel',
            monthCost: newArray[0]['Valor Canon'],
            hasAdministrationFee: newArray[0]['INCLUYE CUOTA DE ADMINISTRACIÓN']==='SI'?true:false,
            feeIncluded: newArray[0]['INCLUYE CUOTA DE ADMINISTRACIÓN']==='SI'?false:true,
            administrationCost: newArray[0]['CUOTA DE ADMINISTRACIÓN'],
            managementTime: 'Anual',
            managementPercentage: 10,
            owner: {
              userID: '623e54b1e4c1e174ab6dde00'
            },
            administrator: {
              userID: '623e54b1e4c1e174ab6dde00'
            },
            minimumNotificationTime: '3 meses',
            bankAccount: {
              bank: 'Bancolombia',
              accountType: 'Ahorros',
              accountNumber: '06003131465',
              owner: 'A&H Group SAS',
            },
            floorsNumber: '1',
            realEstateRegistration: '',
            propertyNumber: '',
            publicDeedNumber: '',
            publicDeedDate: '',
            notary: '',
            servicesBill: '',
            gasBill: '',
          };
          newArray.splice(0,1);
          sendRequest('property', propertyObj, newArray);
          break;
        case '3':
          // Caso de contratos
          const code = newArray[0]['Código inmueble'];
          const startDate = newArray[0]['Fecha de inicio Contrato'];
          const contractObj = {
            actualProperty: {
              type: '',
              address: '',
              neighborhood: '',
              actualPropertyProvince: '',
              actualPropertyCity: '',
              landlord: '',
              landlordPhone: '',
              monthlyCost: 0,
              administrationCost: '',
              antiquity: ''
            },
            employee: {
              company: '',
              address: '',
              position: '',
              salary: '',
              admissionDay: '',
              otherIncome: []
            },
            independent: {
              activity: '',
              address: '',
              employeesNumber: '',
              income: '',
              expenses: ''
            },
            pensionary: { reason: '', value: null, otherIncome: [] },
            firstName: newArray[0]['Nombres'].toLocaleUpperCase(),
            lastName: newArray[0]['Apellidos'].toLocaleUpperCase(),
            documentType: newArray[0]['Tipo documento'],
            document: `${newArray[0]['Documento']}`,
            documentCity: '',
            documentProvince: '',
            email: '',
            phone: '',
            occupation: '',
            province: '',
            city: '',
            dependents: '',
            peopleWhoWillLive: '',
            monthlyExpenses: '',
            userType: '',
            properties: [],
            vehicles: [],
            finantialInformation: [],
            references: [],
            cosigners: [],
            description: 'Subido desde un excel',
            acceptTerms: true,
            observation: 'Subido desde un excel',
            status: 'En contrato',
            contracts: [
                {
                  startDate: new Date(startDate+' 00:00:00'),
                  endDate: new Date(new Date(startDate+' 00:00:00').setFullYear(new Date(startDate+' 00:00:00').getFullYear() + 1)),
                  limitDay: newArray[0]['Fecha limite de pago (1-30)'],
                  agreementTime: 'Anual',
                  monthlyCost: '',
                  status: 'Activo',
                  payments: [],
                }
            ],
          };
          newArray.splice(0,1);
          sendRequest(`property/upload-contract/${code}`, contractObj, newArray);
          break;
        default:
          alert('Error', 'No existe un documento con dicha selección');
          break;
      }
      return;
    }
    alert('Proceso terminado', 'Todos los registros fueron subidos con éxito al sistema');
    setData(newArray);
  }

  const handleSubmit = (event) => {
    if (window.confirm('¿Realmente desea subir los registros actuales?')) {
      createObject(data);
    }
  }

  useEffect(() => {
    //console.log('data', data);
  }, [data])
  

  return(
      <div className="w-100 d-flex">
        {canShow && 
          <div className="container d-flex flex-column">
            <h1 className="my-5">Subir documento - {OPTION_NAME[query.get('option')] || OPTION_DEFAULT_NAME}</h1>
            <span>Puede descargar la plantilla para el cargue <a href={`/templates/${OPTION_FILE[query.get('option')]}`} download><b>aquí</b></a></span>
            <div className="d-flex flex-column w-3 field">
                <label className="label-field" htmlFor="document">Documento</label>
                <label htmlFor="document" className="file-upload">
                    {fileName}
                </label>
                <input id="document" onChange={handleFileChange} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file"/>
            </div>
            {data.length > 0 &&
              <>
                <span className='w-100 mt-4'>Por favor revise que todos los datos contengan información válida y que sea la que desea subir</span>
                <div className='mt-3'>
                  <span className='align-self-center'>Registros: <b>{data.length}</b></span>
                </div>
                <TableComponent
                    id={"upload-table"}
                    headers={headers}
                    body={body}
                    skip={0}
                    data={data}
                    link={``}
                />
                <div className='d-flex justify-content-center my-2'>
                  <button className='primary-button' onClick={handleSubmit}>Subir</button>
                </div>
              </>
            }

        </div>
        }
      </div>
  );
}

export default Upload