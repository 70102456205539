import { formatDate, toCurrency } from './global-actions';
import Swal from 'sweetalert2';

export function getDocumentData( option, data, apply ) {
    switch (option) {
        case '1':
            //console.log('data', data);
            if (data.inventory.length === 0){
                Swal.fire({
                    title:"Advertencia",
                    confirmButtonColor:"#76b4ff",
                    text: 'Para generar el documento de inventario, primero debe dilengenciar sus datos'
                });
                return '';
            }
            return inventaryDocument(data);
        case '2':
            return administrationDocument(data);
        case '3':
            data.request = apply;
            return leasingDocument(data);
        case '5':
            data.request = data.requests.find(request=>request.status === 'Aprobado' || request.status === 'En contrato');
            data.request.name = `${data.request.firstName} ${data.request.lastName}`.toUpperCase();
            data.administratorName = `${data.administrator.userID.firstName} ${data.administrator.userID.lastName}`.toUpperCase();
            data.ubication = `${data.address} B/${data.neighborhood}`;
            data.cityText = `${data.city}, ${data.province}`;
            return incrementDocument(data);
      default:
        return ''
    }
}

const inventaryDocument = ( data ) => (
    <div id="capture" style={{textAlign: 'justify', fontSize: '0.9em', lineHeight: 1.2}}>
        {data.city}, {data.province} - {formatDate(new Date(), 3)}<br/>
        <span style={{width: '100%', textAlign: 'center', display: 'block', marginTop: '1rem'}}>INVENTARIO DEL INMUEBLE <b>{data.code}</b></span>
        <br/>
        <br/>
        <div className="d-flex w-100 flex-wrap">
            <table className='w-100'>
                <thead>
                    <tr>
                        <th className="inventory-item">Item</th>
                        <th className="inventory-item">Estado</th>
                        <th className="inventory-item">Cantidad</th>
                        <th className="inventory-item">Material</th>
                        <th className="inventory-item">Marca</th>
                        <th className="inventory-item">Observaciones</th>
                    </tr>
                </thead>
                <tbody>
                    {data.inventory.map((item, index) => (
                        <tr key={`${item}${index}`}>
                            <td className="inventory-item">
                                <span style={{fontSize: '.85em'}}>{item.name}</span>
                            </td>
                            <td className="inventory-item">
                                <span>{item.state}</span>
                            </td>
                            <td className="inventory-item">
                                <span>{item.quantity}</span>
                            </td>
                            <td className="inventory-item">
                                <span>{item.brand}</span>
                            </td>
                            <td className="inventory-item">
                                <span>{item.material}</span>
                            </td>
                            <td className="inventory-item">
                                <span>{item.observations}</span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <br/>
            <br/>
            <div className="d-flex flex-column w-100 field">
                <label className="label-field" htmlFor='implements'>Pisos, paredes, implementos</label>
                <span>{data.implements}</span>
            </div>
            <br/>
            <br/>
            <div className="d-flex flex-column w-100 field">
                <label className="label-field" htmlFor='services'>Servicios, cosas y usos adicionales</label>
                <span>{data.services}</span>
            </div>
            <br/>
            <br/>
            <div className="d-flex flex-column w-100 field">
                <label className="label-field" htmlFor='observations'>Observaciones</label>
                <span>{data.observations}</span>
            </div>                    
        </div>
    </div>
);

const administrationDocument = ( data ) => (
    <div id="capture" style={{textAlign: 'justify', fontSize: '0.9em', lineHeight: 1.2}}>
        <span style={{width: '100%', textAlign: 'center', display: 'block', marginTop: '1rem'}}>CONTRATO DE ADMINISTRACIÓN DE INMUEBLE</span>
        <br/>
        <br/>
        <b>I Partes.</b><br/>
        <br/>
        <b>1. El Administrador.</b><br/>
        <br/>
        {data.administratorName}, mayor de edad, domiciliado(a) y residente en {data.administrator.userID.city} - {data.administrator.userID.province}, identificado con {data.administrator.userID.documentType} No {data.administrator.userID.document} expedido en {data.administrator.userID.documentCity} - {data.administrator.userID.documentProvince}, actuando de manera libre y espontánea, cuya capacidad le permite suscribir el presente contrato.<br/>
        <br/>
        <b>2. El Consignador.</b><br/>
        <br/>
        {data.ownerName}, mayor de edad, domiciliado(a) y residente en {data.owner.userID.city} - {data.owner.userID.province}, identificado con {data.owner.userID.documentType} No {data.owner.userID.document} expedido en {data.owner.userID.documentCity} - {data.owner.userID.documentProvince}, actuando en calidad de <b>propietario</b>, del bien inmueble que se describe más adelante, en lo sucesivo El Consignador.<br/>
        <br/>
        <b>II Datos Generales del Inmueble.</b><br/>
        <br/>
        Las partes establecen que tanto el número de inmuebles como sus descripciones quedarán registradas en hojas anexas a este contrato, en la medida en que el Consignador le entregue otros bienes para su Administración. No obstante lo anterior, el primer bien inmueble registrado consta de lo siguiente:<br/>
        <br/>
        Dirección:<br/>
        {data.address} B&#47; {data.neighborhood}, {data.city} - {data.province}<br/>                                                                   
        Tipo de bien inmueble:<br/>
        {data.propertyType}<br/>
        {data.floorsNumber?
            <span>
                Número de pisos:<br/>
                {data.floorsNumber}<br/>
            </span>
        : null}                                          
        Número de matrícula inmobiliaria:<br/>
        {data.realEstateRegistration}<br/>
        El inmueble dado en administración consta de:<br/>
        {data.description}<br/>
        Número predial:<br/>
        {data.propertyNumber}<br/>
        <br/>
        <b>III Linderos Generales.</b><br/>
        <br/>
        La descripción de este punto se encuentra en la Escritura Pública {data.publicDeedNumber} de {formatDate(data.publicDeedDate, 1)} de la notaría {data.notary}<br/>
        <br/>
        <b>IV Linderos Especiales.</b><br/>
        <br/>
        La descripción de este punto se encuentra en la Escritura Pública {data.publicDeedNumber} de {formatDate(data.publicDeedDate, 1)} de la notaría {data.notary}<br/>
        <br/>
        En caso de no ser anotados los linderos generales y especiales del inmueble, el Consignador autoriza irrevocablemente al Administrador para tener como tales los que han sido aportados como anexo del presente contrato, consignados en las fotocopias de la última escritura pública del inmueble.<br/>
        <br/>
        <b>V Cláusulas del Contrato.</b><br/>
        <br/>
        Por el presente contrato se hace constar que entre el <b>Administrador</b> y el <b>Consignador</b> se ha celebrado un <b>Contrato de Administración de Inmueble para alquiler</b> contenido en las siguientes cláusulas:<br/>
        <br/>
        <b>1. Mandato.</b> Por medio del presente documento, el <b>Consignador</b> faculta al <b>Administrador</b> a realizar todas las gestiones necesarias para promocionar y dar en alquiler el inmueble descrito en los títulos II y IV de este contrato.<br/>
        <br/>
        <b>2. Facultades de Administración.</b> En ejercicio de este mandato, el <b>Administrador</b> tendrá facultades para:<br/>
        <br/>
        <b>2.1</b> Utilizar cualquier medio idóneo y legal para conseguir arrendatario(s), siendo de cargo del <b>Consignador</b> los costos que genere dicha publicidad (salvo acuerdo en contrario).<br/>
        <br/>
        <b>2.2</b> Promocionar el inmueble por los medios que encuentre más efectivos con el fin de ubicar interesados en alquilarlo.<br/>
        <br/>
        <b>2.3</b> Los riesgos derivados del presente contrato son del <b>Consignador</b>, ya que las obligaciones del Administrador son de medio y no de resultado.<br/>
        <br/>
        <b>3. Término de Consignación y Promoción.</b> Es aquel dentro del cual el <b>Administrador</b> procede a promocionar el bien que le ha sido entregado en consignación exclusiva. Las partes, <b>Consignador</b> y <b>Administrador</b> convienen un término <b>{data.managementTime}</b>, contados a partir de la suscripción del presente documento para que el <b>Administrador</b> realice la promoción para conseguir el respectivo arrendatario. Si cumplido este término no se ha conseguido alquilar el bien entregado, este contrato expirará de manera inmediata.<br/>
        <br/>
        <b>3.1</b> Se considerará un incumplimiento del <b>Consignador</b> o de su responsabilidad la ocurrencia de cualquiera de los siguientes casos: i) No respetar los términos del presente contrato, sobre todo lo tratado en esta parte; ii) Que desista de lo aquí convenido o del alquiler a pesar de haberse ubicado al inquilino o pretenda modificar el papel del <b>Administrador</b> entregándolo a otra persona o entidad, sin que se hubiese dado por terminado el mismo. iii) Que por su cuenta venda el inmueble a un tercero sin previa comunicación al administrador con no menos de <b>{data.minimumNotificationTime}</b> de anticipación a su ocurrencia.<br/>
        <br/>
        <b>4. Prórroga y Terminación del Contrato.</b> Si el <b>Consignador</b> desea no prorrogar el contrato de administración, deberá dar aviso de manera escrita al <b>Administrador</b>, con una anticipación no inferior a <b>{data.minimumNotificationTime}</b> a la fecha de terminación del presente contrato.<br/>
        <br/>
        <b>4.1</b> En la eventualidad que el <b>Consignador</b> decidiese dar por terminado sin justa causa el contrato de administración antes de su expiración, deberá cancelar al <b>Administrador</b> una suma equivalente a dos cánones de arrendamiento del valor fijado para el alquiler del inmueble, sin que fueren necesarios requerimientos judiciales, constituyéndose el presente documento en título ejecutivo suficiente para el cobro.<br/>
        <br/>
        <b>5. Precio base para el alquiler.</b> El <b>Consignador</b> autoriza expresamente al <b>Administrador</b> para ofrecer el inmueble por primera vez por un precio que se determina de mutuo acuerdo y en las renovaciones el incremento será IGUALMENTE FIJADO EN FORMA CONCERTADA CONFORME A LA NORMATIVIDAD VIGENTE.<br/>
        <br/>
        <b>6. Comisión.</b> El <b>Consignador</b> reconocerá al <b>Administrador</b> una comisión, así:<br/>
        <br/>
        <b>6.1</b> Del {`${data.managementPercentage}%`}, liquidado sobre el valor del alquiler, que serán descontados del valor del canon mensual de arrendamiento.<br/>
        <br/>
        <b>7. Otras Obligaciones del Administrador.</b> El Administrador solamente responderá ante las condiciones y eventos determinados en el presente contrato y serán específicamente de su cargo las siguientes:<br/>
        <br/>
        <b>7.1</b> La realización de un inventario con el <b>Consignador</b> donde consten las condiciones de entrega del inmueble;<br/>
        <br/>
        <b>7.2</b> La promoción del bien inmueble para buscarle arrendatario.<br/>
        <br/>
        <b>7.3</b> Los daños en el inmueble derivados de problemas estructurales previos, y el deterioro normal causado por el tiempo, son propios del Consignador.<br/>
        <br/>
        <b>7.5</b> Todas aquellas inherentes al presente contrato.<br/>
        <br/>
        <b>8. Otras Obligaciones del Consignador.</b> Además de las previstas en este contrato son de cargo del <b>Consignador</b> las siguientes obligaciones:<br/>
        <br/>
        <b>8.1</b> El pago oportuno de las sumas de dinero o porcentajes acordados y determinados, a favor del Administrador;<br/>
        <br/>
        <b>8.2</b> La entrega oportuna del bien inmueble confiado al <b>Administrador</b>.<br/>
        <br/>
        <b>8.3</b> Evitar cualquier conducta que ponga en peligro la ejecución del contrato de administración;<br/>
        <br/>
        <b>8.4</b> Responder contractual y extracontractualmente por los daños, perjuicios o indemnizaciones que sobrevengan por causa o razón del mal estado del inmueble o de sus instalaciones.<br/>
        <br/>
        <b>9. Cláusula penal.</b> En caso de incumplimiento al presente contrato y en especial en los casos previstos en la cláusula 3.1, el <b>Consignador</b> pagará al <b>Administrador</b> la suma equivalente dos (2) cánones de arrendamiento establecido en la cláusula cinco.<br/>
        <br/>
        <b>10. Anexos.</b> Los siguientes documentos son anexos del contrato y hacen parte integrante de él:<br/>
        <br/>
        <b>10.1</b> CERTIFICADO DE TRADICIÓN DE MATRÍCULA INMOBILIARIA<br/>
        <b>10.2</b> ESCRITURA PÚBLICA<br/>     
        <br/>                                                      
        <b>11. Declaración de Origen de Fondos.</b> El <b>Consignador</b> de manera voluntaria y obrando de buena fe, declara que toda la información suministrada al <b>Administrador</b> para la celebración del presente contrato es cierta, veraz y verificable, y declara que la procedencia de sus bienes es de fuentes lícitas y legales.<br/>
        <br/>
        <b>12. Autorizaciones.</b> En vista de la naturaleza del presente contrato, el <b>Consignador</b> autoriza al <b>Administrador</b> para: (i) ser consultado en centrales de riesgo, y (ii) ser reportado en centrales de riesgo por el incumplimiento de las obligaciones económicas derivadas del presente contrato.<br/>
        <br/>
        <b>13. Riesgos derivados del contrato.</b> El <b>Consignador</b> acepta desde este momento que en virtud de este contrato el Administrador pueda llamarlo en garantía, con base en lo establecido en el artículo 64 del Código General del Proceso.<br/>
        <br/>
        Para constancia se firma por las partes ante notario, en dos (2) ejemplares del mismo valor, a los <b>{formatDate( new Date(), 5 )}</b>.<br/>
        <br/>
        Firman las partes:<br/>
        <br/>
        <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column' style={{width: '40%'}}>
                <b>El Administrador:</b><br/>					
                <br/>
                <br/>
                <br/>
                {data.administratorName}<br/>
                {data.administrator.userID.documentType} No {data.administrator.userID.document}<br/>                                                                                                            
                {data.administrator.userID.phone}<br/>      
                {data.administrator.userID.email}<br/>                                                                                              
            </div>
            <div className='d-flex flex-column' style={{width: '40%'}}>
                <b>El Consignador:</b><br/>		
                <br/>
                <br/>
                <br/>
                {data.ownerName}<br/>
                {data.owner.userID.documentType} No {data.owner.userID.document}<br/>                                                                                                            
                {data.owner.userID.phone}<br/> 
                {data.owner.userID.email}<br/>                                                  
            </div>
        </div>
    </div>
);

const leasingDocument = ( data ) => (
    <div id="capture" style={{textAlign: 'justify', fontSize: '0.8em', lineHeight: 1.2}}>
        <span style={{width: '100%', textAlign: 'center', display: 'block', marginTop: '1rem'}}>CONTRATO DE ARRENDAMIENTO DE VIVIENDA URBANA</span>
        <br/>
        <br/>
        <b>I Partes.</b><br/>
        <br/>
        <b>1. Arrendador.</b><br/>
        <br/>
        {data.administratorName}, mayor de edad, domiciliado(a) y residente en {data.administrator.userID.city} - {data.administrator.userID.province}, identificado con {data.administrator.userID.documentType} No {data.administrator.userID.document} expedido en {data.administrator.userID.documentCity} - {data.administrator.userID.documentProvince}, actuando en nombre y representación del señor {data.ownerName} mayor de edad, domiciliado(a) y residente en la ciudad de {data.owner.userID.city} - {data.owner.userID.province}, identificado(a) con {data.owner.userID.documentType} No {data.owner.userID.document} expedido en {data.owner.userID.documentCity} - {data.owner.userID.documentProvince}, en calidad de propietario del inmueble ubicado en <b>{data.fullAddress}</b> que para efectos del presente contrato se denominará el <b>ARRENDADOR</b>.
        <br/>
        <br/>
        <b>2.  Los (la) Arrendatarios.</b><br/>
        {data.request.name}, mayor de edad, domiciliado y residente en la ciudad de {data.request.actualProperty.actualPropertyCity} - {data.request.actualProperty.actualPropertyProvince}, identificado con {data.request.documentType} No {data.request.document} expedido en {data.request.documentCity} - {data.request.documentProvince}, en lo sucesivo el (los) <b>Arrendatario(s)</b>. 
        <br/>
        <br/>
        <b>II Cláusulas del Contrato.</b><br/>
        Por el presente contrato se hace constar que entre el <b>Arrendador</b> y los <b>Arrendatarios(s)</b> se ha celebrado un Contrato de Arrendamiento con destinación única y exclusivamente de Vivienda Urbana contenido en las siguientes cláusulas:
        <br/>
        <br/>
        <b>1. Objeto del Contrato.</b> Mediante el presente contrato, el <b>Arrendador</b> da en arrendamiento concediendo el goce al <b>Arrendatario(s)</b> y éstos toman en arrendamiento el inmueble que adelante se identifica por su dirección y se describe, de acuerdo con el inventario que las partes realizan y firman por separado, el mismo que hace parte integral de este contrato.
        <br/>
        <br/>
        <b>2. Descripción del Inmueble.</b><br/>
        <br/>
        <b>Dirección:</b><br/>
        {data.fullAddress}<br/>  
        <b>Tipo de bien inmueble:</b><br/>
        {data.propertyType}<br/>
        {data.floorsNumber?
            <span>
                <b>Número de pisos:</b><br/>
                {data.floorsNumber}<br/>
            </span>
        : null}
        {data.realEstateRegistration?
            <span>
                <b>Número de matrícula inmobiliaria:</b><br/>
                {data.realEstateRegistration}<br/>  
            </span>
        : null}                                  
        <br/>                                                             
        <b>Linderos Generales:</b><br/>
        CONTENIDOS EN LA ESCRITURA CORRESPONDIENTE AL INMUEBLE OBJETO DE ALQUILER<br/>
        <br/>
        <b>Linderos Especiales:</b><br/>
        CONTENIDOS EN LA ESCRITURA CORRESPONDIENTE AL INMUEBLE OBJETO DE ALQUILER<br/>
        <br/>
        <b>3. Destinación.</b> Los arrendatarios se comprometen a destinar este inmueble exclusivamente para vivienda y no podrá darle otro uso, pues se entenderán lesionados los derechos del Arrendador. En el evento que esto ocurra el Arrendador puede dar por terminado el arrendamiento y exigir la entrega inmediata del inmueble arrendado haciendo efectiva la indemnización de perjuicios, sin necesidad de requerimiento alguno. Dicho inmueble será ocupado por {data.request.peopleWhoWillLive} personas.
        <br/>
        <br/>
        <b>4. Precio del Arrendamiento.</b> El precio o canon mensual del arrendamiento es de ${data.request.currentContract.monthlyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}. El canon mensual del arrendamiento se pagará de manera íntegra y anticipada por parte los arrendatarios al <b>Arrendador</b> dentro de los cinco (05) primeros días calendario al vencimiento de cada canon o periodo mensual, quedando claramente establecido que los períodos mensuales son indivisibles. 
        <br/>
        <br/>
        <b>4.1 Intereses de mora.</b> Los arrendatarios(s) acepta y se compromete a pagar intereses de mora a la tasa máxima legal sobre el precio mensual del arrendamiento, las sumas no pagadas, sin perjuicio de las demás acciones del <b>Arrendador</b>, si su pago es posterior al lapso antes indicado (artículo 884 del Código de Comercio). Sobre las sumas que a cualquier título en razón del presente contrato resultare a cargo los arrendatarios(s) y que no hubiesen sido pagadas oportunamente, liquidadas desde la fecha de la causación hasta la fecha en que se efectúe el pago. Si el pago se hiciere en un cheque y éste resultare impagado, los arrendatarios(s) pagará además la sanción del veinte por ciento (20%) del valor del cheque, de conformidad con lo dispuesto en el artículo 731 del Código de Comercio.
        <br/>
        <br/>
        <b> 4.2 Fracción de mes para el primer canon.</b> Si el mes correspondiente a la fecha de suscripción del contrato de arrendamiento ya estuviera en curso, las partes pactan que el canon de arrendamiento de dicho mes sea proporcional al número de días que resten para su finalización, de tal forma que en lo sucesivo el pago de los demás cánones de arrendamiento deban ser realizados anticipadamente, dentro de los CINCO (05) primeros días hábiles de cada mes. 
        <br/>
        <br/>
        <b> 4.3</b> La mera tolerancia del <b>Arrendador</b> en aceptar el pago del precio de la renta a los arrendatarios con posterioridad a los cinco (05) primeros días de cada mes calendario, no se entenderá como ánimo de renovar o modificar el término para el pago de este contrato, o la modificación del precio del arrendamiento en ningún caso podrá considerarse como novación o existencia de un contrato verbal de arrendamiento.
        <br/>
        <br/>
        <b> 5. Incrementos del Precio.</b> Vencido el primer año de vigencia de este contrato y así sucesivamente cada doce (12) mensualidades, en caso de prórroga tácita o expresa, en forma automática y sin necesidad de requerimiento alguno entre las partes, el precio mensual del arrendamiento se incrementará en una proporción igual al ciento por ciento (100%) del incremento que haya tenido el índice de precios al consumidor en el año calendario inmediatamente anterior a aquel en que se efectúe el incremento. Al suscribir este contrato los arrendatarios y el deudor solidario quedan plenamente notificados de todos los reajustes automáticos pactados en este contrato y que han de operar durante la vigencia del mismo. Este reajuste se hace obligatorio, aun si el <b>Arrendador</b> ha comunicado al Arrendatario que el contrato no continuará, si los arrendatarios no hacen entrega del inmueble al vencimiento de la renovación o prórroga respectiva (artículo 20 de la Ley 820 de 2003).
        <br/>
        <br/>
        <b> 6. Pago del Arrendamiento.</b> Lugar para el pago. La arrendataria o los arrendatarios pagarán el precio del arrendamiento mediante consignación bancaria en Lugar para el pago. La arrendataria o los arrendatarios pagarán el precio del arrendamiento mediante consignación bancaria en {data.bankAccount.accountType} {data.bankAccount.accountNumber} de {data.bankAccount.bank} a nombre de {data.bankAccount.owner} o en la oficina del ARRENDADOR ubicada como se señala en la cláusula No. 32 de NOTIFICACIONES del presente contrato. La arrendataria de manera expresa y voluntaria, se compromete a entregar mensualmente al Arrendador, fotocopia del volante o volantes de consignación debidamente diligenciados por el cajero de Banco, ya sea personalmente, o escaneado a través de medios magnéticos o electrónicos a <b>{data.administrator.userID.email}</b>.
        <br/>
        <br/>
        <b> 7. Vigencia del Contrato.</b><br/>
        <br/>
        <b>Fecha de iniciación:</b><br/>
        {data.request.currentContract.startDate}<br/>
        <b>Fecha de terminación:</b><br/>
        {data.request.currentContract.endDate}<br/>
        <b> Tiempo total del periodo inicial:</b><br/>
        {data.request.currentContract.totalTime}<br/>
        <br/>
        <b> 8. Prórrogas.</b> Este contrato se entenderá prorrogado en iguales condiciones y por el mismo término inicial, siempre que cada una de las partes haya cumplido con las obligaciones a su cargo y, que los arrendatarios, se avenga a los reajustes de la renta pactados en la cláusula quinta y autorizados en la Ley 820 de 2003 (artículo 6).
        <br/>
        <br/>
        <b> 9. Los servicios públicos de acueducto, energía, aseo y gas domiciliario corren a cargo de los Arrendatarios.</b> 
        <br/>
        <br/>
        <b> 9.1</b> A cargo del <b>Arrendador</b> estarán los siguientes: Predial y Complementarios.
        <br/>
        <br/>
        <b> 9.2 Línea telefónica.</b> Se deja expresa constancia que el inmueble se arrienda SIN línea telefónica, pero sí con servicio de citófono funcionando perfectamente.
        <br/>
        <br/>
        <b> 9.3</b> De manera expresa y voluntaria, los arrendatarios(s) se comprometen a no solicitar la prestación de servicios públicos adicionales y/o suscripciones que pudieran quedar vinculados con el inmueble y/o su propietario (parabólicas, servicios de cable, etc.). El servicio de Internet o de Parabólica que se instalare, queda a cargo exclusivamente los arrendatarios(s)
        <br/>
        <br/>
        <b> 9.4</b> El <b>Arrendador</b> no asume ninguna responsabilidad por el estado, alteraciones o fraudes en los contadores de energía, gas, agua, ni en sus respectivas instalaciones, ni por rompimientos de sellos, ni por la realización de prácticas similares.
        <br/>
        <br/>
        <b> 10. Cosas o Usos Conexos.</b> Además del inmueble identificado y descrito anteriormente tendrá los arrendatarios derecho de goce sobre las siguientes cosas y usos comunes:
        <br/>
        {data.services}
        <br/>
        <br/>
        <b> 11. Cláusula Penal.</b> El incumplimiento o el cumplimiento tardío por parte los arrendatarios(s) de cualquiera de las cláusulas de este contrato, y aun el simple retardo en el pago de una o más mensualidades, lo constituirá en deudor del <b>Arrendador</b> por una suma equivalente al triple del precio mensual de arrendamiento que esté vigente en el momento en que tal incumplimiento se presente a título de pena. Se entenderá, en todo caso, que el pago de la pena no extingue la obligación principal y que el <b>Arrendador</b> podrá pedir a la vez el pago de la pena y la indemnización de perjuicios, si es el caso. Este contrato será prueba sumaria suficiente para el cobro de esta pena y los arrendatarios y sus codeudores renuncian expresamente a cualquier requerimiento privado o judicial para constituirlos en mora del pago de ésta o cualquier otra obligación derivada del contrato (artículos 1594 y 1595 del Código Civil). El presente contrato presta mérito ejecutivo para exigir el pago de la multa estipulada como cláusula penal, los arrendamientos que se adeuden, los servicios públicos, así como cualquier otra suma o cargo los arrendatarios, para lo cual bastará la sola afirmación hecha en la demanda por el <b>Arrendador</b>, que no podrá ser desvirtuada por los arrendatarios, si no con la presentación de los respectivos recibos de pago. 
        <br/>
        <br/>
        <b> 12. Espacios en Blanco.</b> Los <b>Arrendatarios</b> facultan expresamente al <b>Arrendador</b> para llenar en este documento los espacios en blanco, en especial lo relacionado con linderos y No. de matrícula inmobiliaria.
        <br/>
        <br/>
        <b> 13. Requerimientos.</b> El incumplimiento de una cualquiera de las cláusulas estipuladas u obligaciones del contrato a cargo los arrendatarios(s) dará derecho al <b>Arrendador</b> para dar por terminado el presente contrato sin previo aviso y sin necesidad de requerimiento de ninguna clase, al cual renuncian los arrendatarios. El <b>Arrendador</b> podrá además pedir la restitución del inmueble arrendado por la mora en el pago del precio del arrendamiento, el inicial, o el que resulte de aplicar los reajustes, sin que para ello sea necesario ningún tipo de requerimiento o reconvención previos, pues a ellos renuncia expresamente la Arrendataria(s). Los arrendatarios y la (s) deudora (s) solidaria que suscriben este contrato renuncian expresamente a los requerimientos de que tratan los artículos 2007 del Código Civil y 384 del Código General del Proceso y en general a los que consagre cualquier norma sustancial o procesal para efectos de la constitución en mora.
        <br/>
        <br/>
        <b> 14. Preavisos para la Entrega.</b> La Arrendataria(s) podrá dar por terminado unilateralmente el contrato de arrendamiento a la fecha de vencimiento del término inicial o de sus prórrogas, siempre y cuando dé previo aviso por escrito al <b>Arrendador</b> a través del servicio postal certificado y autorizado, con una antelación no menor de tres (3) meses a la referida fecha de vencimiento. La terminación unilateral por parte los arrendatarios(s) en cualquier otro momento sólo se aceptará previo el pago de una indemnización equivalente al precio de tres (3) meses de arrendamiento que esté vigente en el momento de entrega del inmueble. Igualmente el <b>Arrendador</b> dará por terminado el contrato con previo aviso por escrito al arrendatario a través del servicio postal certificado con una antelación no menor de tres (3) meses a la fecha de vencimiento del contrato.
        <br/>
        <br/>
        <b> 15. Causales de Terminación.</b> El Arrendador podrá dar por terminado el presente contrato por los siguientes motivos:
        <br/>
        <br/>
        <b>15.1</b> El subarriendo total o parcial del inmueble, la cesión del contrato o del goce del inmueble o el cambio de destinación total o parcial del mismo por parte de los <b>Arrendatarios</b>, sin expresa autorización del <b>Arrendador</b> (artículo 17 de la Ley 820 de 2003).<br/>
        <b>15.2</b> El no pago del precio y sus reajustes dentro del término previsto en este contrato; <br/>
        <b>15.3</b> La destinación del inmueble para fines ilícitos o contrario a las buenas costumbres, o que representen peligro para el inmueble o la salubridad de sus habitantes; <br/>
        <b>15.4</b> La realización de mejoras, adiciones, cambios o ampliaciones del inmueble, sin expresa autorización del Arrendador, o porque lo destruya total o parcialmente; <br/>
        <b>15.5</b> La no cancelación de los servicios públicos, que cause la desconexión o pérdida del servicio, o el no pago de las expensas comunes cuando su pago estuviere a cargo los arrendatarios(s).<br/>
        <b>15.6</b> Cuando los arrendatarios(s) reiteradamente afecte la tranquilidad de los vecinos o destine el inmueble para actos delictivos o que impliquen contravención. <br/>
        <b>15.7</b> Cuando los arrendatarios(s) viole las normas del respectivo reglamento interno o de propiedad horizontal (numeral 4, artículo 9 de la Ley 820 de 2003), si aplica.<br/>
        <b>15.8</b> Cuando el propietario o poseedor necesite el inmueble para ocuparlo o cuando el inmueble haya de demolerse para efectuar una nueva construcción, o cuando se requiera desocuparlo con el fin de ejecutar obras indispensables para su reparación. <br/>
        <b>15.9</b> Las demás previstas en la ley.<br/>
        <br/>
        <b> 16. Cesión de los Derechos.</b> Los arrendatarios no podrá ceder el presente contrato ni subarrendar el inmueble total o parcialmente, sin previa autorización escrita del Arrendador, bajo pena de que éste, a su arbitrio, pueda dar por terminado el arrendamiento y exigir la entrega inmediata del inmueble arrendado sin necesidad de requerimientos de cualquier clase, a los cuales renuncia los arrendatarios. Si se autoriza la cesión o subarriendo continuarán vigentes todas las estipulaciones derivadas de este contrato solidariamente, entre los arrendatarios y los cesionarios o subarrendatarios. Estipulan expresamente los contratantes que este contrato no formará parte integral de ningún establecimiento de comercio y que, por lo tanto, la enajenación del que eventualmente se establezca en el inmueble no sólo no transfiere ningún derecho de arrendamiento al adquirente, sino que constituye causal de terminación del contrato, toda vez que los arrendatarios(s) se obligan expresamente a no ceder, a no subarrendar el inmueble, ni transferir su tenencia. Para los efectos legales, esta estipulación equivale a la oposición a que se refiere el numeral 3 del artículo 528 del Código de Comercio, de tal suerte que la responsabilidad los arrendatarios(s) no cesará con la enajenación del establecimiento, ni con el aviso de la transferencia, ni aun con la inscripción de la enajenación en el Registro Mercantil (artículo 17 de la Ley 820 de 2003). 
        <br/>
        <br/>
        <b> 16.1</b> Podrá el <b>Arrendador</b> ceder libremente los derechos que emanan de este contrato y tal cesión producirá efectos respecto los arrendatarios(s) y de los codeudores solidarios a partir de la fecha de la comunicación certificada en que a ellos se notifique tal cesión, todo lo que desde este momento queda expresa y plenamente aceptado por los arrendatarios(s).
        <br/>
        <br/>
        <b> 16.2</b> Acepta los arrendatarios(s) que la notificación de la cesión se surta con el solo envío, por parte del cedente o el cesionario de la nota de cesión acompañada de copia simple del contrato, por servicio postal autorizado, a la dirección del inmueble arrendado o a la dirección de uno cualquiera de los deudores solidarios, individualmente considerados. 
        <br/>
        <br/>
        <b> 17. Recibo y Estado.</b> Los <b>Arrendatarios</b> declaran que han recibido el inmueble objeto de este contrato en buen estado, con todas sus instalaciones eléctricas, sanitarias, hidráulicas, de gas, telefónicas y estructurales en perfecto estado de funcionamiento conforme con el inventario que hace parte integral del mismo, y que en el mismo estado lo restituirá al <b>Arrendador</b> a la terminación del arrendamiento, o cuando éste haya de cesar por alguna de las causales previstas, salvo el deterioro proveniente del tiempo y del uso legítimo para efectos de la restitución anterior  Los arrendatarios(s) hará todas las reparaciones, pinturas, retoques y reemplazos que sean necesarios para que el inmueble se entregue al <b>Arrendador</b> en las mismas perfectas condiciones de uso y conservación en que lo recibió La Arrendataria. Esta obligación se cumplirá en la fecha en que se haga entrega del inmueble al <b>Arrendador</b>, los arrendatarios(s) se obligan a efectuar la restitución del inmueble libre de subarriendos o de ocupaciones de cualquier clase.
        <br/>
        <br/>
        <b> Parágrafo primero.</b> Formará parte integral del presente contrato el inventario del bien inmueble realizado por el <b>Arrendador</b> en presencia de los arrendatarios(s).
        <br/>
        <br/>
        <b> 18. Mejoras.</b> No podrán los arrendatarios ejecutar en el inmueble mejoras de ninguna especie, excepto las reparaciones locativas, sin el permiso escrito del Arrendador. Si se ejecutaren accederán al propietario del inmueble sin indemnización para quien las efectuó. La Arrendataria(s) renuncian expresamente a descontar de la renta el valor de las reparaciones indispensables, a que se refiere el artículo 27 de la Ley 820 de 2003.
        <br/>
        <br/>
        <b> 19. Construcción, distribución y estado del inmueble.</b> Los arrendatarios no podrán modificar la construcción y distribución arquitectónica del inmueble arrendado, sin la autorización expresa, escrita y previa del <b>Arrendador</b>. En caso de que contraviniere esta prohibición deberán demoler las obras y hacer las reconstrucciones necesarias para restablecer la construcción a su estado original, salvo que el <b>Arrendador</b> acepte quedarse con ellas, sin que haya lugar al pago de ningún valor o indemnización. Esta obligación deberá cumplirse en la fecha de entrega del inmueble. En consecuencia, el <b>Arrendador</b> no queda obligado a pagar tales mejoras o reformas ni a indemnizar en forma alguna al <b>Arrendatario</b> (s) aún en los casos en que autorizado expresamente, ni los arrendatarios(s) podrá separar o llevarse los materiales utilizados. Queda expresamente estipulado que cualquier cerradura o implemento adicional que los arrendatarios(s) instale en las puertas o ventanas interiores o exteriores del inmueble no las podrá retirar y quedarán de propiedad del <b>Arrendador</b>, sin que haya lugar a reconocimiento de suma alguna por este concepto (numeral 2, artículo 9 de la Ley 820 de 2003). 
        <br/>
        <br/>
        <b>Parágrafo primero</b>. Si los arrendatarios por alguna circunstancia efectuarán mejoras en el inmueble objeto de este contrato de arrendamiento, no obstante la prohibición contenida en esta cláusula y tales mejoras a juicio del <b>Arrendador</b> constituyeran una depreciación para el inmueble, los arrendatarios(s) se obliga a retirarlas y a entregar el bien en el estado en que lo recibió. Si los arrendatarios no dieran cumplimiento a dicha obligación, la depreciación del inmueble será estimada parcialmente y su valor será exigible por la vía ejecutiva. <br/>
        <br/>
        <b>Parágrafo segundo</b>. No obstante lo dispuesto en esta cláusula la Arrendataria(s) estará obligada a efectuar las reparaciones locativas, asimismo, está obligado expresamente en los términos señalados en los artículos 2029 y 2030 del Código Civil. <br/>
        <br/>
        <b>Parágrafo tercero</b>. Los arrendatarios se obligan expresamente a informar por escrito al <b>Arrendador</b> los daños presentados en el inmueble, cuyas reparaciones no estén a su cargo. En ningún caso podrá descontar, sin autorización expresa del <b>Arrendador</b>, el costo de reparaciones indispensables, no locativas que haya efectuado en el inmueble. <br/>
        <br/>
        <b>Parágrafo cuarto.</b> En caso de existir reforma que implique demolición de mampostería o cambio de algún elemento que implique reforma de la estructura del bien inmueble, los arrendatarios(s) deberá contar con un visto bueno del propietario del inmueble en conjunto con el asesor (Ingeniero Civil) que el propietario destine, por conducto del <b>Arrendador</b>. <br/>
        <br/>
        <b> 20. Conservación y restitución del inmueble, sus instalaciones y accesorios.</b> Los arrendatarios(s) tendrá a su cargo exclusivo el pago de las reparaciones de los daños y/o averías que se ocasionen al inmueble y a sus instalaciones y accesorios, por el uso que los arrendatarios(s) haga de estos bienes, pues su obligación esencial es la de conservarlos y restituirlos en el mismo buen estado en que los ha recibido.
        <br/>
        <br/>
        <b> 21. Extinción del derecho del Arrendador.</b> Si el arrendamiento expiraré por la extinción del derecho del <b>Arrendador</b> sobre el inmueble arrendado, aunque tal extinción fuere ocasionada por hechos del <b>Arrendador</b>, éste no estará obligado a indemnizar al Arrendatario (s)  aun en los casos en que la persona que le suceda en el derecho no esté obligada a respetar el arrendamiento.
        <br/>
        <br/>
        <b> 22. Gastos.</b> Los gastos que cause este contrato, corresponden a los arrendatarios, los de su prórroga o su renovación, llegado el caso.
        <br/>
        <br/>
        <b> 23. Codeudores (as) Solidarios (as)</b> {data.request.cosigners.map((cosigner, index) =>(
            <span key={index}>{cosigner.fullName}, mayor de edad, domiciliado en la ciudad de {cosigner.cosignerCity} - {cosigner.cosignerProvince} identificado como aparece al pie de su respectiva firma {((index+1)!==data.request.cosigners.length)? ' y ': ''}</span>
        ))} {data.request.cosigners.length > 1? ', todos hábiles': 'hábil'} para contratar y obligarse,  por medio del presente documento nos declaramos  deudores del (los) <b>Arrendatario</b> (s) en forma solidaria e indivisible de todas las cargas y obligaciones contenidas en el presente contrato, tanto durante el término inicialmente pactado como durante sus prórrogas o renovaciones expresas o tácitas y hasta la restitución real del inmueble al <b>Arrendador</b>, por concepto de: arrendamientos, servicios públicos, indemnizaciones, daños en el inmueble, cláusulas penales, costas procesales y cualquier otra derivada del contrato, las cuales podrán ser exigidas por el <b>Arrendador</b> a cualquiera de los obligados, por la vía ejecutiva, sin necesidad de requerimientos privados o judiciales a los cuales renunciamos expresamente, sin que por razón de esta solidaridad asumamos el carácter de fiadores, ni arrendatarios del inmueble objeto del presente contrato, pues tal calidad la asume exclusivamente los <b>Arrendatarios</b> y sus respectivos causahabientes. Todo lo anterior sin perjuicio de que en caso de abandono del inmueble el deudor solidario pueda hacer entrega válidamente del inmueble al <b>Arrendador</b> o a quien éste señale, bien sea judicial o extrajudicialmente. Para este exclusivo efecto los <b>Arrendatarios</b> otorgan poder amplio y suficiente a los deudores solidarios en este mismo acto y al suscribirse el presente contrato (artículo 7 de la Ley 820 de 2003).
        <br/>
        <br/>
        <b> 24. Cesión del Contrato.</b> La deudora solidaria acepta expresamente desde ahora cualquier cesión que el <b>Arrendador</b> haga respecto del presente contrato y ratifica su voluntad de que la notificación de que trata el artículo 1960 del Código Civil se surta con el solo envío, por parte del cesionario de la nota de cesión acompañada de copia simple del contrato, por servicio postal autorizado, a la dirección que aparece registrada en este contrato al pie de su respectiva firma, mientras no informe válidamente -por escrito- al <b>Arrendador</b> de otra.
        <br/>
        <br/>
        <b> 24.1</b> Los deudores solidarios aceptan expresamente desde este momento, que si por alguna razón el lugar de su dirección hubiere variado, la notificación se surta con el solo envío, por parte del Cesionario de la nota de cesión acompañada de copia simple del contrato, por servicio postal autorizado, a la Dirección del inmueble arrendado, o a la dirección de uno cualquiera de ellos individualmente considerados.
        <br/>
        <br/>
        <b> 25. Devolución satisfactoria:</b> Cuando este contrato termine por cualquier causa, los arrendatarios(s) deberá entregar el inmueble arrendado al <b>Arrendador</b> a entera satisfacción, pintado y en las mismas condiciones en que fue recibido y de conformidad con el inventario que se anexa a este contrato, entrega que no se entenderá cumplida mientras no se haya acreditado la cancelación de los cánones de arrendamiento y que se hubieren causado a cargo de los arrendatarios hasta la fecha de la respectiva entrega.
        <br/>
        <br/>
        <b> 26. Autorizaciones.</b> El  <b>Arrendatario</b> (s) y los  deudores solidarios autorizan de manera expresa e irrevocable al <b>Arrendador</b> o a quien represente sus derechos u ostente en el futuro la calidad de acreedor, para que en el evento en que se constituya en mora en el pago de cualquier servicio público, arrendamiento o cualquier otro concepto que sea a su cargo, durante el término inicial o el de sus prórrogas o a la terminación del contrato, se incorpore, reporte, procese, y consulte su nombre, apellido y documento de identificación, y la información que se relacione con este contrato o que de él se derive, en los bancos de datos o los archivos de deudores morosos o referencias negativas que lleve Cifin, Datacrédito, Lonja de Propiedad Raíz de Cali y el Valle del Cauca, o cualquier otra entidad encargada del manejo de datos comerciales, personales o económicos. Los arrendatarios(s) exoneran de toda responsabilidad la inclusión de tales datos tanto al <b>Arrendador</b>, como a la entidad que produzca el correspondiente archivo.
        <br/>
        <br/>
        <b> 27. Exención de Responsabilidad:</b> El <b>Arrendador</b> no asume responsabilidad alguna por los daños o perjuicios que la Arrendataria(s) pueda sufrir por causas atribuibles a terceros u otros arrendatarios, dependientes, o residentes del mismo sector o a culpa leve del <b>Arrendador</b>, ni por hurtos, ni por siniestros causados por incendio, inundación, o terrorismo. Los arrendatarios asume la responsabilidad por los daños que se puedan causar al inmueble o los enseres o dotaciones de los vecinos y terceros, cuando éstos provengan o sean causados por los arrendatarios por su descuido o negligencia, tales como dejar abiertas las llaves del agua, dejar conectados aparatos eléctricos o las luces prendidas, etc.
        <br/>
        <br/>
        <b> 28. Abandono del Inmueble.</b> Al suscribir este contrato los arrendatarios(s) faculta expresamente al <b>Arrendador</b> para penetrar en el inmueble y recuperar su tenencia, con el solo requisito de la presencia de dos (2) testigos, en procura de evitar el deterioro o el desmantelamiento de tal inmueble, siempre que por cualquier circunstancia el mismo permanezca abandonado o deshabitado por el término de un (1) mes o que amenace la integridad física del bien o la seguridad del vecindario. La misma facultad tendrán los deudores solidarios en caso de abandono del inmueble para efectos de restituirlo al <b>Arrendador</b>.
        <br/>
        <br/>
        <b> 29. Copia del contrato:</b> Los arrendatarios(s) y los deudores solidarios manifiestan que han recibido copia del presente contrato a satisfacción, con las firmas originales.
        <br/>
        <br/>
        <b> 30. Cobro extrajudicial.</b> Si el incumplimiento a la obligación de pagar oportunamente el arriendo o los servicios dieren lugar a alguna diligencia de cobro extrajudicial, los arrendatarios(s) se obligan a pagar solidariamente, por concepto de honorarios al abogado encargado de dicho cobro extrajudicial hasta una suma igual al valor vigente del canon de arrendamiento por cada gestión, suma está exigible ejecutivamente por ser clara, expresa, exigible y a su cargo, sin perjuicio de las demás acciones por incumplimiento o por mora pactadas en este contrato y sin necesidad de requerimiento alguno. 
        <br/>
        <br/>
        <b> 31. Validez.</b> El presente contrato no producirá ningún efecto sin la firma del Arrendador.
        <br/>
        <br/>
        <b> 32. Notificaciones.</b> Los arrendatarios(s) suministran, como dirección para recibir notificaciones relacionadas con este contrato de arrendamiento, la de {data.request.actualProperty.fullAddress}, correo {data.request.email}, para todos los efectos relacionados con este contrato la dirección del <b>Arrendador</b> es Calle 15 N No. 6 N - 34 Oficina 401 Edificio Alcázar, Santiago de Cali - Valle del Cauca – Colombia, al correo electrónico {data.administrator.userID.email} (artículo 12 de la Ley 820 de 2003). Los deudores solidarios: {data.request.cosigners.map((cosigner, index) =>(
            <span key={index}>{cosigner.fullName}, dirección {cosigner.fullAddress}, correo {cosigner.email} {((index+1)!==data.request.cosigners.length)? ' y ': '.'}</span>
        ))}
        <br/>
        <br/>
        <b> III Cláusulas Adicionales</b> 
        <br/>
        <br/>
        <br/>
        <br/>
        Enteradas las partes del contenido y alcance del presente contrato, lo firman de conformidad con lo establecido en la Ley 527 de 1999, y reconocen su valor probatorio para efectos legales. 
        <br/>
        <br/>
        <div className='d-flex justify-content-between flex-wrap'>
            <div className='d-flex flex-column mt-3' style={{width: '40%'}}>
                <b>EL ARRENDADOR:</b><br/>					
                <br/>
                <br/>
                <br/>
                {data.administratorName}<br/>
                {data.administrator.userID.documentType} No {data.administrator.userID.document}<br/>                                                                                                            
                {data.administrator.userID.email}<br/>                                                  
            </div>
            {data.request.cosigners.map((cosigner, index) =>(
                <div key={index} className='d-flex flex-column mt-3' style={{width: '40%'}}>
                    <b>DEUDORES SOLIDARIOS:</b><br/>		
                    <br/>
                    <br/>
                    <br/>
                    {cosigner.fullName}<br/>
                    {cosigner.documentType} No {cosigner.document}<br/>                                                                                                            
                    {cosigner.email}<br/> 
                </div>
            ))}
            <div className='d-flex flex-column mt-3' style={{width: '40%'}}>
                <b>EL ARRENDATARIO:</b><br/>					
                <br/>
                <br/>
                <br/>
                {data.request.name}<br/>
                {data.request.documentType} No {data.request.document}<br/>                                                                                                            
                {data.request.email}<br/>                                                  
            </div>
        </div>
    </div>
);

const incrementDocument = ( data ) => (
    <div id="capture" style={{display: 'flex', flexDirection: 'column', textAlign: 'justify', fontSize: '0.8em', lineHeight: 1.2}}>
        <span style={{width: '100%'}}>{formatDate(new Date(), 3)}</span>
        <span style={{width: '100%'}}>Cali, Valle del Cauca</span>
        <br/>
        <br/>
        Señor(a)<br/>
        <b>{data.request.name}</b>
        <b>{data.ubication}</b>
        <b>{data.cityText}</b>
        <br/>
        <span>{`    Asunto: `}<b>AUMENTO CANON DE ARRENDAMIENTO {data.address.toLocaleUpperCase()}</b></span>
        <br/>
        <br/>
        Apreciado señor(a)<br/>
        <p>
            Por medio del presente escrito me permito comunicarle que a partir del {formatDate(data.increment.startDate, 3)} el arrendamiento sobre el
            inmueble tendrá un incremento del {data.increment.percentage}% sobre el valor
            del canon de arrendamiento, que será aumentado por el valor de {toCurrency(data.increment.newValue - data.increment.previousValue)} de conformidad con el numeral 5 del contrato
            parágrafo único, es decir que el total a pagar corresponde a {toCurrency(data.increment.newValue)}.
            Lo anterior está permitido teniendo en cuenta lo establecido en la Ley 820 de 2003, en
            donde se dispone el régimen de arrendamiento de vivienda urbana, en su art. 20
            menciona que se puede realizar un reajuste del canon de arrendamiento:
        </p>
        <p style={{width: '90%', margin: 'auto', fontStyle: 'italic'}}>
            “Cada doce (12) meses de ejecución del contrato bajo un mismo precio, el
            arrendador podrá incrementar el canon hasta en una proporción que no sea
            superior al ciento por ciento (100%) del incremento que haya tenido el
            índice de precios al consumidor en el año calendario inmediatamente
            anterior a aquél en que deba efectuarse el reajuste del canon, siempre y
            cuando el nuevo canon no exceda lo previsto en el artículo 18 de la
            presente ley.”
        </p>
        <br/>
        <br/>
        Agradeciendo la atención prestada,<br/>
        <br/>
        <br/>
        Atentamente,<br/>
        <br/>
        <br/>
        <br/>
        {data.administratorName}<br/>
        {data.administrator.userID.documentType} No {data.administrator.userID.document}<br/>                                                                                                            
        {data.administrator.userID.email}<br/>   
    </div>
);