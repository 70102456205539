export const contractsTableHeaders = [
    "Inquilino",
    "Mensualidad",
    "Inicio",
    "Contrato",
    "Finalización",
    "Estado",
];

export const contractsTableBody = [
    {
        data: "tenant",
        classname: ""
    },
    {
        data: "monthlyCostText",
        classname: ""
    },
    {
        data: "formattedStartDate",
        classname: ""
    },
    {
        data: "agreementTime",
        classname: ""
    },
    {
        data: "formattedFinishDate",
        classname: ""
    },
    {
        data: "status",
        classname: ""
    },
];