import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { Link, useNavigate  } from 'react-router-dom';
import { administrationValues, agreementTimes, notificationTimes, notaries, propertyTypes } from './create-property-data';
import DatePicker from 'react-date-picker';
import { searchQuery } from '../../actions/global-actions';
import { createProperty } from '../../actions/property-actions';
import { provinces } from '../../actions/global-actions-data';

function CreateProperty(){
    const currentLink = "/inmuebles";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.user);

    const [form, setForm] = useState({
        propertyType: '',
        address: '',
        neighborhood: '',
        province: '',
        city: '',
        description: '',
        floorsNumber: '',
        monthCost: '',
        hasAdministrationFee: false,
        feeIncluded: false,
        administrationCost: '',
        realEstateRegistration: '',
        propertyNumber: '',
        publicDeedNumber: '',
        publicDeedDate: '',
        notary: '',
        servicesBill: '',
        gasBill: '',
        managementTime: '',
        managementPercentage : 10,
        owner: {
            userID: ''
        },
        ownerText: '',
        administrator: {
            userID: ''
        },
        administratorText: '',
        minimumNotificationTime: '',
        bankAccount: {
            bank: '',
            accountType: '',
            accountNumber: '',
            owner: '',
        },
    });

    const [dropdown, setDropdown] = useState({
        show: false,
        value: '',
        data: []
    })

    const [citiesData, setCitiesData] = useState({
        showCities: false,
        cities: []
    });

    const [accounts, setAccounts] = useState([]);

    const handleInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name] :  event.target.value
        })
    }

    const handleCheckChange = (event) => {
        setForm({
            ...form,
            [event.target.name] :  event.target.checked
        })
    }

    const handleSearchChange = (event) => {
        setForm({
            ...form,
            [event.target.name] :  event.target.value
        })
        if (event.target.value.length > 2) {
            dispatch( searchQuery( event.target.id, event.target.value, searchFunction, event.target.name, user.token ) );
        } else {
            setDropdown({ show: false, value: '', data: [] });
        }
    }

    const searchFunction = ( link, data ) => {
        if(data.code === "01"){
            Swal.fire({
                title:"Error",
                confirmButtonColor:"#76b4ff",
                text: data.message
            });
        }else{
            console.log(data);
            setDropdown({ show: true, value: link, data: data.data });
        }
    }

    const setOwner = ( data ) => {
        setForm({
            ...form,
            owner: {
                userID: data._id
            },
            ownerText: data.firstName + ' ' + data.lastName + ' ' + data.documentType + ': ' + data.document,
        })
        setDropdown({ show: false, value: '', data: [] });
    }

    const setAdministrator = ( data ) => {
        setAccounts([...data.bankAccount]);
        setForm({
            ...form,
            administrator: {
                userID: data._id
            },
            administratorText: data.firstName + ' ' + data.lastName + ' ' + data.documentType + ': ' + data.document,
        })
        setDropdown({ show: false, value: '', data: [] });
    }

    const handleAccountChange = (event) => {
        setForm({
            ...form,
            [event.target.name] :  JSON.parse(event.target.value)
        })
    }

    const handleOnChange = (event) => {
        const value = event.target.value;
        if (value !== '' && value) {
            const cities = provinces.find( x => x.name === value).cities;            
            setCitiesData({
                showCities: true,
                cities
            })
            setForm({
                ...form,
                [event.target.name]: event.target.value
            })
        } else {
            setCitiesData({
                showCities: false,
                cities: []
            })
            setForm({
                ...form,
                city :  '',
                province: ''
            })
        }
    }

    const handleDateChange = ( name, date ) => {
        setForm({
            ...form,
            [name] :  date
        })
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const isEmpty = validateFields();
        if (isEmpty) {
            Swal.fire({
                title:"¡Atención!",
                confirmButtonColor:"#76b4ff",
                text:"Debe llenar todos los datos para continuar",
            });
            return;
        } else {
            if (form.bankAccount.bank === '') {
                Swal.fire({
                    title:"¡Atención!",
                    confirmButtonColor:"#76b4ff",
                    text:"Debe llenar todos los datos para continuar",
                });
                return;
            }
            dispatch( createProperty( form, createFunction, user.token ) );
        }
    }

    const validateFields = () => {
        for (const field in form) {
            if (form.hasOwnProperty(field)) {
                //console.log(`${field}: ${form[field]}`);
                if ( field !== 'administrationCost' && field !== 'floorsNumber' && field !== 'realEstateRegistration' && field !== 'propertyNumber' 
                    && field !== 'publicDeedNumber' && field !== 'publicDeedDate' && field !== 'notary' && field !== 'servicesBill' && field !== 'gasBill' 
                    && form[field] === '' ) {
                    return true
                }
            }
        }
        return false
    }

    const createFunction = (data) => {
        if(data.code === "01"){
            Swal.fire({
                title:"Error",
                confirmButtonColor:"#76b4ff",
                text: data.message
            });
        }else{
            navigate(`${currentLink}/editar/${data.data._id}`)
            //console.log(data);
            Swal.fire({
                title:"¡Éxito!",
                confirmButtonColor:"#76b4ff",
                text: "Inmueble creado con éxito"
            });
        }
    }

    useEffect(() => {
        //console.log('form', form)
        //console.log('accounts', accounts)
    }, [form, accounts]);

    return(
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <div className="w-100 mt-5">
                    <Link to={currentLink} className="back-button">&#60; Volver</Link>
                </div>
                <h1 className="my-5">Ficha inicial para nuevo inmueble</h1>
                <form onSubmit={handleOnSubmit}>
                    <div className="d-flex w-100 flex-wrap">
                        <h4 className="w-100 my-2">Datos generales</h4>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="propertyType">Tipo de propiedad</label>
                            <select className="input-field" id="propertyType" name="propertyType" onChange={handleInputChange}>
                                <option></option>
                                {propertyTypes.map((type, index) => (
                                    <option key={`${type}${index}`} value={type}>{type}</option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="province">Departamento</label>
                            <select className="input-field" id="province" name="province" onChange={handleOnChange}>
                                <option></option>
                                {provinces.map((province) => (
                                    <option key={province.name} value={province.name}>{province.name}</option>
                                ))}
                            </select>
                        </div>
                        {citiesData.showCities && citiesData.cities !== []? 
                            <div className="d-flex flex-column w-25 field">
                                <label className="label-field" htmlFor="city">Ciudad</label>
                                <select className="input-field" id="city" name="city" onChange={handleInputChange}>
                                    <option></option>
                                    {citiesData.cities.map((city) => (
                                        <option key={city.name} value={city.name}>{city.name}</option>
                                    ))}
                                </select>
                            </div>
                        :''}
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="address">Dirección</label>
                            <input id="address" name="address" autoComplete="off" maxLength={180} className="input-field" type="text" onChange={handleInputChange}></input>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="neighborhood">Barrio</label>
                            <input id="neighborhood" name="neighborhood" autoComplete="off" maxLength={60} className="input-field" type="text" onChange={handleInputChange}></input>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="floorsNumber">Número de pisos (Opcional) </label>
                            <input id="floorsNumber" name="floorsNumber" autoComplete="off" type='number' min="0"  className="input-field" onChange={handleInputChange}></input>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="monthCost">Valor del canon mensual</label>
                            <input id="monthCost" name="monthCost" autoComplete="off" type='number' min="0" className="input-field" onChange={handleInputChange}></input>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor='hasAdministrationFee'>¿Tiene costo de administración?</label>
                            <input type='checkbox' id="hasAdministrationFee" name="hasAdministrationFee" onChange={handleCheckChange}></input>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor='feeIncluded'>¿Está incluida?</label>
                            <input type='checkbox' id="feeIncluded" name="feeIncluded" onChange={handleCheckChange}></input>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="administrationCost">Valor de la administración (Opcional)</label>
                            <input id="administrationCost" name="administrationCost" autoComplete="off" type='number' className="input-field" onChange={handleInputChange}></input>
                        </div>
                        <div className="d-flex flex-column w-100 field">
                            <label className="label-field" htmlFor='description'>Breve descripción del predio</label>
                            <textarea id='description' name='description' placeholder='' autoComplete="off" rows={5} cols={5} className="input-field" type="text" onChange={handleInputChange}></textarea>
                        </div>
                        <h4 className="w-100 my-2">Datos particulares</h4>
                        <span className="span-field field w-100">Los datos a continuación son opcionales, pero tenga en cuenta que son necesarios para la generación de los documentos</span>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="realEstateRegistration">Número de registro inmobiliario</label>
                            <input id="realEstateRegistration" name="realEstateRegistration" autoComplete="off"  maxLength={70} className="input-field" type="text" onChange={handleInputChange}></input>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="propertyNumber">Número predial</label>
                            <input id="propertyNumber" name="propertyNumber" autoComplete="off"  maxLength={70} className="input-field" type="text" onChange={handleInputChange}></input>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="publicDeedNumber">Número de escritura pública</label>
                            <input id="publicDeedNumber" name="publicDeedNumber" autoComplete="off"  maxLength={70} className="input-field" type="text" onChange={handleInputChange}></input>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field">Fecha de escritura pública</label>
                            <DatePicker
                                value={ form.publicDeedDate }
                                dayAriaLabel={"Día"}
                                dayPlaceholder={"dd"}
                                monthAriaLabel={"Mes"}
                                monthPlaceholder={"mm"}
                                yearAriaLabel={"Año"}
                                yearPlaceholder={"yyyy"}
                                name={"date"}
                                onChange={ (date)=>handleDateChange( 'publicDeedDate', date ) }
                                dateFormat="MM/dd/yyyy"
                            />
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="notary">Notaría</label>
                            <select className="input-field" id="notary" name="notary" onChange={handleInputChange}>
                                <option></option>
                                {notaries.map((notary, index) => (
                                    <option key={`${notary.name}${index}`} value={notary.name}>{notary.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="servicesBill">Factura de servicios públicos</label>
                            <input id="servicesBill" name="servicesBill" autoComplete="off" placeholder='Número de factura'  maxLength={70} className="input-field" type="text" onChange={handleInputChange}></input>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="gasBill">Factura del gas</label>
                            <input id="gasBill" name="gasBill" autoComplete="off" placeholder='Número de factura'  maxLength={70} className="input-field" type="text" onChange={handleInputChange}></input>
                        </div>
                        <h4 className="w-100 my-2">Datos de administración</h4>
                        <div className="d-flex flex-column w-25 field position-relative">
                            <label className="label-field" htmlFor="owner">Propietario del inmueble</label>
                            <input type="text" autoComplete='off' className="input-field" id="user" placeholder="Buscar por nombres, apellidos o documento" onChange={handleSearchChange} name="ownerText" value={form.ownerText}></input>
                            <div className={`dropdown-container ${(dropdown.show && dropdown.value === 'ownerText') ? "active" : ""}`}>
                                { dropdown.data.length > 0 ?
                                    dropdown.data.map((option, index) => (
                                    <div key={index} className="dropdown-item-container" onClick={() => setOwner(option)}>{option.firstName} {option.lastName} {option.documentType} {option.document}</div>
                                    ))
                                :
                                    <span className="dropdown-message-item">Sin resultados</span>
                                }
                            </div>
                        </div>
                        <div className="d-flex flex-column w-25 field position-relative">
                            <label className="label-field" htmlFor="administrator">Persona que lo administra</label>
                            <input type="text" autoComplete='off' className="input-field" id="user" placeholder="Buscar por nombres, apellidos o documento" onChange={handleSearchChange} name="administratorText" value={form.administratorText}></input>
                            <div className={`dropdown-container ${(dropdown.show && dropdown.value === 'administratorText') ? "active" : ""}`}>
                                { dropdown.data.length > 0 ?
                                    dropdown.data.map((option, index) => (
                                    <div key={index} className="dropdown-item-container" onClick={() => setAdministrator(option)}>{option.firstName} {option.lastName} {option.documentType} {option.document}</div>
                                    ))
                                :
                                    <span className="dropdown-message-item">Sin resultados</span>
                                }
                            </div>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="managementTime">Tiempo de contrato de administración</label>
                            <select className="input-field" id="managementTime" name="managementTime" onChange={handleInputChange}>
                                <option></option>
                                {agreementTimes.map((type, index) => (
                                    <option key={`${type}${index}`} value={type}>{type}</option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="managementPercentage">&#37; Administración</label>
                            <select className="input-field" id="managementPercentage" name="managementPercentage" onChange={handleInputChange} value={form.managementPercentage}>
                                <option></option>
                                {administrationValues.map((value, index) => (
                                    <option key={`${value.value}${index}`} value={value.value}>{value.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="minimumNotificationTime">Tiempo mínimo de notifiación</label>
                            <select className="input-field" id="minimumNotificationTime" name="minimumNotificationTime" onChange={handleInputChange}>
                                <option></option>
                                {notificationTimes.map((time, index) => (
                                    <option key={`${time}${index}`} value={time}>{time}</option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex flex-column w-25 field">
                            <label className="label-field" htmlFor="bankAccount">Cuenta a la cual se debe pagar</label>
                            <select className="input-field" id="bankAccount" name="bankAccount" onChange={handleAccountChange}>
                                <option></option>
                                {accounts.map((account, index) => (
                                    <option key={`${account}${index}`} value={JSON.stringify(account)}>No. {account.accountNumber} {account.accountType} - {account.bank}  </option>
                                ))}
                            </select>
                        </div>
                        <div className="w-100 d-flex d-flex justify-content-center my-4">
                            <button className="primary-button save-button" type="submit">Guardar &#43;</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateProperty;
  