import {
    QUERY_IS_LOADING,
    QUERY_NOT_LOADING
} from '../types';

const initialState = {
    queryIsLoading:false,
}

export default function globalReducer(state = initialState, action) {
    switch(action.type) {
        case QUERY_IS_LOADING:
            return {
                ...state,
                queryIsLoading:true
            }
        case QUERY_NOT_LOADING:
            return {
                ...state,
                queryIsLoading:false
            }
        default:
            return state;
    }
}