import Swal from "sweetalert2";

const alert = (title, message) => {
    if (Swal.isVisible()) {
        Swal.update({
            title,
            confirmButtonColor:"#76b4ff",
            text: message
        });
        return;
    }
    Swal.fire({
        title,
        confirmButtonColor:"#76b4ff",
        text: message
    });
    return;
}

export default alert