import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { Link, useSearchParams } from 'react-router-dom';
import { getUsers } from '../../actions/user-actions';
import { searchListQuery } from '../../actions/global-actions';
import Paginator from '../../components/paginator/paginator';
import { setSkipValue } from '../../components/setSkipValue';
import TableComponent from '../../components/table/table';
import { usersTableBody, usersTableHeaders } from './users-data';

function UsersList(){
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [users, setUsers] = useState([]);
    const [loadData, setLoadData] = useState(true);
    const user = useSelector(state => state.user.user);
    const currentLink = "/usuarios";
    const [paginatorData, setPaginatorData] = useState({
        totalitems: 0,
        totalpages: 0,
        currentpage: 0
    });
    const [searchData, setSearchData] = useState( searchParams.get("q") || '' );
    const [skip, setSkip] = useState(setSkipValue(searchParams));

    useEffect(() => {
        const getFunction = (response) => {
            let data = response.data;
            if(data.code === "01"){
                Swal.fire({
                    title:"Error",
                    confirmButtonColor:"#76b4ff",
                    text: data.message
                });
                return ;
            }

            data.data.map( user => {
                user.userText = `${user.firstName} ${user.lastName}`;
                user.documentTex = `${user.documentType} No ${user.document}`; 
                user.statusText = 'Completo';
                if (user.bankAccount.length === 0) {
                    user.statusText = 'Sin revisar'; 
                    user.alertClass = true;
                    user.alertItem = "statusText";
                }
                return user;
            })
            setUsers(data.data);

            setPaginatorData({
                ...paginatorData,
                ...response.headers,
            })
            
        }
        if (loadData) {
            setLoadData(false);
            setUsers([]);
            if (searchData.length > 2) {
                dispatch( searchListQuery( 'user', searchData, getFunction, skip, user.token ) );
            } else {
                dispatch( getUsers( skip, getFunction, user.token ) );
            }
        }
    }, [loadData, dispatch, user.token, skip, paginatorData, searchData]);

    const searchFunction = (event) => {
        let value = event.target.value;
        setSearchData(value);
        if (value.length > 2) {
            stockPaginator();
            setLoadData(true);
            setSearchParams({ q: value });
            return;
        }
        if (value.length === 0) {
            stockPaginator();
            setLoadData(true);
            setSearchParams({});
        }
    }

    const stockPaginator = () => {
        setSkip(0);
        setPaginatorData({
            totalitems: 0,
            totalpages: 0,
            currentpage: 0
        });
    }
    
    return(
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <h1 className="my-5">Usuarios</h1>
                <div className="d-flex justify-content-between">
                    <div className="d-flex w-50">
                        <input className="input-field w-75" type="text" onChange={searchFunction} placeholder="Buscar un usuario por nombre, apellido o documento" value={searchData}/>
                    </div>
                    <Link to="/usuarios/nuevo" className="primary-button">Añadir nuevo usuario +</Link>
                </div>
                <div className='mt-3'>
                    <span className='align-self-center'>Usuarios: <b>{paginatorData.totalitems}</b></span>
                </div>
                <TableComponent
                    id={"users-table"}
                    headers={usersTableHeaders}
                    body={usersTableBody}
                    skip={skip}
                    data={users}
                    link={`${currentLink}/editar/`}
                    needOptions={true}
                />
                {paginatorData.totalpages > 0 && paginatorData.currentpage > 0 &&
                    <Paginator 
                        skip={skip}
                        data={paginatorData}
                        setSkip={(n) => setSkip(n)}
                        setLoadData={(need) => setLoadData(need)}
                        queryParams={searchParams}
                        setQueryParams={(params) => setSearchParams(params)} />
                }
            </div>
        </div>
    );
}

export default UsersList;
  