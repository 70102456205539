import {useState} from 'react';
import {useDispatch} from 'react-redux';
import Swal from 'sweetalert2';
import logo from '../assets/logo-inmobiliaria.jpeg';
import '../styles/login.css';
import {loginUser} from '../actions/user-actions';
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

function Login(){
    const dispatch = useDispatch();

    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");

    const[ showInfo, setShowInfo ] = useState(false);
    const[ fieldType, setFieldType ] = useState("password");

    const needShowInfo = () =>{
        if( fieldType === "password" ){
            setFieldType("text");
            setShowInfo(true);
        }
        else{
            setFieldType("password");
            setShowInfo(false);
        }
    }

    const login = () => {
        if(user.trim() !== "" && pass.trim() !== ""){
            dispatch(loginUser(user, pass));
        }else{
            Swal.fire({
                title:"Atención!",
                confirmButtonColor:"#76b4ff",
                text:"Debe escribir el correo y la contraseña para continuar."
            });
        }
    }

    return(
        <div className="row m-0 justify-content-center fixed-background">
            <div className="card-container d-flex justify-content-center flex-wrap">
                <div className="mb-4">
                    <img alt={""} src={logo} className="logo-img"/>
                </div>
                <div className="row m-0 mb-2 w-100">
                    <label htmlFor="username">Usuario</label>
                    <input id="username" className="input-field" onChange={(e) => setUser(e.currentTarget.value)} value={user}></input>
                </div>
                <div className="row m-0 mb-2 w-100 position-relative">
                    <label htmlFor="password">Contraseña</label>
                    <input id="password" className="input-field" type={fieldType} onChange={(e) => setPass(e.currentTarget.value)} value={pass}></input>
                    { !showInfo ? 
                        <BsFillEyeFill onClick={needShowInfo} className='eye-icon' style={{ bottom: '16%', right: '0' }} />
                    :
                        <BsFillEyeSlashFill onClick={needShowInfo} className='eye-icon' style={{ bottom: '16%', right: '0' }} />
                    }
                </div>
                <div className="row m-0 justify-content-center mt-4 mb-2">
                    <div className="primary-button p-2" onClick={login} style={{textAlign: 'center'}}>INGRESAR</div>
                </div>
            </div>
        </div>
    );
}

export default Login;
  