import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams  } from 'react-router-dom';
import { getProperty } from '../../actions/property-actions';
import { createPayment } from '../../actions/request-actions';
import alert from '../../components/alert';
import DatePicker from "react-datepicker";
import { formatDate, toCurrency } from '../../actions/global-actions';
import PropertyContractDetail from '../../components/propertyContractDetail/propertyContractDetail';
import useQuery from '../../components/useQuery';

function CreatePayment() {
    const currentLink = "/inmuebles";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.user);
    const [loadData, setLoadData] = useState(true);
    const { id } = useParams();
    const [currentData, setCurrentData] = useState({});
    const [currentRequest, setCurrentRequest] = useState({});
    const [currentContract, setCurrentContract] = useState({});
    const [canShow, setCanShow] = useState(false);
    let query = useQuery();

    const setMonthValue = () => {
        // Se obtiene la fecha como parámetro
        const date = query.get('date');
        // Si no viene ninguna fecha como parámetro entonces se devuelve la fecha actual a las 00:00AM
        if (!date) return new Date(new Date().setHours(0,0,0));
        return new Date(new Date(new Date().setTime(date)).setHours(0,0,0));
    }

    const [form, setForm] = useState({
        monthPaid: setMonthValue(),
        valuePaid: 0,
        creationUserID: '',
        observation: '',
        createdAt: new Date(),
    });
    
    useEffect(() => {
        if (loadData) {
            const getFunction = (data) => {
                if(data.code === "01"){
                    alert('Error', data.message);
                    return;
                }

                //console.log('info', data.data[0]);
                let info = data.data[0];
                info.ubication = `${info?.address} B/${info?.neighborhood} ${info?.city}, ${info?.province}`;
                //console.log('info', info);
                setCurrentData(info);

                let request = info.requests.find(request=>request.status==='Aprobado' || request.status==='En contrato');
                if (!request) {
                    alert('Error', 'Para registrar un pago a un inquilino primero debe aceptar una solicitud de algún aspirante');
                    return;
                }
                request.person = `${request.firstName} ${request.lastName} ${request.documentType} No ${request.document}`;

                let currentContract = request.contracts.find(contract=>contract.status==='Activo');
                if (!currentContract) {
                    alert('Advertencia', 'Para generar un pago primero debe existir un contrato activo');
                    return;
                }
                currentContract.monthlyCostText = toCurrency(currentContract.monthlyCost);
                currentContract.formattedStartDate = formatDate(currentContract.startDate, 4);
                currentContract.formattedEndDate = formatDate(currentContract.endDate, 4);
                setCurrentContract(currentContract);

                setCurrentRequest(request);
                setCanShow(true);
                setForm({
                    ...form,
                    valuePaid: currentContract.monthlyCost,
                    creationUserID: user._id
                })
            }

            setLoadData(false);

            setForm({
                monthPaid: setMonthValue(),
                valuePaid: 0,
                creationUserID: '',
                observation: '',
                createdAt: new Date(),
            })

            dispatch( getProperty( id, getFunction, user.token ) );
        }
    }, [loadData, dispatch, form, id, user ]);
 
    const handleInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name] :  event.target.value
        })
    }

    const handleDateChange = ( name, date ) => {
        setForm({
            ...form,
            [name] :  name==='monthPaid'?new Date(new Date(date).setHours(0,0,0)):date
        })
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const isEmpty = validateFields();
        if (isEmpty) {
            alert('¡Atención!','Debe llenar todos los datos excepto la observación para continuar')
            return;
        }
        let data = JSON.parse(JSON.stringify(currentData));
        let request = JSON.parse(JSON.stringify(currentRequest));
        let contract = JSON.parse(JSON.stringify(currentContract));
        const date = formatDate(form.monthPaid, 6);

        //Se valida que no exista un pago en el mismo mes
        const existPayment = contract.payments.find(payment => formatDate(new Date(payment.monthPaid), 6) === date);
        if (existPayment) {
            alert('¡Atención!',`Ya existe un pago realizado para la fecha '${date}'`);
            return;
        }

        contract.payments.push(form);
        const contractIndex = request.contracts.findIndex( contractObj => contractObj._id === contract._id );
        const requestIndex = data.requests.findIndex( requestObj => requestObj._id === request._id );

        data.requests[requestIndex].contracts[contractIndex] = contract;
        //console.log('data', data);

        dispatch( createPayment( data, createFunct, user.token ) );
    }

    const validateFields = () => {
        for (const field in form) {
            if (form.hasOwnProperty(field)) {
                //console.log(`${field}: ${form[field]}`);
                if (  form[field] === '' && field !== 'observation' ) {
                    return true
                }
            }
        }
        return false
    }

    const createFunct = (data) => {
        if(data.code === "01"){
            alert('Error', data.message);
            return;
        }
        navigate(-1);
        //console.log(data);
        alert('¡Éxito!', 'Pago realizado con éxito');
    }

    useEffect(() => {
        //console.log(form)
    }, [form]);

    return(
        <div className="w-100 d-flex">
            { (canShow) &&
                <div className="container d-flex flex-column">
                    <div className="w-100 mt-5">
                        <button onClick={()=>navigate(-1)} className="back-button">&#60; Volver</button>
                    </div>
                    <h1 className="my-5">Nuevo pago - Inmueble {currentData.code}</h1>
                    <PropertyContractDetail
                        data={currentData}
                        request={currentRequest}
                        contract={currentContract}
                        />
                    <form onSubmit={handleOnSubmit}>
                        <div className="d-flex w-100 flex-wrap">
                            <h4 className="w-100 my-2">Datos del pago</h4>
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="createdAt">Fecha de pago</label>
                                <DatePicker
                                    selected={form.createdAt}
                                    dayAriaLabel={"Día"}
                                    dayPlaceholder={"dd"}
                                    monthAriaLabel={"Mes"}
                                    monthPlaceholder={"mm"}
                                    yearAriaLabel={"Año"}
                                    yearPlaceholder={"yyyy"}
                                    name={"createdAt"}
                                    onChange={ (date)=>handleDateChange( 'createdAt', date ) }
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="monthPaid">Fecha a pagar</label>
                                <DatePicker
                                    selected={form.monthPaid}
                                    onChange={(date) => handleDateChange('monthPaid', date)}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    locale="es"
                                />
                            </div>
                            <div className="d-flex flex-column w-3 field">
                                <label className="label-field" htmlFor="valuePaid">Valor</label>
                                <input id="valuePaid" name="valuePaid" autoComplete="off" maxLength={180} className="input-field" type="number" min={1} onChange={handleInputChange} value={form.valuePaid}></input>
                            </div>
                            <div className="d-flex flex-column w-100 field">
                                <label className="label-field" htmlFor="observation">Observación (Opcional)</label>
                                <textarea id='observation' name="observation" rows={3} cols={5} className="input-field" maxLength={256} placeholder='Ejem: No pago el canon completo por x o y motivo..' onChange={handleInputChange} value={form.observation}></textarea>
                            </div>
                            <div className="w-100 d-flex d-flex justify-content-center my-4">
                                <button className="primary-button save-button" type="submit">Generar</button>
                            </div>
                        </div>
                    </form>
                </div>
            }
        </div>
    );
}

export default CreatePayment;