import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProperty, updateProperty } from '../../actions/property-actions';
import Paginator from '../../components/paginator/paginator';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../../actions/global-actions';
import { contractsTableBody, contractsTableHeaders } from './contract-list-data';
import TableComponent from './../../components/table/table';
import alert from '../../components/alert';

function ContractsList(){
    const dispatch = useDispatch();
    const params = useParams();
    const [contracts, setContracts] = useState([]);
    const [loadData, setLoadData] = useState(true);
    const user = useSelector(state => state.user.user);
    const currentLink = "/inmuebles";
    const [currentData, setCurrentData] = useState({});

    const [skip, setSkip] = useState(0);
    const [paginatorData, setPaginatorData] = useState({
        totalitems: 0,
        totalpages: 0,
        currentpage: 0
    });
    const navigate = useNavigate();

    useEffect(() => {
        const getFunction = (response) => {
            let data = response.data;
            if(data.code === "01"){
                alert('Error', data.message);
                return;
            }
            
            //console.log(data[0]);
            let requests = data[0].requests || [];
            //console.log('requests', requests);
            let contracts = [];
            requests.forEach(request => {
                request.contracts.forEach(contract => {
                    contract.formattedStartDate = formatDate(contract.startDate, 4);
                    contract.startDate = new Date(contract.startDate);
                    contract.formattedEndDate = formatDate(contract.endDate, 4);
                    contract.tenant = `${request.firstName} ${request.lastName} ${request.documentType} No ${request.document}`;
                    contract.monthlyCostText = `$${contract.monthlyCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
                    contract.formattedFinishDate = contract.finishDate?formatDate(contract.finishDate, 4):null;
                    contract.link = `${currentLink}/${params.id}/contratos/${contract._id}/editar`;
                    contracts.push(contract);
                });
            });

            contracts.sort((a, b) => b.startDate - a.startDate);

            //console.log('contracts', contracts);

            setContracts(contracts);
            setCurrentData(data[0]);
            /*
            setPaginatorData({
                ...paginatorData,
                ...response.headers,
            })*/
        }

        if (loadData) {
            setLoadData(false);
            setContracts([]);
            setCurrentData({});
            dispatch( getProperty( params.id, getFunction, user.token ) );
        }
    }, [loadData, dispatch, user.token, params.id, skip, paginatorData]);
    
    const handleOnClick = (contract) => {
        if (contract.status === 'Activo') {
            alert('¡Advertencia!', 'No se puede eliminar un contrato activo');
            return;
        }
        if (window.confirm(`¿Realmente desea eliminar el contrato con datos: Inquilino: ${contract.tenant}, Fechas ${contract.formattedStartDate} - ${contract.formattedEndDate}, ¡ESTA OPCIÓN NO SE PUEDE DESHACER Y ELIMINARÁ TODOS LOS PAGOS REALIZADOS A DICHO CONTRATO!`)) {
            loopX:
            for (let x = 0; x < currentData.requests.length; x++) {
                let request = currentData.requests[x];
                for (let y = 0; y < request.contracts.length; y++) {
                    let contractObj = request.contracts[y];
                    if (contractObj._id === contract._id) {
                        currentData.requests[x].contracts.splice(y,1);
                        break loopX;
                    }
                }
            }
            //console.log('currentData', currentData);

            dispatch( updateProperty( currentData, updateFunction, user.token ) );
        }
    }

    const updateFunction = (data) => {
        if(data.data.code === "01"){
            alert('Error', data.data.message);
            return;
        }
        //console.log('data',data);
        alert('¡Éxito!', 'Contrato eliminado con éxito');
        setLoadData(true);
    }

    useEffect(() => {
        //console.log(currentData)
    }, [currentData]);

    return(
        <div className="w-100 d-flex">
            <div className="container d-flex flex-column">
                <div className="w-100 mt-5">
                    <button onClick={()=>navigate(-1)} className="back-button">&#60; Volver</button>
                </div>
                <h1 className="my-5">Contratos - Inmueble {currentData.code}</h1>
                <div className="d-flex justify-content-between">
                    <Link to={`${currentLink}/${params.id}/solicitudes`} className="primary-button">Ir a solicitudes</Link>
                    <Link to={`${currentLink}/${params.id}/nuevo-contrato`} className="primary-button">Nuevo contrato +</Link>
                </div>
                <TableComponent
                    id={"contracts-table"}
                    headers={contractsTableHeaders}
                    body={contractsTableBody}
                    skip={skip}
                    data={contracts}
                    link={``}
                    hasOwnLink={true}
                    needOptions={true}
                    deleteAction={handleOnClick}
                />
                {paginatorData.totalpages > 0 && paginatorData.currentpage > 0 && 
                    <Paginator skip={skip} data={paginatorData} setSkip={(n) => setSkip(n)} setLoadData={(need) => setLoadData(need)} />
                }
            </div>
        </div>
    );
}

export default ContractsList;