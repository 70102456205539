export const propertyPaymentsTableHeaders = [
    "Fecha",
    "Mes pago",
    "Inquilino",
    "Valor"
];

export const propertyPaymentsTableBody = [
    {
        data: "date",
        classname: ""
    },
    {
        data: "monthPaidText",
        classname: ""
    },
    {
        data: "tenant",
        classname: ""
    },
    {
        data: "valuePaidText",
        classname: ""
    },
];