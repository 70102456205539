export const propertyTypes = [
    "Casa", "Apartamento", "Local", "Bodega", "Oficina"
];

export const agreementTimes = [
    "Mensual", "Trimestral", "Cada 6 meses", "Anual"
];

export const notificationTimes = [
    "1 mes", "2 meses", "3 meses", "4 meses", "5 meses", "6 meses", "7 meses", "8 meses", "9 meses", "10 meses", "11 meses", "1 año"
];

export const notaries = [
    { name: 'PRIMERA', description: 'Calle 5 Norte #2-41  B/ Centenario  Tels (57+2) 6676563 - (57+2) 6679907 ' },
    { name: 'SEGUNDA', description: 'AV.1N #7N-35 B/Centenario DR. PEDRO JOSE BARRETO VACCA Tels (57+2) 6614121 - (57+2) 6614124 - (57+2) 6614128 ' },
    { name: 'TERCERA', description: 'Cra 6 #8-30 Centro DR. JORGE E. CAICEDO ZAMORANO Tels (57+2) 8843367 - (57+2) 8810416 Fax (57+2) 8843564 ' },
    { name: 'CUARTA', description: 'Cll 7 #25-60 B/Alameda DR. HECTOR MARIO GARCES P. Tels (57+2) 5541215 - (57+2) 5541012 ' },
    { name: 'QUINTA', description: 'Calle 29 Norte #6AN-35 B/Santa Mónica Residencial DRA .GLORIA MARINA RESTREPO Tel (57+2) 6410608 ' },
    { name: 'SEXTA', description: 'Calle 7 #8-37 B/ San Pedro DR. ADOLFO LEON OLIVEROS T. Tels (57+2) 8881916 - (57+2) 8881935 - (57+2) 8881940 ' },
    { name: 'SEPTIMA', description: 'Calle 18 Norte #5AN-20 B/Granada DR. JAIME HERNAN CORREA O. Tels (57+2 ) 6604465 - (57+2) 6604466 Fax (57+2) 6612412' },
    { name: 'OCTAVA', description: 'Cra 4 #9-63 Interior Oficina 106 Centro DR. LUIS ORISON ARIAS BONILLA Tels (57+2) 8891158 - (57+2) 8891159 ' },
    { name: 'NOVENA', description: 'Cra 5 #12-80 Centro DRA. MIRYAM PATRICIA BARONA Tels (57+2) 8844273 - (57+2) 8839318 - (57+2) 8839319 Fax (57+2)  },8896522 Ext.116'  },
    { name: 'DECIMA', description: 'Cra 4 #8-63 Piso 1 Centro DRA. VIVIAN ARISTIZABAL CALERO Tels (57+2) 881 3950 - (57+2 )881 62 02 - (57+2) 881 6734  },Fax (57+2)880 6388' },
    { name: 'ONCE', description: 'Avenida Rooselvet #39A-36 B/ Tequendama DR. ALFONSO RUÍZ RAMÍREZ PBX (57+2) 3724848 Tels (57+2) 3724646 - (57+2)  },3724442 - (57+2) 3733440'  },
    { name: 'DOCE', description: 'Avenida 8 Norte #18N-22 B/Granada DRA. MARIA MERCEDES LALINDE Tels (57+2) 4139321- (57+2) 4139322 ' },
    { name: 'TRECE', description: 'Calle 10 #3-52 Centro DRA. LUCIA BELLINI AYALA Tels (57+2) 8846673 Fax (57+2) 8846676 ' },
    { name: 'CATORCE', description: 'Cra 9 #8-74 Centro DRA. MARIA SOL SINISTERRA Tels (57+2) 8841476 - (57+2) 8841697 - (57+2) 8841203 Fax (57+2)  },8896418 ' },
    { name: 'QUINCE', description: 'Av. 4 Norte #22N-35 B/Granada DR. JAVIER FRANCO SILVA Tels (57+2) 6618811 - (57+2) 6618812 Fax (57+2) 7280863 ' },
    { name: 'DIECISEIS' , description: 'Calle 52 #1B-160 Locales 119 -120 Centro Comercial Carrera Salomia DRA. SONIA ESCALANTE ARIAS Tels (57+2) 4491547  },- (57+2) 4855936 Fax (57+2) 4491547'  },
    { name: 'DIECISIETE', description: 'Carrera 1 con Calle 70 Centro Comercial 14 de Calima Semisotano  DR. ALBERTO MONTOYA MONTOYA Tels (57+2) 4851456  },- (57+2) 4851998 Fax (57+2) 4851409'  },
    { name: 'DIECIOCHO', description: 'Calle 9 #65-42 B/Limonar DR. BERNARDO VALLEJO RESTREPO Tels (57+2) 33327008 - (57+2) 3334037 Fax (57+2) 3334041 ' },
    { name: 'DIECINUEVE', description: 'Calle 27 #43A-83 B/Villa del Sur DR. RUBEN FELIPE LAGAREJO RIVAS Tels (57+2) 3100047 ' },
    { name: 'VEINTE', description: 'Carrera 28D 72W-14 Segundo piso Poblado II veintecali@supernotariado.gov.co  DR. ALEJANDRO DÍAZ CHACÓN Tel (57+2)  },4372319' },
    { name: 'VEINTIUNA', description: 'Carrera 100 #11-60 Centro Comercial Holguines Trade Center Locales 234 A, 234 B, 235 A, 235 B DR. HOLMES RAFAEL  },CARDONA M. Tels (57+2) 3303686 - (57+2) 3303684 - (57+2) 3303622 Fax (57+2) 3303686 Ext. 104'  },
    { name: 'VEINTIDOS', description: 'Calle 13 #82-60 Local 201 Centro Comercial La 14 de Pasoancho DR. HUMBERTO BUENO CARDONA Tels (57+2) 3150046 Fax  },(57+2) 3153529'  },
    { name: 'VEINTITRES', description: 'Carrera 42A #5B-76 B/Tequendama DR .RAMIRO CALLE CADAVID Tels (57+2) 5242406 ' },
    { name: 'ÚNICA DE JAMUNDI', description: 'Carrera 7 No. 9-34 B/ Angel María DRA.MARTHA FERRER Tels (57+2) 5164995 ' },
    { name: 'ÚNICA DE YUMBO', description: 'Carrera 3 # 4-31 DR. RAUL JIMENEZ FRANCO Tels (57+2) 6695960 - (57+2) 6695001 ' },
    { name: 'OFICINA DE REGISTRO DE INSTRUMENTOS PÚBLICOS', description: 'Cra 56 #11A-20 B/ Santa Anita DR. FABBIO VARGAS VARGAS Tels (57+2) 3154880' },
]

export const administrationValues = [
    { name: "10%", value: 10 },
    { name: "20%", value: 20 },
    { name: "30%", value: 30 },
    { name: "40%", value: 40 },
    { name: "50%", value: 50 },
    { name: "60%", value: 60 },
    { name: "70%", value: 70 },
    { name: "80%", value: 80 },
    { name: "90%", value: 90 },
    { name: "100%", value: 100 },
];

export const inventoryItems = [
    "Puertas", "Cerraduras", "Ventanas", "Vidrios", "Cortinas/Persianas", "Closets", "Duchas", "Lavamanos", "Sanitarios", "Toallero",
    "Jabonera", "Cepillera", "Papelera", "Gabinete", "Contador", "Tomas", "Roseta", "Interruptores", "Teléfono", "Citófono",
    "Estufa/asador", "Horno", "Lavaplatos", "Calentador", "Lavadero"
];

export const itemStates = [
    "Bueno", "Regular", "Malo"
];

export const tools = [
    {
        name: "Copiar link solicitud",
        value: ""
    },
    {
        name: "Contratos",
        value: "contratos"
    },
    {
        name: "Solicitudes",
        value: "solicitudes"
    },
    {
        name: "Pagos",
        value: "pagos"
    },
    {
        name: "Gastos",
        value: "gastos"
    },
    {
        name: "Aumentos del canon",
        value: "aumentos"
    },
    {
        name: "Inventario",
        value: "inventario"
    },
];

export const documents = [
    {
        name: "1. Inventario",
        value: 1
    },
    {
        name: "2. Contrato de administración",
        value: 2
    },
    {
        name: "3. Contrato de arrendamiento",
        value: 3
    },
    {
        name: "4. Carta circular",
        value: 4
    },
    {
        name: "5. Aumento del canon mensual",
        value: 5
    },
    {
        name: "6. Declaración de renta",
        value: 6
    },
    {
        name: "7. Acta de entrega",
        value: 7
    },
    {
        name: "8. Carta bienvenida",
        value: 8
    },
    {
        name: "9. Carta de asamblea general",
        value: 9
    }
];

export const templateData = {
    type: 'PODER ESPECIAL',
    company: {
        address: 'Calle 15N No. 6N-34 Oficina 401 Edificio Alcazar',
        phone: 'Teléfono: (602)4074950',
        city: 'Cali - Colombia',
    }
};

export const finantialInformationType = [
    "Préstamo", "Tarjeta de crédito"
];

export const contractStatus = [
    "Activo", "Finalizado"
];

export const propertiesTableHeaders = [
    "Código",
    "Ubicación",
    "Propietario",
    "Estado",
    "Info"
];

export const propertiesTableBody = [
    {
        data: "code",
        classname: ""
    },
    {
        data: "ubication",
        classname: ""
    },
    {
        data: "ownerText",
        classname: ""
    },
    {
        data: "statusText",
        classname: ""
    },
    {
        data: "infoText",
        classname: ""
    },
];